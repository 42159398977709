import React from 'react';
import { ERROR_MSG } from 'Core-utils/constants/constants';
import { ErrorState } from 'Components/molecules';
import { PageLoader } from 'Components/molecules';
import { EmptyState } from 'Components/molecules/EmptyState';
import { initializeSentry } from '../sentry';
import { generateSentryEvent } from 'Core-utils/helpers/helpers';
import { AmpSentryTags } from 'Core-utils/types';

type PageErrorHandlerAndLoaderWrapperProps = {
    error: any;
    isLoading?: any;
    render: () => React.ReactNode;
    handleRefresh?: React.MouseEventHandler<HTMLButtonElement>;
    isEmpty?: boolean;
    imageSrc?: string;
    component?: string;
};

const PageErrorHandlerAndLoaderWrapper = ({
    error,
    isLoading,
    render,
    handleRefresh,
    isEmpty,
    imageSrc,
    component,
}: PageErrorHandlerAndLoaderWrapperProps) => {
    const sentry = initializeSentry();

    if (isLoading) {
        return <PageLoader />;
    }
    if (error) {
        sentry.captureException(error);
        return (
            <ErrorState primaryMessage={ERROR_MSG[0]} secondaryMessage={ERROR_MSG[1]} onRefreshClick={handleRefresh} />
        );
    }

    if (isEmpty) {
        component && sentry.captureEvent(generateSentryEvent(component, AmpSentryTags.EMPTY_RESPONSE));
        return <EmptyState ImageSrc={imageSrc ?? ''} />;
    }

    return <>{render()}</>;
};

export default PageErrorHandlerAndLoaderWrapper;
