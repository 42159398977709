import { Form } from 'react-bootstrap';
import { useState } from 'react';
import React from 'react';
import { Typography } from 'Components/atoms';
import { AreaGraph } from 'Components/molecules';
import { LineGraph } from 'Components/molecules';
import { BY_SEVERITY, IN_THIS_QUARTER, OUTSTANDING_VULNERABILITIES, TOTAL_TEXT } from 'Core-utils/constants/constants';
import { StatisticsCard } from 'Components/atoms/StatisticsCard';
import './styles.scss';

interface Series {
    data: number[];
    name: string;
}

interface OutStandingVulnerabilitiesProps {
    series: Series[];
    categories: string[];
}

const severityLevels = [
    { color: '#FF2629', label: 'Critical' },
    { color: '#FF8C22', label: 'High' },
    { color: '#FFFF07', label: 'Medium' },
    { color: '#65CC01', label: 'Low' },
];

const OutStandingVulnerabilities = ({ series, categories }: OutStandingVulnerabilitiesProps) => {
    const [value, setValue] = useState<string>('severity');
    const handleChange = () => {
        setValue(value === 'severity' ? 'total' : 'severity');
    };

    const renderHeaderBody = () => {
        return (
            <div className="d-flex flex-column align-items-center outstanding gap-2">
                <div className="d-flex gap-2 align-items-center">
                    <Typography variant="body3" color="text-high-emphasis" style={{ marginBottom: '0%' }}>
                        {BY_SEVERITY}
                    </Typography>
                    <Form>
                        <Form.Check type="switch" id="toggle-switch" value={value} onChange={handleChange} />
                    </Form>
                    <Typography variant="body3" color="text-high-emphasis" style={{ marginBottom: '0%' }}>
                        {TOTAL_TEXT}
                    </Typography>
                </div>
                {value === 'severity' && (
                    <div className="d-flex align-items-center gap-2">
                        {severityLevels.map(({ color, label }, index) => (
                            <React.Fragment key={index}>
                                <div className="outstanding__legend" style={{ backgroundColor: color }}></div>
                                <Typography
                                    variant="overline2"
                                    color="text-medium-emphasis"
                                    style={{ marginBottom: '0%' }}
                                >
                                    {label}
                                </Typography>
                                {index !== severityLevels.length - 1 && <div className="vr" />}
                            </React.Fragment>
                        ))}
                    </div>
                )}
            </div>
        );
    };
    const seriesTotal = [];

    for (let i = 0; i < series[0].data.length; i++) {
        let currentSum = 0;
        for (const obj of series) {
            currentSum += obj.data[i];
        }
        seriesTotal.push(currentSum);
    }

    const renderBody = (
        <div className="d-flex align-items-center justify-content-center h-100 w-100">
            {value === 'severity' ? (
                <AreaGraph series={series} categories={categories} />
            ) : (
                <LineGraph series={seriesTotal} categories={categories} showDataLabel={false} />
            )}
        </div>
    );

    return (
        <div className="h-100 w-100">
            <StatisticsCard
                header={OUTSTANDING_VULNERABILITIES}
                subHeader={IN_THIS_QUARTER}
                headerBody={renderHeaderBody()}
                body={renderBody}
            />
        </div>
    );
};

export default OutStandingVulnerabilities;
