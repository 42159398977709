import { TableColumn } from 'react-data-table-component';
import { Typography } from 'Components/atoms';
import { UserGroupType } from 'Core-utils/types/types';
import { GROUP_NAME, NUMBER_OF_EMPLOYEES, DESCRIPTION } from 'Core-utils/constants/constants';

export const USER_GROUP_GRID_COLUMNS: TableColumn<UserGroupType>[] = [
    {
        id: 'groupName',
        name: <Typography variant="body2">{GROUP_NAME}</Typography>,
        sortable: false,
        wrap: true,
        cell: (row: UserGroupType) => {
            return <Typography variant="subtitle3">{row.groupName}</Typography>;
        },
    },
    {
        id: 'numberOfEmployees',
        name: <Typography variant="body2">{NUMBER_OF_EMPLOYEES}</Typography>,
        sortable: false,
        wrap: true,
        cell: (row: UserGroupType) => {
            return <Typography variant="subtitle3">{row.numberOfEmployees}</Typography>;
        },
    },
    {
        id: 'description',
        name: <Typography variant="body2">{DESCRIPTION}</Typography>,
        sortable: false,
        wrap: true,
        cell: (row: UserGroupType) => {
            return <Typography variant="subtitle3">{row.description}</Typography>;
        },
    },
];
