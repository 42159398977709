import { HealthMetrics } from 'Components/molecules';
import { calculateUserMetricsPosition, roundOffValue, mapThreshold } from 'Core-utils/helpers/helpers';
import { DEPARTMENT_SCORE, ORGANIZATION_SCORE } from 'Core-utils/constants/constants';
import Needle from './Needle';
import { Typography } from 'Components/atoms';
import classNames from 'classnames';
import './styles.scss';

interface SpeedometerProps {
    value: number;
    orgValue?: number;
    deptValue?: number;
    variant: 'organization' | 'user';
    disableDepartment?: boolean;
}

const Speedometer = (props: SpeedometerProps) => {
    const { value = 0, variant, orgValue = 0, deptValue = 0 } = props;
    const displayValue = roundOffValue(value);
    const displayOrgValue = roundOffValue(orgValue);
    const displayDeptValue = roundOffValue(deptValue);
    const { label, color } = mapThreshold(value);
    const orgMetricsPosition = calculateUserMetricsPosition(orgValue!);
    const deptMetricsPosition = calculateUserMetricsPosition(deptValue!);

    const renderOrganizationSpeedometer = () => (
        <div className="speedometer" data-testid="speedometer">
            <div className="d-flex align-items-center justify-content-center flex-column speedometer__speedometer-container">
                <Needle value={value} />
                <div className="speedometer__content">
                    <HealthMetrics
                        metric={{
                            label: displayValue.toString(),
                            labelColor: color,
                            labelVariant: 'h0',
                            value: label,
                            valueVariant: 'body1',
                            valueColor: 'text-high-emphasis',
                        }}
                    />
                </div>
            </div>
        </div>
    );

    const renderUserSpeedometer = () => (
        <div className="speedometer" data-testid="speedometer">
            <div className="d-flex align-items-center justify-content-center flex-column speedometer__speedometer-container">
                <Needle
                    value={value}
                    org={{
                        x: orgMetricsPosition.cx,
                        y: orgMetricsPosition.cy,
                    }}
                    {...(deptValue && {
                        dept: {
                            x: deptMetricsPosition.cx,
                            y: deptMetricsPosition.cy,
                        },
                    })}
                />

                <div className="d-flex align-items-center justify-content-between speedometer__health-metrics-row">
                    {orgValue >= 0 && (
                        <div className="d-flex align-items-center justify-content-center flex-column speedometer__score">
                            <Typography variant="subtitle0" color="primary-500">
                                {displayOrgValue}
                            </Typography>
                            <Typography variant="body6" color="primary-500">
                                <span className="text-uppercase">{ORGANIZATION_SCORE}</span>
                            </Typography>
                        </div>
                    )}
                    <div className="d-flex align-items-center justify-content-center speedometer__health-metrics-cell">
                        <div className="speedometer__content">
                            <HealthMetrics
                                metric={{
                                    label: displayValue.toString(),
                                    labelColor: 'text-high-emphasis',
                                    labelVariant: 'h0',
                                    value: label,
                                    valueVariant: 'body1',
                                    valueColor: 'text-high-emphasis',
                                }}
                            />
                        </div>
                    </div>

                    <div
                        className={classNames(
                            'd-flex align-items-center justify-content-center flex-column speedometer__score',
                            { invisible: props.disableDepartment },
                        )}
                    >
                        {deptValue >= 0 && (
                            <>
                                <Typography variant="subtitle0" color="text-low-emphasis">
                                    {displayDeptValue}
                                </Typography>
                                <Typography variant="body6" color="text-low-emphasis">
                                    <span className="text-uppercase">{DEPARTMENT_SCORE}</span>
                                </Typography>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );

    const renderSpeedometer = () => {
        if (variant === 'organization') {
            return renderOrganizationSpeedometer();
        } else if (variant === 'user') {
            return renderUserSpeedometer();
        }
        return null;
    };

    return <>{renderSpeedometer()}</>;
};

export default Speedometer;
