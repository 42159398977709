import { Image } from 'react-bootstrap';
import SpeedomterSVG from './SpeedometerSVG';
import pointer from 'Assets/icons/Needle.svg';
import { calculateNeedleRotation } from 'Core-utils/helpers/helpers';
import { Coordinates } from 'Core-utils/types/types';
import './styles.scss';

interface NeedleProps {
    value: number;
    org?: Coordinates;
    dept?: Coordinates;
}

const Needle = ({ value, org, dept }: NeedleProps) => {
    const rotation = calculateNeedleRotation(value);
    return (
        <div>
            <div className="speedometer__speedometer-image">
                <SpeedomterSVG org={org} dept={dept} />
            </div>

            <div className="speedometer__speedometer-needle">
                <Image
                    src={pointer}
                    style={{
                        transform: `translateX(30px) rotate(${rotation}deg)`,
                    }}
                />
            </div>
        </div>
    );
};

export default Needle;
