import ReactApexChart from 'react-apexcharts';
import { UserLineGraphData } from 'Core-utils/constants/charts';
import './styles.scss';

interface Series {
    name?: string;
    data: Array<number | never>;
}

export interface UserLineGraphProps {
    series: Series[];
    categories?: string[];
    lineColors?: string[];
    showDataLabels?: boolean;
    width?: number[];
}

const UserLineGraph: React.FC<UserLineGraphProps> = (props: UserLineGraphProps) => {
    const chartData = UserLineGraphData(props);

    return (
        <div id="user-line-chart" className="user-line-graph">
            <ReactApexChart options={chartData.options} series={chartData.series} type="line" width="100%" />
        </div>
    );
};

export default UserLineGraph;
