import { Col, Row } from 'react-bootstrap';
import { Typography } from 'Components/atoms';
import { NO_RESULTS_FOUND } from 'Core-utils/constants/constants';
import { ReactSVG } from 'react-svg';
import search from 'Assets/icons/search.svg';
import './styles.scss';

const EmptySearchResults = () => {
    return (
        <div className="d-flex search">
            <Col className="d-grid search__content gap-4">
                <Row>
                    <ReactSVG src={search} />
                </Row>
                <Typography variant="body1" color="text-high-emphasis">
                    {NO_RESULTS_FOUND}
                </Typography>
            </Col>
        </div>
    );
};

export default EmptySearchResults;
