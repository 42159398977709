import { HEALTH_TRENDS_TEXT } from 'Core-utils/constants/constants';
import { CustomCard } from 'Components/molecules';
import { UserLineGraph } from 'Components/molecules';
import { Typography } from 'Components/atoms';
import ErrorHandlerAndLoaderWrapper from 'Wrappers/ErrorHandlerandLoaderWrapper';
import './styles.scss';

const COMPONENT_IDENTIFIER = 'Department Health Trend';

interface Series {
    data: number[];
    name: string;
}

interface UserLineGraphCardProps {
    series: Series[];
    error: any;
    isLoading: boolean;
    onRefresh: React.MouseEventHandler<HTMLButtonElement>;
    isEmpty: boolean;
    imageSrc: string;
}

const UserLineGraphCard = ({ series, error, isLoading, onRefresh, isEmpty, imageSrc }: UserLineGraphCardProps) => {
    return (
        <div className="user-line-graph-card h-100 w-100">
            <CustomCard
                cardType="oneThird"
                title={<Typography variant="subtitle4">{HEALTH_TRENDS_TEXT}</Typography>}
                content={
                    <div className="d-flex align-items-center justify-content-center user-line-graph-card__error-container">
                        <ErrorHandlerAndLoaderWrapper
                            error={error}
                            isLoading={isLoading}
                            handleRefresh={onRefresh}
                            render={() => <UserLineGraph series={series} />}
                            isEmpty={isEmpty}
                            ImageSrc={imageSrc}
                            component={COMPONENT_IDENTIFIER}
                        />
                    </div>
                }
            />
        </div>
    );
};

export default UserLineGraphCard;
