import React from 'react';
import { Accordion as BootstrapAccordion, Badge } from 'react-bootstrap';
import { CONFIGURED, NOT_CONFIGURED } from 'Core-utils/constants/constants';
import { BsChevronRight } from '@react-icons/all-files/bs/BsChevronRight';
import classNames from 'classnames';
import { Typography } from 'Components/atoms';
import './styles.scss';

export type AccordionItemProps = {
    key: number | string;
    children: React.ReactNode;
    title: React.ReactNode;
    eventKey: string;
    disabled?: boolean;
    isHidden?: boolean;
} & (
    | {
          isConfigurationRequired: true;
          isConfigured: boolean;
      }
    | {
          isConfigurationRequired: false;
      }
);

export type AccordionProps = {
    accordionItems: AccordionItemProps[];
    defaultActiveKey?: string[];
    alwaysOpen?: boolean;
};

const Accordion = ({ accordionItems, defaultActiveKey = [], alwaysOpen = false }: AccordionProps) => {
    return (
        <BootstrapAccordion defaultActiveKey={defaultActiveKey} alwaysOpen={alwaysOpen}>
            {accordionItems?.map((item: AccordionItemProps) => {
                const { children, isConfigurationRequired, title, eventKey, disabled = false, isHidden = false } = item;

                const accordionItemClassName = classNames({ 'pe-none opacity-50': disabled });

                if (isHidden) {
                    return null;
                }

                return (
                    <BootstrapAccordion.Item key={item.key} eventKey={eventKey} className={accordionItemClassName}>
                        <BootstrapAccordion.Header className="bg-primary-hover">
                            <BsChevronRight />
                            <Typography variant="subtitle7">{title}</Typography>
                            {isConfigurationRequired && (
                                <Badge
                                    className="bg-structural-300 body3 text-text-medium-emphasis accordion__badge d-flex align-items-center justify-content-center"
                                    pill
                                >
                                    {item.isConfigured ? CONFIGURED : NOT_CONFIGURED}
                                </Badge>
                            )}
                        </BootstrapAccordion.Header>
                        <BootstrapAccordion.Body className="bg-structural-400 px-5">{children}</BootstrapAccordion.Body>
                    </BootstrapAccordion.Item>
                );
            })}
        </BootstrapAccordion>
    );
};

export default Accordion;
