import { Typography } from 'Components/atoms';
import { FindingStatusLabelMap } from 'Core-utils/constants/constants';
import './styles.scss';

type CheckboxProps = {
    labelProp: string;
    handleCheckbox: (event: React.ChangeEvent<HTMLInputElement>) => void;
    checked?: boolean;
    customDisplayLabel?: string;
};

const Checkbox = ({ labelProp, handleCheckbox, checked, customDisplayLabel }: CheckboxProps) => {
    const displayLabel = FindingStatusLabelMap?.[labelProp as string] ?? (labelProp as string);
    return (
        <div className="form-check data-grid-dropdown__dropdown-item dropdown-item">
            <input
                type="checkbox"
                className="checkbox__input form-check-input me-2 ms-1 bg-transparent border shadow-none"
                checked={checked}
                id={labelProp}
                onChange={handleCheckbox}
            />
            <label className="form-label m-0 w-100 mt-1" htmlFor={labelProp}>
                <Typography variant="body2">{customDisplayLabel ?? displayLabel}</Typography>
            </label>
        </div>
    );
};

export default Checkbox;
