import React, { useEffect, useMemo, useState } from 'react';
import _, { startCase } from 'lodash';
import { DropdownComponent } from 'Components/molecules';
import { AutoCompleteDropdown } from 'Components/molecules/AutoCompleteDropdown';
import DeleteIcon from 'Assets/icons/DeleteIcon.svg';
import { Button, Image } from 'react-bootstrap';
import {
    ADD_COHORT,
    USER_COHORT_ERROR_MESSAGE,
    USER_COHORT_SUBHEADER,
    USER_COHORT,
    USER_COHORT_CAPTION,
} from 'Core-utils/constants/constants';
import { Typography } from 'Components/atoms';
import { ExpandableMenuStack } from 'Components/molecules';
import { DropdownData } from './Data';
import { AutoCompleteItem, MenuItem, Row } from 'Core-utils/types';
import { useApiObjectData } from 'Hooks/useApiObjectData';
import { getCohorts } from 'Apis/library';
import { CohortDto } from '@ampsec/platform-client/src/dto';
import { useConnectorData } from 'Hooks/useConnectorsData';
import './styles.scss';

interface UserCohortProps {
    rows: Row[];
    setRows: React.Dispatch<React.SetStateAction<Row[]>>;
}

const UserCohort = ({ rows, setRows }: UserCohortProps) => {
    const [isAddButtonClicked, setIsAddButtonClicked] = useState(false);
    const [searchText, setSearchText] = useState<{ id: string; value: string }>({ id: '1', value: '' });
    const { data: fetchCohorts } = useApiObjectData<CohortDto[], MenuItem[]>(
        searchText?.value ?? '',
        getCohorts,
        (dataItem) => {
            return (
                dataItem &&
                dataItem.map((item) => ({
                    id: item.value,
                    displayValue: item.displayValue,
                    value: item.displayValue,
                    kind: item.kind,
                }))
            );
        },
    );
    const [filteredData, setFilteredData] = useState<MenuItem[]>([]);
    const { connectorsByCategoryGroup } = useConnectorData();

    const moreThanOrEqualToFiveRows = rows.length >= 5;
    const addRow = () => {
        const lastRow = rows[rows.length - 1];

        if (rows.length < 5 && lastRow?.selectedValue !== '') {
            setRows([
                ...rows,
                {
                    id: lastRow.id + 1,
                    selectedValue: '',
                    included: false,
                    autoCompleteData: [],
                },
            ]);
            setIsAddButtonClicked(false);
        } else {
            setIsAddButtonClicked(true);
        }
    };

    const hasEmptySelectedValue = rows.some((row) => row.selectedValue === '');

    const deleteRow = (rowId: number) => {
        const updatedRows = rows.filter((row) => row.id !== rowId);
        setRows(updatedRows);
    };

    const isSingleRow = rows.length === 1;

    const handleDropdownChange = (selectedValue: string, rowId: number) => {
        const updatedRows = rows.map((row) => {
            if (row.id === rowId) {
                const included = selectedValue === 'Include';
                const updatedAutoCompleteData = row.autoCompleteData.map((item) => ({
                    ...item,
                    inclusive: included,
                })) as MenuItem[];

                return { ...row, selectedValue, included, autoCompleteData: updatedAutoCompleteData };
            }
            return row;
        });

        setRows(updatedRows);
    };

    const globalDisabledItems = useMemo(() => {
        const disabled: any[] = [];
        for (const row of rows) {
            const tempDisabled = row.autoCompleteData.map((data) => data.id);
            disabled.push(...tempDisabled);
        }
        return disabled;
    }, [rows]);

    const handleAutoCompleteChange = (selectedItems: AutoCompleteItem[], rowIndex: number) => {
        const updatedRows = rows.map((r) => {
            if (r.id === rowIndex) {
                const existingAutoCompleteData = r.autoCompleteData || [];

                const updatedAutoCompleteData = [
                    ...new Set(
                        [...existingAutoCompleteData, ...selectedItems].map((item) => ({
                            ...item,
                            inclusive: r.included,
                        })) as MenuItem[],
                    ),
                ];
                const filteredAutoCompleteData = updatedAutoCompleteData.filter((item) => item.value !== 'ALL USERS');
                return { ...r, autoCompleteData: _.uniqBy(filteredAutoCompleteData, 'id') };
            }
            return r;
        });

        const globalDisabledItems: any[] = [];
        updatedRows.forEach((row) => {
            const tempDisabled = row.autoCompleteData.map((data) => data.id);
            globalDisabledItems.push(...tempDisabled);
        });
        setRows(updatedRows);
        setSearchText({ id: rowIndex.toString(), value: '' });
    };
    const handleChipClose = (index: number) => (closedItem: string) => {
        setRows((prevValues) => {
            const updatedAutoCompleteData = prevValues[index].autoCompleteData.filter(
                (autoCompleteData) => autoCompleteData.id !== closedItem,
            );
            const updatedRows = [...prevValues];
            updatedRows[index] = {
                ...updatedRows[index],
                autoCompleteData: _.uniqBy(updatedAutoCompleteData, 'id'),
            };
            return updatedRows;
        });
    };
    const caption = useMemo(() => {
        const caption: AutoCompleteItem[] = [];
        for (const row of rows) {
            if (row.id) {
                const tempCaption = row.autoCompleteData.map((data) => ({
                    icon: data.icon,
                    value: startCase(data.displayValue ?? ''),
                }));
                caption.push(...tempCaption);
            }
        }
        return caption;
    }, [rows]);

    const handleSearchChange = (id: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
        const newSearchText = e.target.value;
        setSearchText({ id: id, value: newSearchText });
    };

    useEffect(() => {
        setFilteredData(Object.values(fetchCohorts));
    }, [fetchCohorts]);

    const renderRows = () => {
        return rows.map((row, index) => (
            <div key={row.id} className="d-flex flex-row w-100 gap-2 mb-2">
                <div className="user-cohort__dropdown-container">
                    <DropdownComponent
                        value={row.selectedValue ?? ''}
                        dropDownItems={DropdownData}
                        onChange={(selectedValue) => handleDropdownChange(selectedValue || '', row.id)}
                    />
                </div>
                <div className="d-flex flex-row w-100 h-100 gap-3">
                    <AutoCompleteDropdown
                        dropDownItems={
                            filteredData &&
                            filteredData.map((data) => ({
                                ...data,
                                icon: connectorsByCategoryGroup?.['Identity & Access']?.[0]?.imageUrl,
                            }))
                        }
                        onSelectedItemsChange={(selectedItems) => handleAutoCompleteChange(selectedItems, row.id)}
                        disabledItems={globalDisabledItems}
                        rowIndex={index + 1}
                        onCloseChip={handleChipClose(index)}
                        selectedItems={row.autoCompleteData}
                        isSimpleAutoComplete={true}
                        onSearchChange={handleSearchChange(row.id.toString())}
                        searchQuery={searchText?.id === row.id.toString() ? searchText?.value ?? '' : undefined}
                        isExcluded={row.selectedValue === 'Exclude'}
                    />

                    <Image
                        src={DeleteIcon}
                        className={`user-cohort__delete-icon ${isSingleRow ? 'single-row' : ''}`}
                        {...(isSingleRow ? {} : { onClick: () => deleteRow(row.id) })}
                        data-testid="delete-row"
                    />
                </div>
            </div>
        ));
    };

    return (
        <div className="user-cohort">
            <ExpandableMenuStack
                header={USER_COHORT}
                caption={caption}
                defaultCaption={USER_COHORT_CAPTION}
                color="vizualization-blue"
            >
                <>
                    {renderRows()}
                    {isAddButtonClicked && hasEmptySelectedValue && (
                        <Typography variant="caption4" color="vizualization-red-200">
                            {USER_COHORT_ERROR_MESSAGE}
                        </Typography>
                    )}
                    <Button
                        variant="body5 border-0 text-vizualization-blue-02 d-flex justify-content-end align-items-center p-0"
                        onClick={addRow}
                        disabled={moreThanOrEqualToFiveRows}
                    >
                        {ADD_COHORT}
                    </Button>
                    {rows.some((row) => row.selectedValue) && (
                        <div className="mt-2 mb-2">
                            <Typography variant="body5" color="text-high-emphasis">
                                {USER_COHORT_SUBHEADER}
                                {rows[0].selectedValue === 'Exclude' ? 'included' : 'excluded'}
                            </Typography>
                        </div>
                    )}
                </>
            </ExpandableMenuStack>
        </div>
    );
};

export default UserCohort;
