import React from 'react';
import Button from 'react-bootstrap/Button';
import { Row, Col } from 'react-bootstrap';
import { FiAlertCircle } from '@react-icons/all-files/fi/FiAlertCircle';
import { Typography } from 'Components/atoms';
import './styles.scss';

interface ErrorStateProps {
    primaryMessage: string;
    secondaryMessage: string;
    onRefreshClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const ErrorState = ({ primaryMessage, secondaryMessage, onRefreshClick }: ErrorStateProps) => {
    return (
        <div
            className="error-state w-100 h-100 d-flex justify-content-center align-items-center align-content-center bg-structural-CardBG overflow-hidden"
            data-testid="error-state"
        >
            <Col className="error-state__col gap-2">
                <Row className="error-state__icon-row">
                    <FiAlertCircle className="error-state__error-icon" />
                </Row>
                <div className="error-state__row gap-2">
                    <div className="custom-typography text-uppercase">
                        <Typography variant={'body5'} color="text-high-emphasis">
                            {primaryMessage}
                        </Typography>
                    </div>
                    <Button variant={'outline-primary'} onClick={onRefreshClick} className="text-uppercase">
                        <Typography variant={'body5'}>{secondaryMessage}</Typography>
                    </Button>
                </div>
            </Col>
        </div>
    );
};

export default ErrorState;
