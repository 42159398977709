import { IoCheckmarkCircle } from '@react-icons/all-files/io5/IoCheckmarkCircle';
import { IoIosWarning } from '@react-icons/all-files/io/IoIosWarning';
import './styles.scss';

interface IconWithStatusProps {
    status: 'warn' | 'active';
    imageSrc?: string;
    iconClassName?: string;
}
const getStatusIcon = (status: 'warn' | 'active') => {
    switch (status) {
        case 'warn':
            return <IoIosWarning className="icon-status__logo text-secondary-accent-yellow-1" />;
        case 'active':
            return <IoCheckmarkCircle className="icon-status__logo text-vizualization-green-300" />;
    }
};
const IconWithStatus = ({ status, imageSrc, iconClassName }: IconWithStatusProps) => {
    return (
        <div className={'icon-status'} data-testid="icon-status-container">
            <img className={iconClassName} src={imageSrc} width={25} height={25} />
            {getStatusIcon(status)}
        </div>
    );
};

export default IconWithStatus;
