import { COMPLIANCE_LABEL, TOTAL_HEALED_LABEL, ACTIVE_ENGAGEMENTS_LABEL } from 'Core-utils/constants/constants';

export const VULNERABILITIES_HEALED_CARD_DATA = [
    {
        label: COMPLIANCE_LABEL,
        value: '98.8',
    },
    {
        label: TOTAL_HEALED_LABEL,
        value: '400',
    },
    {
        label: ACTIVE_ENGAGEMENTS_LABEL,
        value: '6',
    },
];
export const TRAINING_DEFICIENCIES_HEALED_CARD_DATA = [
    {
        label: COMPLIANCE_LABEL,
        value: '80',
    },
    {
        label: TOTAL_HEALED_LABEL,
        value: '30',
    },
    {
        label: ACTIVE_ENGAGEMENTS_LABEL,
        value: '21',
    },
];
export const TOOLING_GAPS_HEALED_CARD_DATA = [
    {
        label: COMPLIANCE_LABEL,
        value: '100',
    },
    {
        label: TOTAL_HEALED_LABEL,
        value: '42',
    },
    {
        label: ACTIVE_ENGAGEMENTS_LABEL,
        value: '0',
    },
];
