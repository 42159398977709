import {
    ACTION_MODAL_CONTENT,
    ACTION_NAME,
    ACTION_TARGET,
    ACTION_TRIGGERED,
    DELETE_ACTION,
    ERROR,
    FAILED_ACTIONS,
    FAILED_RUN,
    FINDINGS_TABLE_PAGINATION,
    YES_DELETE_IT,
} from 'Core-utils/constants/constants';
import { Table } from 'Components/organisms';
import { useEffect, useState } from 'react';
import { RiDeleteBin6Line } from '@react-icons/all-files/ri/RiDeleteBin6Line';
import { Typography } from 'Components/atoms';
import { TableColumn } from 'react-data-table-component';
import { CustomActionWizard } from 'Components/organisms/CustomActionWizard';
import { ViewQueryModal } from 'Components/organisms/ViewQueryModal';
import {
    CustomerActionSummaryDto,
    CustomActionDto,
    ServiceProfile,
    ActionExecutionDto,
    KIND,
} from '@ampsec/platform-client';
import { deleteActionById, getActionExecutions, updateActionById } from 'Apis/library';
import { ConfirmationModal } from 'Components/molecules';
import { getServiceInfoByKey } from 'Core-utils/helpers/helpers';
import { useApiObjectData } from 'Hooks/useApiObjectData';
import './styles.scss';
import useTimeSpent from 'Hooks/useTimeSpent';

const COMPONENT_IDENTIFIER = 'Actions Grid';

interface ActionsProps {
    refresh: boolean;
    serviceProfiles: ServiceProfile[];
}

const Actions = ({ refresh, serviceProfiles }: ActionsProps) => {
    useTimeSpent(COMPONENT_IDENTIFIER);
    const [show, setShow] = useState<boolean>(false);
    const [showViewLogsModal, setShowViewLogsModal] = useState<boolean>(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
    const [refreshData, setRefreshData] = useState<boolean>(refresh);
    const [id, setId] = useState<string>('');
    const [idToDelete, setIdToDelete] = useState<string>('');
    const { data: failedRuns } = useApiObjectData<ActionExecutionDto[], ActionExecutionDto[]>(
        KIND.ACTION_EXECUTION,
        getActionExecutions,
        (a) => a,
        { status: ERROR, specId: id },
    );

    useEffect(() => {
        setRefreshData((refreshData) => !refreshData);
    }, [refresh]);

    const handleDeleteAction = (id: string) => {
        setShowConfirmationModal(true);
        setIdToDelete(id);
    };

    const handleDeleteClick = () => {
        deleteActionById(idToDelete).then(() => setRefreshData((refreshData) => !refreshData));
        setShowConfirmationModal(false);
    };

    const handleCloseModal = () => {
        setShowConfirmationModal(false);
    };

    const handleOnNameClick = (row: CustomActionDto) => {
        setId(row.id);
        setShow(true);
    };

    const handleCloseViewQuerModal = () => {
        setShowViewLogsModal(false);
    };
    const handleViewQueryClick = (_row: CustomerActionSummaryDto) => () => {
        setShowViewLogsModal(true);
    };

    const ACTIONS_TABLE_COLUMNS: TableColumn<CustomerActionSummaryDto>[] = [
        {
            id: 'name',
            name: (
                <Typography variant="body2" color="text-black">
                    {ACTION_NAME}
                </Typography>
            ),
            cell: (row: CustomerActionSummaryDto) => (
                <div
                    onClick={() => handleOnNameClick((row as unknown) as CustomActionDto)}
                    className="engagement-flow__name"
                >
                    <Typography variant="body5" color="text-high-emphasis">
                        {row.displayValue}
                    </Typography>
                </div>
            ),
            width: '25rem',
        },
        {
            id: 'target',
            name: (
                <Typography variant="body2" color="text-black">
                    {ACTION_TARGET}
                </Typography>
            ),
            cell: (row: CustomerActionSummaryDto) => {
                const info = getServiceInfoByKey(serviceProfiles, row.serviceKey);
                const amplifierLogo = getServiceInfoByKey(serviceProfiles, 'Amplifier')?.logo;

                const logo = info?.logo ? info?.logo : amplifierLogo;
                const displayValue = info?.displayValue ? info?.displayValue : 'Custom';

                return (
                    <div className="d-flex gap-2 justify-content-center align-items-center">
                        <img src={logo} height="20px" width="20px" />
                        <Typography variant="body5" color="text-high-emphasis">
                            {displayValue}
                        </Typography>
                    </div>
                );
            },
            width: '25rem',
        },
        {
            id: 'triggered',
            name: (
                <div className="w-100 text-center">
                    <Typography variant="body2" color="text-black">
                        {ACTION_TRIGGERED}
                    </Typography>
                </div>
            ),
            cell: (row: CustomerActionSummaryDto) => (
                <Typography variant="body5" color="text-high-emphasis">
                    {row.recentActivity.totalCount}
                </Typography>
            ),
            width: '20rem',
            center: true,
        },
        {
            id: 'failedAttempts',
            name: (
                <div className="w-100 text-center">
                    <Typography variant="body2" color="text-black">
                        {FAILED_ACTIONS}
                    </Typography>
                </div>
            ),
            cell: (row: CustomerActionSummaryDto) => (
                <div onClick={handleViewQueryClick(row)} className="engagement-flow__link">
                    <Typography variant="body5" color="text-high-emphasis">
                        {row.recentActivity.failureCount}
                    </Typography>
                </div>
            ),
            width: '25rem',
            center: true,
        },
        {
            id: 'delete',
            cell: (row: CustomerActionSummaryDto) => {
                return (
                    <RiDeleteBin6Line
                        size={20}
                        className="text-text-low-emphasis"
                        cursor="pointer"
                        onClick={() => handleDeleteAction(row.id)}
                    />
                );
            },
        },
    ];

    const handleClose = () => {
        setId('');
        setShow(false);
    };

    const handleActionsSave = async (action: CustomActionDto) => {
        if (action.id && !action.isTemplate) {
            updateActionById(action).then(() => setRefreshData((refreshData) => !refreshData));
        }
        setShow(false);
    };

    return (
        <>
            <Table
                columns={ACTIONS_TABLE_COLUMNS}
                rowsPerPageOptions={FINDINGS_TABLE_PAGINATION}
                showSubHeader={false}
                tableBodyHeight={'calc(100% - 3.625rem)'}
                cacheKey={'customActions'}
                transformer={(a) => a}
                operation="summary"
                refreshTableData={refreshData}
                filter={{ isTemplate: false }}
            />
            <CustomActionWizard show={show} handleSave={handleActionsSave} onClose={handleClose} id={id} />
            <ConfirmationModal
                modalTitle={DELETE_ACTION}
                modalBodyTitle={ACTION_MODAL_CONTENT}
                onPrimaryButtonClick={handleDeleteClick}
                primaryButtonLabel={YES_DELETE_IT}
                show={showConfirmationModal}
                onClose={handleCloseModal}
                buttonVariant="danger"
            />
            <ViewQueryModal
                onClose={handleCloseViewQuerModal}
                show={showViewLogsModal}
                json={(Array.isArray(failedRuns) ? failedRuns : []).map((item) => item.output)}
                isFooter={true}
                title={FAILED_RUN}
            />
        </>
    );
};

export default Actions;
