export const DropdownData = [
    { id: 1, value: 'Exclude' },
    { id: 2, value: 'Include' },
];

export type UserCohortType = {
    id: string;
    kind: 'ORGANIZATION' | 'DEPARTMENT' | 'USER' | 'RISK_CONTRIBUTOR';
    displayValue: string;
    value: string;
    icon?: string;
    inclusive: boolean;
};

export const AutoCompleteData: UserCohortType[] = [
    {
        id: '0',
        kind: 'ORGANIZATION',
        displayValue: 'ALL USERS',
        value: 'ALL USERS',
        inclusive: false,
    },
    {
        id: '1',
        kind: 'RISK_CONTRIBUTOR',
        displayValue: 'SALES',
        value: 'SALES',
        inclusive: false,
    },
    {
        id: '2',
        kind: 'DEPARTMENT',
        displayValue: 'SALES',
        value: 'SALES',
        inclusive: false,
    },
    {
        id: '3',
        kind: 'USER',
        displayValue: 'SALES',
        value: 'SALES',
        inclusive: false,
    },
    {
        id: '4',
        kind: 'ORGANIZATION',
        displayValue: 'SAM LAWSON',
        value: 'SAM LAWSON',
        inclusive: false,
    },
    {
        id: '5',
        kind: 'ORGANIZATION',
        displayValue: 'SARA WALKER',
        value: 'SARA WALKER',
        inclusive: false,
    },
    {
        id: '6',
        kind: 'ORGANIZATION',
        displayValue: 'SANA BBARNES',
        value: 'SANA BBARNES',
        inclusive: false,
    },
];

export const ALL_USERS_COHORT = (imageUrl: string) => [
    {
        id: 1,
        selectedValue: 'Include',
        included: true,
        autoCompleteData: [
            {
                id: '0',
                kind: 'ORGANIZATION',
                displayValue: 'ALL USERS',
                icon: imageUrl,
                value: 'ALL USERS',
                inclusive: false,
            },
        ],
    },
];
