import React from 'react';
import { ReportResultUpsertDto, BaseMetric } from '@ampsec/platform-client';
import { Typography } from 'Components/atoms';
import { SearchBar, VerticalSecScoreBar } from 'Components/molecules';
import { ALL, DEPARTMENTS } from 'Core-utils/constants/constants';
import { DepartmentContentProps } from './DepartmentContent';
import ErrorHandlerAndLoaderWrapper from 'Wrappers/ErrorHandlerandLoaderWrapper';
import EngageIcon from 'Assets/icons/engagement.svg';
import { Color, MenuItem } from 'Core-utils/types';
import { EmptyState } from 'Components/molecules/EmptyState';
import _ from 'lodash';
import './styles.scss';
import { getEnum } from 'Apis/library';
import { useApiObjectData } from 'Hooks/useApiObjectData';

const COMPONENT_IDENTIFIER = 'Department Score Bars';

interface renderDropdownProps {
    onDropdownChange?: (eventKey: string | null, e: any) => void;
    onSearchChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    selectedEventKey?: string | null;
}

const SortButton = ({
    dropDownItems,
    selectedEventKey,
    onDropdownChange,
}: {
    dropDownItems: MenuItem[];
    selectedEventKey: string | null;
    onDropdownChange?: (eventKey: string | null, e: any) => void;
}) => {
    const backgroundColor: Color = selectedEventKey ? 'structural-stroke-100' : 'text-black';
    return (
        <div className="btn-group">
            <button
                type="button"
                className={`btn btn-dark btn-outline-primary-500 bg-${backgroundColor} text-primary-500`}
            >
                Sort
            </button>
            <button
                type="button"
                className="btn btn-dark dropdown-toggle dropdown-toggle-split btn-outline-primary-500"
                data-bs-toggle="dropdown"
            >
                <span className="visually-hidden">Toggle Dropdown</span>
            </button>
            <ul className="dropdown-menu p-0 w-100 gap-2 bg-body-color border-text-medium-emphasis ">
                {dropDownItems.map((item) => (
                    <li key={item.id}>
                        <a
                            className="dropdown-item department__menu-item"
                            onClick={(e) => onDropdownChange && onDropdownChange(item.value, e)}
                        >
                            {item.displayValue}
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    );
};

const DepartmentScoreBars = ({
    uniqueDepartments,
    selectedDept,
    onDepartmentClick,
    isSelected,
    error,
    isLoading,
    isEmpty,
    onRefresh,
    onDropdownChange,
    onSearchChange,
    selectedEventKey,
}: DepartmentContentProps) => {
    const allDepartment = uniqueDepartments.find((department) => department.department === ALL);
    const handleDepartmentClick = (department: ReportResultUpsertDto) => () =>
        onDepartmentClick(department.department ?? '');
    const DepartmentContent = () => {
        const { data: fetchedDepts } = useApiObjectData<BaseMetric, MenuItem[]>(
            'departments',
            getEnum,
            (dataItem: any) => {
                return (
                    dataItem.data &&
                    Object.keys(dataItem.data[0])?.map((key: string) => ({
                        id: key,
                        value: dataItem.data[0][key]?.total,
                    }))
                );
            },
        );

        return (
            <div className="d-flex gap-5 ps-5 department__progress-bar">
                <div className="d-flex flex-column justify-content-center gap-2 align-items-center">
                    <Typography variant="h6" color="text-high-emphasis">
                        {ALL}
                    </Typography>
                    {allDepartment && (
                        <VerticalSecScoreBar
                            percentage={allDepartment.results.health_score_label.value as number}
                            department={allDepartment.department ?? ''}
                            isSelected={allDepartment.department === selectedDept && isSelected}
                            onClick={handleDepartmentClick(allDepartment)}
                        />
                    )}
                </div>
                <div className="vr border border-structural-stroke-100" />
                <div className="d-flex flex-column gap-2 justify-content-center align-items-center w-100">
                    <Typography variant="h6" color="text-high-emphasis">
                        {DEPARTMENTS}
                    </Typography>
                    <div className="d-flex justify-content-center department__department-container custom-scrollbar">
                        {_.isEmpty(uniqueDepartments.filter((item) => item.department !== 'ALL')) ? (
                            <div>
                                <EmptyState ImageSrc={EngageIcon} description="0 Results Found" />
                            </div>
                        ) : (
                            <div className="d-flex justify-content-start custom-scrollbar">
                                {uniqueDepartments
                                    .filter((department) => department.department !== ALL)
                                    .map((department: ReportResultUpsertDto) => (
                                        <React.Fragment key={department.id}>
                                            <VerticalSecScoreBar
                                                percentage={department.results.health_score_label.value as number}
                                                department={department.department ?? ''}
                                                noOfUsers={
                                                    (_.isEmpty(fetchedDepts) ? [] : fetchedDepts)?.find(
                                                        (dept) => dept.id === department.department,
                                                    )?.value ?? null
                                                }
                                                isSelected={department.department === selectedDept && isSelected}
                                                onClick={handleDepartmentClick(department)}
                                            />
                                        </React.Fragment>
                                    ))}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    };

    const dropdownItems = [
        {
            id: 1,
            value: 'Name',
            displayValue: 'Name',
        },
        {
            id: 2,
            value: 'Score',
            displayValue: 'Score',
        },
    ];

    const renderDepartmentContent = ({ onDropdownChange, onSearchChange, selectedEventKey }: renderDropdownProps) => {
        return (
            <div className="d-flex flex-column w-100 h-100 gap-3">
                <div className="d-flex flex-column gap-2">
                    <div className="d-flex gap-3 p-3 department__header-cont">
                        <SearchBar onChange={onSearchChange} searchClassName="search-container__input-cont" />
                        <SortButton
                            dropDownItems={dropdownItems}
                            onDropdownChange={onDropdownChange}
                            selectedEventKey={selectedEventKey ?? ''}
                        />
                    </div>
                    <div className="hr border border-structural-stroke-100" />
                </div>
                <DepartmentContent />
            </div>
        );
    };

    return (
        <div className="bg-text-black  rounded d-flex justify-content-evenly">
            <ErrorHandlerAndLoaderWrapper
                error={error}
                isEmpty={isEmpty}
                ImageSrc={EngageIcon}
                render={() => renderDepartmentContent({ onDropdownChange, onSearchChange, selectedEventKey })}
                isLoading={isLoading}
                handleRefresh={onRefresh}
                component={COMPONENT_IDENTIFIER}
            />
        </div>
    );
};

export default DepartmentScoreBars;
