import { AiOutlineClose } from '@react-icons/all-files/ai/AiOutlineClose';
import { Typography } from 'Components/atoms';
import ToastType from 'Core-utils/types/ToastType';
import { ToastsContext } from 'Rdx/contexts/ToastsContext';
import { useContext } from 'react';
import { Toast, ToastContainer } from 'react-bootstrap';

export const Toaster = () => {
    const { toasts, deleteToast } = useContext(ToastsContext);

    return (
        <ToastContainer position="bottom-end" data-testid="toaster-container">
            {toasts?.map((toast: ToastType) => {
                const handleCloseToast = () => deleteToast(toast.id);
                return (
                    <Toast
                        key={toast.id}
                        className={`d-inline-block m-1 border-${toast.borderColor} overflow-hidden`}
                        onClose={handleCloseToast}
                    >
                        <Toast.Body className="d-flex bg-structural-BG p-3 justify-content-between align-items-center">
                            <div className="d-flex bg-structural-BG align-items-center gap-2 justify-content-center">
                                {toast.icon}
                                <Typography variant="caption5" color="text-high-emphasis" style={{ margin: 'auto' }}>
                                    {toast.body}
                                </Typography>
                            </div>
                            <AiOutlineClose
                                className="d-flex text-text-medium-emphasis justify-content-end align-items-end toaster__icon"
                                onClick={handleCloseToast}
                                data-testid="close-toast"
                            />
                        </Toast.Body>
                    </Toast>
                );
            })}
        </ToastContainer>
    );
};

export default Toaster;
