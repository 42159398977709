import { useEffect, useState } from 'react';
import { Button, Collapse } from 'react-bootstrap';
import { VscSend } from '@react-icons/all-files/vsc/VscSend';
import { FINDINGS_PROMPT_INPUT_PLACEHOLDER } from 'Core-utils/constants/constants';
import { PromptType, SavedQueryType } from 'Core-utils/types/types';
import { ViewSavedQueriesModal } from 'Components/molecules';
import PromptSuggestions from './PromptSuggestions';
import PromptHeader from './PromptHeader';
import Prompt from './Prompt';
import { useRef } from 'react';
import './styles.scss';

const PROMPT_SUGGESTIONS = [
    'How many production access employees have open critical vulnerabilities out of SLA?',
    'How many executives are not using Zscaler?',
    'How many 3rd party employees don’t have endpoint detection?',
];

interface PromptList {
    prompts: PromptType[];
    onSaveQuery: (checked: boolean, prompt: SavedQueryType) => void;
    onViewQueryClick: (promptId: string) => void;
}

interface FindingsPromptProps {
    prompt: PromptType;
    input: string;
    setInput: (value: React.SetStateAction<string>) => void;
    onSendButtonClick: () => void;
    onInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onClearPrompt: () => void;
    prompts: PromptType[];
    loading?: boolean;
    disabled?: boolean;
    onViewQueryClick: (promptId: string) => void;
}

const PromptList = ({ prompts, onSaveQuery, onViewQueryClick }: PromptList) => {
    const promptListRef = useRef<HTMLDivElement>(null);

    const scrollToTop = () => {
        if (promptListRef.current) {
            promptListRef.current.scrollTop = promptListRef.current.scrollHeight;
        }
    };

    useEffect(() => {
        scrollToTop();
    }, [prompts]);
    return (
        <div className="findings-prompt__prompt-list d-flex flex-column custom-scrollbar" ref={promptListRef}>
            {prompts.map((prompt, index) => (
                <Prompt
                    {...prompt}
                    key={`${index}-${prompt.value}`}
                    onSaveQuery={onSaveQuery}
                    onViewQueryClick={() => onViewQueryClick(prompt.id)}
                    disabled={prompt.disabled}
                    data-testid={`prompt-${index}`}
                />
            ))}
        </div>
    );
};

const FindingsPrompt = ({
    prompts,
    onSendButtonClick,
    onInputChange,
    input,
    setInput,
    onClearPrompt,
    loading = false,
    onViewQueryClick,
}: FindingsPromptProps) => {
    const [open, setOpen] = useState<boolean>(true);
    const [viewSavedQueries, setViewSavedQueries] = useState<SavedQueryType[]>([]);
    const [showSavedQueriesModal, setShowSavedQueriesModal] = useState<boolean>(false);

    const handleOpenSavedPrompt = () => {
        setShowSavedQueriesModal(true);
    };

    const handleAddPromptToSavedQueries = (checked: boolean, prompt: SavedQueryType) => {
        if (checked) {
            setViewSavedQueries((queries) => [...queries, prompt]);
        } else {
            setViewSavedQueries((queries) => queries.filter((query) => query.id !== prompt.id));
        }
    };

    const handleRemoveSavedQuery = (id: string) => {
        setViewSavedQueries((prevQueries) => prevQueries.filter((dataItem) => dataItem.id !== id));
    };

    const handleClose = () => {
        setShowSavedQueriesModal(false);
    };
    const handleToggleOpen = () => setOpen((open) => !open);
    const handlePromptSuggestionClick = (prompt: string) => setInput(prompt);
    const emptyFunction = () => {};
    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            onSendButtonClick();
        }
    };
    return (
        <div className="findings-prompt w-100 p-3 bg-structural-CardBG rounded">
            <PromptHeader
                onOpenSavedPrompt={handleOpenSavedPrompt}
                onClearPrompts={onClearPrompt}
                showResetButton={!!prompts.length}
                onToggleOpen={handleToggleOpen}
                open={open}
                loading={loading}
                promptsListed={!!prompts.length}
            />

            <Collapse in={open}>
                <div>
                    <div className="findings-prompt__body p-3 pt-0 bg-structural-400 border border-structural-stroke-100 rounded-bottom d-flex flex-column">
                        <div className="findings-prompt__body__child">
                            {prompts.length ? (
                                <PromptList
                                    prompts={prompts}
                                    onSaveQuery={handleAddPromptToSavedQueries}
                                    onViewQueryClick={onViewQueryClick}
                                />
                            ) : (
                                <PromptSuggestions
                                    prompts={PROMPT_SUGGESTIONS}
                                    onPromptSuggestionClick={handlePromptSuggestionClick}
                                />
                            )}
                        </div>
                        <div className="input-group">
                            <input
                                type="text"
                                className="findings-prompt__input form-control bg-transparent border border-structural-stroke-100 border-end-0 text-text-high-emphasis"
                                placeholder={FINDINGS_PROMPT_INPUT_PLACEHOLDER}
                                aria-label="Findings Prompt"
                                value={input}
                                onChange={onInputChange}
                                onKeyDown={handleKeyDown}
                                data-testid="input-element"
                            />
                            <Button
                                variant="outline-structural-stroke-100 border-start-0 text-text-high-emphasis d-flex justify-content-center align-items-center"
                                className="findings-prompt__btn"
                                onClick={onSendButtonClick}
                                disabled={!input.length}
                                data-testid="send-button"
                            >
                                <VscSend size={25} />
                            </Button>
                        </div>
                    </div>
                </div>
            </Collapse>
            {showSavedQueriesModal && (
                <ViewSavedQueriesModal
                    onStarClick={handleRemoveSavedQuery}
                    onPromptClick={emptyFunction}
                    onViewQueryClick={emptyFunction}
                    queriesData={viewSavedQueries}
                    show={showSavedQueriesModal}
                    onClose={handleClose}
                />
            )}
        </div>
    );
};

export default FindingsPrompt;
