import { Tab, Tabs } from 'react-bootstrap';
import { Typography } from 'Components/atoms';
import { useState } from 'react';
import './styles.scss';

interface tabType {
    title: string;
    eventKey: string;
    content: React.ReactNode;
}
interface ConfigurationTabsProps {
    configTabs: tabType[];
}

const ConfigurationTabs = ({ configTabs }: ConfigurationTabsProps) => {
    const [activeTab, setActiveTab] = useState<string>(configTabs[0].eventKey);

    const onSelect = (eventKey: string | null) => {
        setActiveTab(eventKey ?? '');
    };

    return (
        <div className="d-flex flex-column p-2 gap-3 config-tabs">
            <div className="w-100">
                <Tabs activeKey={activeTab} onSelect={onSelect} className="config-tabs__custom-tabs">
                    {configTabs.map((tab: tabType) => (
                        <Tab
                            key={tab.eventKey}
                            eventKey={tab.eventKey}
                            title={
                                <Typography
                                    variant={activeTab === tab.eventKey ? 'body2' : 'body5'}
                                    color={activeTab === tab.eventKey ? 'text-high-emphasis' : 'text-medium-emphasis'}
                                    style={{ textTransform: 'uppercase' }}
                                >
                                    {tab.title}
                                </Typography>
                            }
                        >
                            <div className="content-container pt-4">{tab.content}</div>
                        </Tab>
                    ))}
                </Tabs>
            </div>
        </div>
    );
};

export default ConfigurationTabs;
