import { Models } from '@rematch/core';
import auth from './authModel';
import ssoModel from './ssoModel';
import orgModel from './orgModel';
import pidProviderMap from './pidProviderMap';
import providerMap from './providerModel';
import installedConnectorMap from './installedConnectorModel';
import featureModel from './featureModel';
import findingsModel from './findingsModel';
import findingSpecsModel from './findingSpecsModel';

export interface RootModel extends Models<RootModel> {
    auth: typeof auth;
    ssoModel: typeof ssoModel;
    orgModel: typeof orgModel;
    pidProviderMap: typeof pidProviderMap;
    providerMap: typeof providerMap;
    installedConnectorMap: typeof installedConnectorMap;
    featureModel: typeof featureModel;
    findingsModel: typeof findingsModel;
    findingSpecsModel: typeof findingSpecsModel;
}

const models: RootModel = {
    auth,
    ssoModel,
    orgModel,
    pidProviderMap,
    providerMap,
    installedConnectorMap,
    featureModel,
    findingsModel,
    findingSpecsModel,
};

export default models;
