import { Typography } from 'Components/atoms';
import { PROMPT_PREFIX } from 'Core-utils/constants/constants';

interface PromptSuggestion {
    prompts: string[];
    onPromptSuggestionClick: (prompt: string) => void;
}

const PromptSuggestions = ({ prompts, onPromptSuggestionClick }: PromptSuggestion) => {
    return (
        <div className="d-flex justify-content-center align-items-center gap-3 h-100" data-testid="prompt-suggestions">
            {prompts.map((prompt, index) => {
                return (
                    <div
                        className="prompt-suggestion d-flex border border-red bg-structural-200 border border-structural-stroke-100 rounded"
                        onClick={() => onPromptSuggestionClick(prompt)}
                        key={`${index}-${prompt}`}
                    >
                        <Typography variant="body3" color="text-high-emphasis" style={{ textTransform: 'uppercase' }}>
                            {PROMPT_PREFIX}:&nbsp;
                        </Typography>
                        <Typography variant="body3" color="text-high-emphasis">
                            {prompt}
                        </Typography>
                    </div>
                );
            })}
        </div>
    );
};

export default PromptSuggestions;
