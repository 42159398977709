import ReactApexChart from 'react-apexcharts';
import { lineGraphData } from 'Core-utils/constants/charts';
import './styles.scss';

export interface LineGraphProps {
    series: number[];
    categories?: string[];
    showDataLabel?: boolean;
}

const LineGraph = (props: LineGraphProps) => {
    const chartData = lineGraphData(props);

    return (
        <div id="line-chart" className="linegraph" data-testid="line-graph">
            <ReactApexChart
                options={chartData.options}
                series={chartData.series}
                type="line"
                width="100%"
                data-testid="line-graph"
            />
        </div>
    );
};

export default LineGraph;
