import {
    ADD_INTEGRATION,
    CONNECTOR_CREATED,
    CONNECTOR_SETTINGS_SAVED,
    CREDENTIALS_SAVED,
    DEFAULT_TABS,
    ERROR_MESSAGE,
    ERROR_VARIANT,
    OAUTH_SETTINGS_SAVED,
    SIDEBAR,
    SUCCESS_VARIANT,
} from 'Core-utils/constants/constants';
import { Typography } from 'Components/atoms';
import { IoArrowBackOutline } from '@react-icons/all-files/io5/IoArrowBackOutline';
import MockIntegrationTabs from './MockIntegrationTabs';
import { IntegrationSidebar } from 'Components/molecules';
import { Modal } from 'react-bootstrap';
import { AiOutlineClose } from '@react-icons/all-files/ai/AiOutlineClose';
import { useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import convertToDynamicTabsToTabs from './ConvertDynamicTabsToTabs';
import { useSelector } from 'react-redux';
import { RootState } from 'Rdx/store';
import { ToastsContext } from 'Rdx/contexts/ToastsContext';
import { getApi } from 'Src/Api';
import { AMP_SETTINGS, ConnectorStatus, ConnectorUpsertDto, CredentialStatus } from '@ampsec/platform-client';
import { FormSetting, FormSettings } from 'Core-utils/types';
import { ContentfulContext } from 'Rdx/contexts/contentfulContext';
import { DynamicTab, IntegrationDataProps } from 'Components/organisms/Integrations/Integrations';
import './styles.scss';

export interface IntegrationSidebarType {
    title: string;
    link: string;
}

export interface AddIntegrationModalProps {
    imageUrl: string;
    installationTabContent?: { header: string; content: string }[];
    overviewTabContent?: string;
    providerName: string;
    show: boolean;
    sideBar?: IntegrationSidebarType[];
    vendor?: IntegrationDataProps;
    onClose: () => void;
    onIntegration?: () => Promise<void>;
    onBackClick: () => void;
    disableBack?: boolean;
}

const AddIntegrationModal = ({
    imageUrl,
    providerName,
    show,
    vendor,
    onBackClick,
    onClose,
    onIntegration,
    disableBack = false,
}: AddIntegrationModalProps) => {
    const [tabs, setTabs] = useState<any>();
    const providers = useSelector((state: RootState) => state?.providerMap);
    const { addToast } = useContext(ToastsContext);
    const [selectedOptions, setSelectedOptions] = useState<FormSettings>();
    const [cid, setCid] = useState<string>();
    const backButtonClass = classNames('text-text-high-emphasis add-integration__icon', { invisible: disableBack });
    const { contentfulData } = useContext(ContentfulContext);
    const integrations = contentfulData?.integrations;
    const onSelectOption = (formId: string, fieldName: string, value: string | string[] | boolean) => {
        setSelectedOptions((currentSelectedOptions) => ({
            ...currentSelectedOptions,
            [formId]: {
                ...(currentSelectedOptions?.[formId] ? currentSelectedOptions[formId] : {}),
                fieldName: value,
            },
        }));
    };

    const onConnectorCreated = (cid: string) => {
        setCid(cid);
    };

    const installConnector = async () => {
        const api = await getApi();
        const provider = providers[vendor?.providerKey as string];
        let tempCid: string | undefined = cid;
        if (cid === '') {
            tempCid = await installConnector();
            if (!tempCid) {
                return;
            }
            onConnectorCreated && onConnectorCreated(tempCid);
        }
        if (!provider) {
            addToast({
                severity: ERROR_VARIANT,
                body: ERROR_MESSAGE,
            });
        }
        const connectorModel: ConnectorUpsertDto = {
            displayValue: vendor?.providerKey as string,
            status: ConnectorStatus.DISABLED,
            pid: provider?.id,
            credentialStatus: CredentialStatus.NOT_INSTALLED,
        };

        try {
            const newConnector = await api?.connectors.create(provider.id, connectorModel.displayValue);

            const cid = newConnector?.id || '';
            addToast({
                severity: SUCCESS_VARIANT,
                body: `${CONNECTOR_CREATED} ${newConnector?.displayValue}`,
            });

            return cid;
        } catch {
            addToast({
                severity: ERROR_VARIANT,
                body: ERROR_MESSAGE,
            });
        }
    };

    const handleButtonClick = (formId: string, strategy?: string) => async (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    ) => {
        event.preventDefault();
        let tempCid: string | undefined = cid;
        if (cid === '') {
            tempCid = await installConnector();
            if (!tempCid) {
                return;
            }
        }
        const api = await getApi();

        if (strategy === 'connectorSettings' || strategy === 'tenantSettings' || strategy === 'slackBot') {
            const saveSettings = async (settings: FormSetting) => {
                const res = await api?.settings
                    .createMultiple(
                        Object.keys(settings!).map((selectedOption) => ({
                            key: selectedOption,
                            value: settings[selectedOption],
                        })) || [],
                        strategy === 'connectorSettings' ? tempCid : undefined,
                    )
                    .catch((_err) => {
                        addToast({
                            severity: ERROR_VARIANT,
                            body: ERROR_MESSAGE,
                        });
                    });
                if (!res) {
                    addToast({
                        severity: ERROR_VARIANT,
                        body: ERROR_MESSAGE,
                    });
                    return;
                }

                addToast({
                    severity: SUCCESS_VARIANT,
                    body: CONNECTOR_SETTINGS_SAVED,
                });
            };
            saveSettings(
                strategy === 'slackBot'
                    ? _.omit(selectedOptions?.[formId], ['makeDefault', AMP_SETTINGS.tenant.SLACK_QA_CHANNEL_ID.id])
                    : (selectedOptions?.[formId] as FormSetting),
            );
            if (strategy === 'slackBot') {
                const qaChannelFormValue = selectedOptions?.[formId]?.[AMP_SETTINGS.tenant.SLACK_QA_CHANNEL_ID.id];
                const shouldSetQaChannelId = qaChannelFormValue && qaChannelFormValue !== '';
                if (shouldSetQaChannelId) {
                    const setSlackQaChannelIdResponse = await api?.settings.create(
                        AMP_SETTINGS.tenant.SLACK_QA_CHANNEL_ID.id,
                        qaChannelFormValue,
                    );

                    addToast({
                        severity: setSlackQaChannelIdResponse ? SUCCESS_VARIANT : ERROR_VARIANT,
                        body: setSlackQaChannelIdResponse ? `Default Engagement Channel Set` : ERROR_MESSAGE,
                    });
                }
            }
        } else if (strategy === 'credentials') {
            const res = await api?.install.credentials(tempCid!, selectedOptions?.[formId]!);
            if (!res) {
                addToast({
                    severity: ERROR_VARIANT,
                    body: ERROR_MESSAGE,
                });
                return;
            }

            addToast({
                severity: SUCCESS_VARIANT,
                body: CREDENTIALS_SAVED,
            });
        } else if (strategy === 'oauth') {
            const clientRedirectUrl = `${window.location.origin}/policies/${tempCid}`;
            const oauthInstallUrl = await api?.install.getOauthInstallUrl(tempCid!, 'google', clientRedirectUrl);
            if (!oauthInstallUrl) {
                addToast({
                    severity: ERROR_VARIANT,
                    body: ERROR_MESSAGE,
                });
            } else {
                window.location.href = oauthInstallUrl;
            }

            addToast({
                severity: SUCCESS_VARIANT,
                body: OAUTH_SETTINGS_SAVED,
            });
        }
    };

    useEffect(() => {
        if (!vendor?.dynamicTabs?.tabs || !selectedOptions) {
            return;
        }
        const filteredTabs = vendor?.dynamicTabs?.tabs?.filter((tab: DynamicTab) =>
            DEFAULT_TABS.includes(tab?.contentfulKey ?? ''),
        );
        const newTabs = convertToDynamicTabsToTabs(
            filteredTabs,
            vendor,
            selectedOptions,
            onSelectOption,
            handleButtonClick,
        );
        setTabs(newTabs);
    }, [vendor, selectedOptions]);

    useEffect(() => {
        const loadDefaultValues = () => {
            const defaultValues: FormSettings = {};
            vendor?.dynamicTabs?.tabs?.forEach((tab) => {
                if (tab?.fields && tab.fields.length > 0) {
                    // eslint-disable-next-line max-nested-callbacks
                    tab.fields.forEach((field) => {
                        if (field?.defaultValue !== null && field?.defaultValue !== undefined) {
                            defaultValues[tab.formId as string] = {
                                ...(defaultValues[tab.formId as string] || {}),
                                [field.name]: field.defaultValue,
                            };
                        }
                    });
                }
            });
            setSelectedOptions(defaultValues);
        };

        loadDefaultValues();
    }, [integrations]);

    if (!tabs) {
        return null;
    }

    return (
        <Modal
            data-testid="modal"
            className="add-integration"
            show={show}
            keyboard={false}
            tabIndex={-1}
            aria-hidden="true"
            centered
            onHide={onClose}
        >
            <Modal.Header>
                <Modal.Title>
                    <div className="d-flex align-items-center">
                        <IoArrowBackOutline
                            className={backButtonClass}
                            onClick={onBackClick}
                            data-testid="back-button"
                        />
                        <Typography variant="h4" color="text-high-emphasis">
                            <span className="text-uppercase mb-0 ps-3 pt-1" data-testid="modal-title">
                                {ADD_INTEGRATION}
                            </span>
                        </Typography>
                    </div>
                </Modal.Title>
                <AiOutlineClose
                    onClick={onClose}
                    className="text-text-high-emphasis add-integration__icon"
                    data-testid="close-button"
                />
            </Modal.Header>

            <Modal.Body className="p-0">
                <div className="d-flex">
                    <IntegrationSidebar navLinks={SIDEBAR} imageUrl={imageUrl} signInText={providerName} />
                    <MockIntegrationTabs
                        hideButton={Boolean(vendor?.settingsFormSchema)}
                        tabs={tabs}
                        onIntegration={onIntegration}
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default AddIntegrationModal;
