import { createModel } from '@rematch/core';
import type { RootModel } from './models';

interface Finding {
    id: string;
    value: string;
    query?: object;
}

interface FindingsModelState {
    findings: Finding[];
}

const initialState: FindingsModelState = {
    findings: [],
};

const findingsModel = createModel<RootModel>()({
    state: initialState,
    reducers: {
        setFindings(state, payload: Finding[]) {
            return { ...state, findings: payload };
        },
    },
});

export default findingsModel;
