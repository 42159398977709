import { APPLY_TEXT, CLEAR_ALL_FILTERS, CLEAR_TEXT, RESET, SAVE } from 'Core-utils/constants/constants';
import { Typography } from 'Components/atoms';
import { Color } from 'Core-utils/types/color';
import classNames from 'classnames';
import { FilterDropdownProps, FilterItemProps, ActionButtonProps, FilterButtonProps } from 'Core-utils/types/types';
import { getTotalArrayLength } from 'Core-utils/helpers/helpers';
import { CheckboxList, SearchBar } from 'Components/molecules';
import { useMemo, useState } from 'react';
import { Button } from 'react-bootstrap';
import { RiArrowDownSLine } from '@react-icons/all-files/ri/RiArrowDownSLine';
import './styles.scss';

const FilterDropdown = (props: FilterDropdownProps) => {
    return (
        <div className="menu table-filer-dropdown">
            <FilterButton {...props} />
            {props.filterWithActions && <ActionButton {...props} />}
        </div>
    );
};

const FilterButton = ({
    items,
    options,
    selectedOptions,
    handleCheckboxChange,
    handleClearClick,
    handleApplyClick,
    filterWithActions,
    isDisabled,
    hasSearchBar = false,
}: FilterButtonProps) => {
    const backgroundColor: Color =
        selectedOptions && getTotalArrayLength(selectedOptions) > 0 ? 'structural-stroke-100' : 'text-black';

    const className = classNames(
        `btn btn-outline-primary dropdown-toggle no-after-content bg-${backgroundColor} text-uppercase text-primary-500`,
        {
            'menu__filter-button': filterWithActions,
            'menu__filter-button-without-actions': !filterWithActions,
        },
    );
    const dropdownMenuClasses = 'dropdown-menu menu__dropdown-filter-item bg-text-black';
    return (
        <>
            <button
                type="button"
                className={className}
                data-bs-toggle="dropdown"
                data-bs-auto-close="outside"
                id="filter-dropdown"
            >
                <div className="d-flex align-items-center justify-content-center">
                    <Typography variant="body4" color="primary-500">
                        Filter
                    </Typography>
                    {getTotalArrayLength(selectedOptions) > 0 && (
                        <div className="menu__count bg-text-interactive d-flex justify-content-center align-items-center caption2">
                            {getTotalArrayLength(selectedOptions)}
                        </div>
                    )}
                </div>
            </button>
            <ul className={dropdownMenuClasses}>
                {items.map(
                    (item: React.ReactNode, index: number) =>
                        item && (
                            <FilterItem
                                key={index}
                                text={item as string}
                                options={options[item.toString().toLowerCase()]}
                                selectedOptions={selectedOptions}
                                handleCheckboxChange={handleCheckboxChange}
                                handleClearClick={handleClearClick}
                                handleApplyClick={handleApplyClick}
                                isDisabled={isDisabled}
                                hasSearchBar={hasSearchBar}
                            />
                        ),
                )}
            </ul>
        </>
    );
};

const FilterItem = ({
    text,
    options,
    selectedOptions,
    handleCheckboxChange,
    handleClearClick,
    handleApplyClick,
    isDisabled,
    hasSearchBar = false,
}: FilterItemProps) => {
    const [searchInput, setSearchInput] = useState<string>('');
    const handleCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
        const option = event.target.id;
        const isChecked = event.target.checked;

        handleCheckboxChange(option, isChecked, text);
    };
    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchInput(event.target.value);
    };

    const filteredOptions = useMemo(() => {
        const regex = new RegExp(searchInput, 'i');
        return options && options.filter((item) => regex.test(item));
    }, [searchInput, options]);

    if (options) {
        return (
            <li className="dropend body4">
                <div className="menu__dropdown-item w-100 no-after-content text-capitalize dropdown-toggle show">
                    {text}
                </div>
                <div className="dropdown-menu bg-text-black menu__submenu" data-bs-toggle="hover">
                    {hasSearchBar && (
                        <div className="ps-2 pe-2">
                            <SearchBar value={searchInput} onChange={handleSearchChange} />
                        </div>
                    )}
                    <div className="menu__submenu-content custom-scrollbar">
                        <CheckboxList
                            options={filteredOptions ?? []}
                            selectedOptions={selectedOptions[text.toLowerCase()] ?? []}
                            handleCheckbox={handleCheckbox}
                            header={false}
                        />
                    </div>
                    <div className="d-flex justify-content-between align-items-center rounded-bottom border-top border-structural-stroke-100 menu__buttons">
                        <Button
                            className={`btn bg-transparent border-0 d-flex align-items-center justify-content-center text-primary-500 body4 mb-3 menu__clear-button`}
                            onClick={handleClearClick(text)}
                            disabled={false}
                            data-testid="clear-button"
                        >
                            {CLEAR_TEXT}
                        </Button>
                        <Button
                            className="btn btn-primary bg-primary-500 text-black body4 d-flex align-items-center justify-content-center mb-3 me-2 menu__apply-button"
                            onClick={handleApplyClick}
                            disabled={isDisabled}
                            data-testid="apply-button"
                        >
                            {APPLY_TEXT}
                        </Button>
                    </div>
                </div>
            </li>
        );
    }

    return (
        <li className={`menu__dropdown-item body4`} key={text}>
            {text}
        </li>
    );
};

const ActionButton = ({ selectedOptions, handleResetClick, handleSaveClick }: ActionButtonProps) => {
    const buttonsClassName = classNames({
        disabled: !Boolean(getTotalArrayLength(selectedOptions)),
    });
    return (
        <>
            <button
                type="button"
                className="btn btn-primary menu__arrow"
                data-bs-toggle="dropdown"
                aria-expanded="false"
            >
                <RiArrowDownSLine className="d-flex pe-1" size={20} />
            </button>
            <ul className="dropdown-menu bg-text-black body2">
                <li className={`menu__arrow-item ${buttonsClassName}`} onClick={handleSaveClick} hidden>
                    {SAVE}
                </li>
                <li className={`menu__arrow-item ${buttonsClassName}`} onClick={handleResetClick} hidden>
                    {RESET}
                </li>
                <li className={`menu__arrow-item ${buttonsClassName}`} onClick={handleResetClick}>
                    {CLEAR_ALL_FILTERS}
                </li>
            </ul>
        </>
    );
};

export default FilterDropdown;
