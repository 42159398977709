import { Typography } from 'Components/atoms';
import classNames from 'classnames';
import './styles.scss';

interface ChatMessageProps {
    message: string;
    sender: 'user' | 'ai';
}
const ChatMessage = ({ message, sender }: ChatMessageProps) => {
    const className = classNames('chat-message', {
        'bg-hover-login-cell text-text-high-emphasis rounded-top rounded-left': sender === 'user',
        'bg-text-medium-emphasis text-body-color rounded-bottom rounded-right': sender === 'ai',
    });

    return (
        <div className={className}>
            <Typography variant="body5" style={{ margin: '0%' }}>
                {message}
            </Typography>
        </div>
    );
};

export default ChatMessage;
