import { CustomMetricTags, addPageLoadMetric } from 'Core-utils/helpers/SentryHelpers';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'Rdx/store';

function useTimeSpent(page: string, tags?: Partial<CustomMetricTags>) {
    const { selectedTenant, user } = useSelector((state: RootState) => state.auth);
    useEffect(() => {
        const start = Date.now();

        const cleanup = () => {
            const end = Date.now();
            const elapsedTime = (end - start) / 1000;
            if (Math.floor(elapsedTime) > 0) {
                addPageLoadMetric(elapsedTime, {
                    ...tags,
                    page,
                    tenant: selectedTenant.displayValue,
                    tid: selectedTenant.id,
                    userEmail: user?.email!,
                });
            }
        };

        window.addEventListener('beforeunload', cleanup);

        return () => {
            cleanup();
            window.removeEventListener('beforeunload', cleanup);
        };
    }, []);
}

export default useTimeSpent;
