import React from 'react';
import classNames from 'classnames';

interface WeekdayPickerProps {
    selectedWeekdays: number[];
    onWeekdayClick: (weekdayId: number) => void;
}

const WeekDayPicker: React.FC<WeekdayPickerProps> = ({ selectedWeekdays, onWeekdayClick }) => {
    const weekdays = [
        {
            id: 0,
            name: 'S',
        },
        {
            id: 1,
            name: 'M',
        },
        {
            id: 2,
            name: 'T',
        },
        {
            id: 3,
            name: 'W',
        },
        {
            id: 4,
            name: 'T',
        },
        {
            id: 5,
            name: 'F',
        },
        {
            id: 6,
            name: 'S',
        },
    ];

    return (
        <div className="d-flex gap-2">
            {weekdays.map(({ id, name }) => {
                const className = classNames('btn rounded-circle body4', {
                    'btn-primary-500 text-text-high-emphasis': selectedWeekdays.includes(id),
                    'btn-text-low-emphasis text-body-color': !selectedWeekdays.includes(id),
                });
                return (
                    <button key={id} className={className} onClick={() => onWeekdayClick(id)}>
                        {name}
                    </button>
                );
            })}
        </div>
    );
};

export default WeekDayPicker;
