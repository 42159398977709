import React from 'react';
import { Form } from 'react-bootstrap';
import classNames from 'classnames';
import './styles.scss';

interface TextAreaProps {
    disabled?: boolean;
    value?: string;
    onValueChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
    textLimit?: number;
    isJsonData?: boolean;
}

const TextArea = ({ disabled = false, value, onValueChange, textLimit, isJsonData = false }: TextAreaProps) => {
    const handleValueChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (textLimit && event.target.value.length > textLimit) {
            return;
        }
        onValueChange(event);
    };

    const textAreaClassName = classNames(
        'text-area__form overflow-auto bg-body-color',
        {
            'text-text-low-emphasis': disabled,
            'text-text-high-emphasis mb-3': !disabled,
        },
        'shadow-none custom-scrollbar body3',
        { 'text-area__json-style': isJsonData },
    );

    return (
        <div>
            <Form.Control
                as="textarea"
                value={value}
                onChange={handleValueChange}
                disabled={disabled}
                className={textAreaClassName}
            />
        </div>
    );
};

export default TextArea;
