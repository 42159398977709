import { Modal } from 'react-bootstrap';
import { TfiClose } from '@react-icons/all-files/tfi/TfiClose';
import { CUSTOM_ACTION } from 'Core-utils/constants/constants';
import CustomActionForm from './components/CustomActionStepperForm';
import { CustomActionDto } from '@ampsec/platform-client';
import { getActionById } from 'Apis/library';
import { useEffect, useState } from 'react';
import _ from 'lodash';
import { Loader } from 'Components/molecules';
import classNames from 'classnames';
import './styles.scss';

interface CustomActionWizardProps {
    show: boolean;
    handleSave: (action: CustomActionDto) => void;
    onClose: () => void;
    id?: string;
}

const CustomActionWizard = ({ show, handleSave, onClose, id }: CustomActionWizardProps) => {
    const [customData, setCustomData] = useState<CustomActionDto>({} as CustomActionDto);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        setIsLoading(true);
        if (id) {
            const fetchData = async () => {
                try {
                    const result = await getActionById(id);
                    const modifiedResult = _.cloneDeep(result);
                    try {
                        const parsedValue = JSON.parse(modifiedResult?.meta?.request?.payload?.value ?? '');
                        const stringValue = JSON.stringify(parsedValue, null, 2);
                        _.set(modifiedResult ?? {}, 'meta.request.payload.value', stringValue);
                    } catch (error) {}
                    setCustomData({ ...modifiedResult } as CustomActionDto);
                } finally {
                    setIsLoading(false);
                }
            };
            fetchData();
        } else {
            setIsLoading(false);
            setCustomData({} as CustomActionDto);
        }
    }, [id]);

    const handleActionsSave = () => {
        const newAction: CustomActionDto = {
            ...customData,
        };
        handleSave(newAction);
        if (!id) {
            setCustomData({} as CustomActionDto);
        }
    };
    const handleOnClose = () => {
        onClose();
        setCustomData({} as CustomActionDto);
    };
    const handleFieldChange = (field: string, value: string) => {
        switch (field) {
            case 'url':
            case 'method':
            case 'headers':
            case 'parameters':
                setCustomData((prevData: CustomActionDto) => {
                    return _.set(_.cloneDeep(prevData), `meta.request.${field}`, value);
                });
                break;
            case 'payload':
                setCustomData((prevData: CustomActionDto) => {
                    return _.set(_.cloneDeep(prevData), `meta.request.payload.format`, value);
                });
                break;
            case 'textAreaValue':
                setCustomData((prevData: CustomActionDto) => {
                    return _.set(_.cloneDeep(prevData), `meta.request.payload.value`, value);
                });
                break;
            case 'displayValue':
            case 'description':
                setCustomData((prevData: CustomActionDto) => ({ ...prevData, [field]: value }));
                break;
            default:
                break;
        }
    };

    const handleTableChange = (dataKey: 'headers' | 'parameters', index: number, field: string) => (
        value: string | React.ChangeEvent<HTMLTextAreaElement>,
    ) => {
        const updatedValue = typeof value === 'string' ? value : value.target.value;
        setCustomData((prevData: CustomActionDto) => {
            const newData = _.cloneDeep(prevData);
            if (dataKey === 'headers' && !_.isEmpty(newData.meta?.request?.headers)) {
                _.set(
                    newData,
                    `meta.request.headers`,
                    Object.fromEntries(
                        Object.entries(newData.meta?.request?.headers)?.map(([key, val], currentIndex) =>
                            currentIndex === index
                                ? [field === 'key' ? updatedValue : key, field === 'value' ? updatedValue : val]
                                : [key, val],
                        ),
                    ),
                );
            }
            if (dataKey === 'parameters' && !_.isEmpty(newData.meta?.request?.params)) {
                _.set(
                    newData,
                    `meta.request.params`,
                    Object.fromEntries(
                        Object.entries(newData.meta?.request?.params).map(([key, val], currentIndex) =>
                            currentIndex === index
                                ? [field === 'key' ? updatedValue : key, field === 'value' ? updatedValue : val]
                                : [key, val],
                        ),
                    ),
                );
            }
            return newData;
        });
    };

    const handleAddRow = (dataKey: 'headers' | 'parameters') => () => {
        setCustomData((prevData: CustomActionDto) => {
            const newData = _.cloneDeep(prevData);
            const newField = '';
            if (dataKey === 'headers') {
                _.set(newData, `meta.request.headers.${newField}`, '');
            } else {
                _.set(newData, `meta.request.params.${newField}`, '');
            }
            return newData;
        });
    };

    const handleDeleteRow = (dataKey: 'headers' | 'parameters') => (index: number) => () => {
        setCustomData((prevData: CustomActionDto) => {
            const newData = { ...prevData.meta?.request };
            if (dataKey === 'headers' && newData.headers) {
                delete newData.headers[Object.keys(newData.headers)[index]];
            }
            if (dataKey === 'parameters' && newData.params) {
                delete newData.params[Object.keys(newData.params)[index]];
            }
            return { ...prevData, meta: { ...prevData.meta, request: newData } };
        });
    };

    const modalBodyClassName = classNames(
        'bg-body-color text-text-high-emphasis rounded-bottom border border-structural-stroke-100 border-top-0',
        {
            'd-flex justify-content-center': isLoading,
        },
    );

    return (
        <Modal
            show={show}
            centered
            size="lg"
            className="custom-action-wizard"
            data-testid="custom-action-wizard"
            onHide={handleOnClose}
        >
            <Modal.Header className="custom-action-wizard__header bg-body-color text-text-high-emphasis border border-structural-stroke-100 rounded-top">
                <Modal.Title className="subtitle1 p-1 text-uppercase">{CUSTOM_ACTION}</Modal.Title>
                <TfiClose
                    className="text-text-high-emphasis btn-close position-absolute end-0 me-2"
                    onClick={handleOnClose}
                    size={10}
                    data-testid="btn-close"
                />
            </Modal.Header>
            <Modal.Body className={modalBodyClassName}>
                {isLoading ? (
                    <Loader />
                ) : (
                    <CustomActionForm
                        handleSave={handleActionsSave}
                        data={customData}
                        handleFieldChange={handleFieldChange}
                        handleAddRow={handleAddRow}
                        handleDeleteRow={handleDeleteRow}
                        handleTableChange={handleTableChange}
                    />
                )}
            </Modal.Body>
        </Modal>
    );
};

export default CustomActionWizard;
