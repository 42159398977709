import React from 'react';
import { Typography } from 'Components/atoms';
import { UserLineGraph } from 'Components/molecules';
import { IN_THIS_QUARTER, VULNERABILITY_MTTR } from 'Core-utils/constants/constants';
import { StatisticsCard } from 'Components/atoms/StatisticsCard';
import './styles.scss';

export interface Series {
    data: number[];
    name: string;
}

export interface VulnerabilityMTTRAnalysisProps {
    series: Series[];
    categories: string[];
}

const colors = ['#FF2629', '#FF8C22', '#FFFF07', '#65CC01'];

const severityLevels = [
    { color: colors[0], label: 'Critical' },
    { color: colors[1], label: 'High' },
    { color: colors[2], label: 'Medium' },
    { color: colors[3], label: 'Low' },
];

const VulnerabilityMTTRAnalysis = ({ series, categories }: VulnerabilityMTTRAnalysisProps) => {
    const renderHeaderBody = () => {
        return (
            <div className="d-flex flex-column align-items-center vulnerability-mttr gap-2">
                <div className="d-flex align-items-center gap-2">
                    {severityLevels.map(({ color, label }, index) => (
                        <React.Fragment key={index}>
                            <div className="vulnerability-mttr__legend" style={{ backgroundColor: color }}></div>
                            <Typography variant="overline2" color="text-medium-emphasis" style={{ marginBottom: '0%' }}>
                                {label}
                            </Typography>
                            {index !== severityLevels.length - 1 && <div className="vr" />}
                        </React.Fragment>
                    ))}
                </div>
            </div>
        );
    };

    const renderBody = (
        <div className="d-flex align-items-center justify-content-center h-100 w-100">
            <UserLineGraph
                series={series}
                categories={categories}
                lineColors={colors}
                showDataLabels={false}
                width={[2, 2, 2, 2]}
            />
        </div>
    );
    return (
        <div className="h-100 w-100">
            <StatisticsCard
                header={VULNERABILITY_MTTR}
                subHeader={IN_THIS_QUARTER}
                headerBody={renderHeaderBody()}
                body={renderBody}
            />
        </div>
    );
};

export default VulnerabilityMTTRAnalysis;
