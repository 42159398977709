import { useI18n } from 'Hooks/useI18n';
import { DynamicDropdown } from '../DynamicDropdown';
import { usAndEuropeanTimezones } from 'Core-utils/helpers/helpers';

interface EventTimeZoneSettingProps {
    selectedTimezone: string;
    onTimezoneChange: (timezone: string) => void;
}

const EventTimeZoneSetting = ({ selectedTimezone, onTimezoneChange }: EventTimeZoneSettingProps) => {
    const { t } = useI18n();
    return (
        <div className="settings-page__dropdown-container">
            <DynamicDropdown
                variant="default"
                options={usAndEuropeanTimezones}
                selectedOptions={selectedTimezone}
                onOptionSelected={onTimezoneChange}
                placeholder={t('SELECT_TIMEZONE_PLACEHOLDER')}
            />
        </div>
    );
};

export default EventTimeZoneSetting;
