import { Typography } from 'Components/atoms';
import React from 'react';
import { ReactSVG } from 'react-svg';
import './styles.scss';
import classNames from 'classnames';
interface TooltipContentProps {
    icon?: React.ReactNode;
    headerLabel?: string;
    headerIcon?: string | React.ReactNode;
    customContent?: { icon: React.ReactNode; value: string }[];
    content?: { key: string; value: string }[];
}

const ToolingCoverageTooltipContent = ({
    icon,
    headerLabel,
    headerIcon,
    customContent,
    content,
}: TooltipContentProps) => {
    const renderHeader = () =>
        typeof headerIcon === 'string' ? (
            <ReactSVG src={headerIcon} className="tooltip-content__react-svg" />
        ) : (
            <>{headerIcon}</>
        );
    const renderContent = () =>
        content?.map((dataItem) => (
            <div key={`dataItem-${dataItem.key}`} className="d-flex gap-1 justify-content-start p-0 align-items-start">
                <Typography
                    variant="caption4"
                    color="structural-stroke-100"
                    style={{
                        marginBottom: '0%',
                        minWidth: '120px',
                        display: 'flex',
                        justifyContent: 'flex-start',
                    }}
                >
                    {dataItem.key}
                </Typography>
                <Typography
                    variant="caption4"
                    color="structural-CardBG"
                    style={{
                        marginBottom: '0%',
                        minWidth: '120px',
                        display: 'flex',
                        justifyContent: 'flex-start',
                    }}
                >
                    {dataItem.value}
                </Typography>
            </div>
        ));

    const renderCustomContent = () => {
        const className = classNames({
            'col-4': (customContent?.length ?? 0) > 2,
            'col-6': (customContent?.length ?? 0) === 2,
            'col-12': (customContent?.length ?? 0) === 1,
        });

        return (
            <div className="d-grid w-100">
                <div className="row w-100">
                    {customContent?.map((datItem, index) => (
                        <div
                            key={index}
                            className={`${className} d-flex gap-2 align-items-center justify-content-center`}
                        >
                            {datItem.icon}
                            <Typography
                                variant="caption4"
                                color="body-color"
                                style={{ marginBottom: '0rem', maxWidth: '108px' }}
                            >
                                {datItem.value}
                            </Typography>
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    return (
        <div className="d-flex flex-column gap-2 bg-text-medium-emphasis ps-3 pe-3 pb-3 tooltip-content justify-content-start">
            {icon && <div className="d-flex justify-content-center align-items-center">{icon}</div>}
            <div className="d-flex gap-1 align-items-center">
                {headerIcon && renderHeader()}

                {headerLabel && (
                    <Typography variant={'body7'} color="text-black" style={{ marginBottom: '0%' }}>
                        {headerLabel}
                    </Typography>
                )}
            </div>
            {customContent ? renderCustomContent() : renderContent()}
        </div>
    );
};

export default ToolingCoverageTooltipContent;
