import { Navigate } from 'react-router-dom';
import { tabs } from 'Core-utils/constants/constants';
import { Organisation } from 'Components/organisms';
import { DashboardTemplate } from 'Components/templates';
import { Users } from 'Components/organisms';
import { Route, Routes } from 'react-router-dom';
import {
    DEPT_ROUTE,
    ORG_ROUTE,
    USERS_ROUTE,
    NOT_FOUND_ROUTE,
    DEFAULT_ROUTE,
    ORG_DASHBOARD_ROUTE,
} from 'Core-utils/routes';
import { NotFoundPage } from 'Components/pages';
import { DepartmentTab } from 'Components/organisms';

const DashboardPage = () => {
    const DashboardComponent = () => {
        return (
            <DashboardTemplate tabs={tabs}>
                <Routes>
                    <Route path={DEFAULT_ROUTE} element={<Navigate to={ORG_DASHBOARD_ROUTE} />} />
                    <Route path={ORG_ROUTE} element={<Organisation />} />
                    {/* TODO:To be changed with Department tab */}
                    <Route path={DEPT_ROUTE} element={<DepartmentTab />} />
                    <Route path={USERS_ROUTE} element={<Users />} />
                    <Route path={NOT_FOUND_ROUTE} element={<NotFoundPage />} />
                </Routes>
            </DashboardTemplate>
        );
    };

    return <DashboardComponent />;
};

export default DashboardPage;
