import { ChatMessage } from 'Components/molecules/ChatMessage';
import { Image } from 'react-bootstrap';
import AmplifierLogo from 'Assets/icons/AmpSec.svg';
import { PREVIEW_ENGAGEMENT } from 'Core-utils/constants/constants';
import { Typography } from 'Components/atoms';
import './styles.scss';

export interface Message {
    message: string;
    timestamp: number;
}

interface PreviewEngagementProps {
    messages: Message[];
}

const PreviewEngagement = ({ messages = [] }: PreviewEngagementProps) => {
    const sortedMessages = [...messages].sort((a, b) => a.timestamp - b.timestamp);

    const renderMessages = () => {
        return sortedMessages.map((msg, index) => (
            <div className="mb-2" key={index}>
                <div className="d-flex justify-content-start align-items-start gap-2">
                    <Image src={AmplifierLogo} />
                    <ChatMessage message={msg.message} sender="ai" />
                </div>
            </div>
        ));
    };

    return (
        <div
            className="preview-engagement bg-structural-CardBG rounded d-flex flex-column flex-grow-1 position-relative"
            data-testid="preview-engagement"
        >
            <div className="text-uppercase  mt-3">
                <Typography variant="body8" color="text-high-emphasis">
                    {PREVIEW_ENGAGEMENT}
                </Typography>
            </div>
            <div className="custom-scrollbar">{renderMessages()}</div>
        </div>
    );
};

export default PreviewEngagement;
