import { Typography } from 'Components/atoms';
import './styles.scss';

interface InviteUserDetailsProps {
    userId: string;
    email: string;
    revokeInvitation: (id: string) => void;
}

const InviteUserDetails = (props: InviteUserDetailsProps) => {
    const { email } = props;

    return (
        <div
            className="user-invited d-flex flex-row justify-content-between align-items-center"
            data-testid="user-invited"
        >
            <Typography variant="body5" color="text-high-emphasis" className="mb-0">
                {email}
            </Typography>
        </div>
    );
};

export default InviteUserDetails;
