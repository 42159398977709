import React from 'react';
import Card from 'react-bootstrap/Card';
import classNames from 'classnames';
import './styles.scss';

export interface CardProps {
    cardType: 'oneThird' | 'twoThird' | 'custom';
    title?: React.ReactNode;
    subTitle?: React.ReactNode;
    content?: React.ReactNode;
    width?: string;
    height?: string;
    onCardClick?: () => void;
}

const CustomCard = (props: CardProps) => {
    const cardClassName = classNames({
        'card__cursor-pointer': props.onCardClick,
    });

    return props.cardType === 'oneThird' ? (
        <div className={`card card--square ${cardClassName}`} data-testid="card-variant1" onClick={props.onCardClick}>
            <Card.Body className="card__body">
                <Card.Title className="card__title w-100">{props.title}</Card.Title>
                <Card.Subtitle className="card__subtitle">{props.subTitle}</Card.Subtitle>
                <Card.Text className="card__content">{props.content}</Card.Text>
            </Card.Body>
        </div>
    ) : props.cardType === 'twoThird' ? (
        <Card
            className={`card card--rectangle ${cardClassName}`}
            data-testid="card-variant2"
            onClick={props.onCardClick}
        >
            <Card.Body className="card__body">
                <Card.Title className="card__title">{props.title}</Card.Title>
                <Card.Text className="card__content">{props.content}</Card.Text>
            </Card.Body>
        </Card>
    ) : (
        <Card className={`card card--custom ${cardClassName}`} data-testid="card-variant3">
            <Card.Body className="card__body">
                <Card.Title className="card__title">{props.title}</Card.Title>
                <Card.Text className="card__content">{props.content}</Card.Text>
            </Card.Body>
        </Card>
    );
};

export default CustomCard;
