import { Typography } from 'Components/atoms';
import { getInitials } from 'Core-utils/helpers/helpers';
import { AvatarWithNameType, AvatarWithImageType, AvatarWithImageAndHeightType } from 'Core-utils/types/types';
import './avatar.scss';

interface AvatarProps {
    height?: string;
    width?: string;
    variant: 'avatar' | 'name_avatar';
    image?: string;
    name: string;
    status?: 'warn' | 'active';
}

const AvatarInitials = ({ name, height, width }: AvatarWithNameType) => (
    <div
        className={`avatar avatar__initials d-flex align-items-center justify-content-center`}
        style={{ height, width }}
    >
        <Typography variant="body2">{getInitials(name, height, width)}</Typography>
    </div>
);

const AvatarWithImage = ({ image }: AvatarWithImageType) => <img src={image} className="avatar__avatar-with-name" />;

const AvatarWithNameAndInitials = ({ name, height, width }: AvatarWithNameType) => (
    <div className="avatar__avatar-with-name avatar__avatar-with-name--initials d-flex align-items-center justify-content-center">
        <Typography variant="body2">{getInitials(name, height, width)}</Typography>
    </div>
);

const AvatarImage = ({ image, height }: AvatarWithImageAndHeightType) => (
    <img className="avatar__avatar-image" src={image} style={{ height }} data-testid="avatar-image" />
);

const Avatar = ({ variant, image, name, height = '', width = '', status }: AvatarProps) => {
    const AvatarContent = () => {
        return (
            <>
                {variant === 'avatar' ? (
                    <>
                        {image ? (
                            <AvatarImage image={image} height={height} />
                        ) : (
                            <AvatarInitials name={name} height={height} width={width} />
                        )}
                    </>
                ) : (
                    <>
                        {image ? (
                            <AvatarWithImage image={image} />
                        ) : (
                            <AvatarWithNameAndInitials name={name} height={height} width={width} />
                        )}
                        <div>
                            <Typography variant="body5">{name}</Typography>
                        </div>
                    </>
                )}
            </>
        );
    };
    const className = status === 'active' ? 'avatar__icon__active' : 'avatar__icon__inactive';
    const avatarClassName = variant === 'avatar' ? 'avatar__icon' : 'd-flex gap-2';
    return (
        <div className="avatar ">
            <div className={avatarClassName}>
                <AvatarContent />
                {status && variant === 'avatar' && (
                    <span
                        className={`avatar__icon__dot ${className}`}
                        style={{
                            width: `calc((1/3) * ${width})`,
                            height: `calc((1/3) * ${height})`,
                        }}
                    ></span>
                )}
            </div>
        </div>
    );
};

export default Avatar;
