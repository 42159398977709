import { REPORTS } from '@ampsec/platform-client';
import { getMetricsReportsData } from 'Apis/library';
import { SECURITY_HEALTH_LABEL } from 'Core-utils/constants/constants';
import { TrendingMetricResults } from 'Core-utils/types/types';
import { useApiObjectData } from 'Hooks/useApiObjectData';
import ErrorHandlerAndLoaderWrapper from 'Wrappers/ErrorHandlerandLoaderWrapper';
import { CustomCard } from 'Components/molecules';
import { IconWithTypography } from 'Components/molecules';
import { Speedometer } from 'Components/molecules';
import healthScoreIcon from 'Assets/icons/healthScore.svg';
import _ from 'lodash';

const COMPONENT_IDENTIFIER = 'Security Health';

interface UserSecurityHealthCardProps {
    criticalRisk: number;
    organization: string;
    department?: string;
    disableDepartment?: boolean;
    title: string;
}

const UserSecurityHealthCard = ({
    criticalRisk,
    organization = 'NOT DEFINED',
    department,
    title,
    disableDepartment,
}: UserSecurityHealthCardProps) => {
    const {
        data: orgData,
        error: orgDataError,
        isLoading: orgDetailsLoading,
        refresh: orgDataRefresh,
    } = useApiObjectData<TrendingMetricResults, TrendingMetricResults>(
        REPORTS.HEALTH_SCORE,
        getMetricsReportsData,
        (a) => a,
        { organization, department: 'ALL' },
    );

    let deptData: TrendingMetricResults, deptDataError, deptDetailsLoading, deptDataRefresh;

    if (department) {
        ({
            data: deptData,
            error: deptDataError,
            isLoading: deptDetailsLoading,
            refresh: deptDataRefresh,
        } = useApiObjectData<TrendingMetricResults, TrendingMetricResults>(
            REPORTS.HEALTH_SCORE,
            getMetricsReportsData,
            (a) => a,
            { organization, department: department },
        ));
    }
    return (
        <div className="h-100 w-100">
            <CustomCard
                cardType="oneThird"
                title={<IconWithTypography isRow={true} label={title} labelColor="primary" />}
                content={
                    <ErrorHandlerAndLoaderWrapper
                        error={orgDataError || deptDataError}
                        isLoading={orgDetailsLoading || deptDetailsLoading}
                        render={() => (
                            <Speedometer
                                value={criticalRisk}
                                variant="user"
                                orgValue={orgData?.[SECURITY_HEALTH_LABEL]?.value}
                                deptValue={deptData?.[SECURITY_HEALTH_LABEL]?.value}
                                disableDepartment={disableDepartment}
                            />
                        )}
                        handleRefresh={orgDataRefresh || deptDataRefresh}
                        isEmpty={_.isEmpty(orgData)}
                        ImageSrc={healthScoreIcon}
                        component={COMPONENT_IDENTIFIER}
                    />
                }
            />
        </div>
    );
};

export default UserSecurityHealthCard;
