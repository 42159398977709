import React from 'react';
import classnames from 'classnames';
import { Button } from 'react-bootstrap';
import { IoArrowBackOutline } from '@react-icons/all-files/io5/IoArrowBackOutline';
import { IoArrowForwardOutline } from '@react-icons/all-files/io5/IoArrowForwardOutline';
import { Color, StepperType } from 'Core-utils/types';
import { STEPPER_BUTTON_LABELS } from 'Core-utils/constants/constants';
import { Typography } from 'Components/atoms';
import './styles.scss';

interface StepperProps {
    steps: StepperType[];
    currentStep: number;
    handleBackButtonClick: () => void;
    handleNextButtonClick: () => void;
    handleSave: () => void;
}

const Stepper = ({ steps, currentStep, handleBackButtonClick, handleNextButtonClick, handleSave }: StepperProps) => {
    const getLineClasses = (index: number) =>
        classnames('stepper-container__line', {
            'bg-primary-500': index <= currentStep,
            'bg-text-low-emphasis': index > currentStep,
            'stepper-container__opacity-reduced': index < currentStep,
        });

    const getCircleClasses = (index: number) =>
        classnames('stepper-container__circle d-flex flex-column justify-content-center align-items-center ', {
            'border-primary-500': index <= currentStep,
            'border-text-low-emphasis': index > currentStep,
            'stepper-container__opacity-reduced': index < currentStep,
        });

    const getTextClasses = (index: number) =>
        classnames('stepper-container__step-text d-flex justify-content-center', {
            active: index === currentStep,
        });

    const getButtonClasses = (isSaveButton: boolean) => {
        const baseClasses = 'body1 d-flex align-items-center justify-content-center gap-2 text-uppercase';
        const specificClasses = isSaveButton
            ? 'stepper-container__button-save ' + baseClasses
            : 'stepper-container__button-navigation ' + baseClasses;

        return {
            className: specificClasses,
        };
    };

    const getColorForStepName = (index: number): Color => {
        if (index < currentStep) {
            return 'text-high-emphasis';
        } else if (index === currentStep) {
            return 'primary-500';
        } else {
            return 'text-medium-emphasis';
        }
    };

    const renderCirclesAndLines = () =>
        steps.map((_, index) => (
            <React.Fragment key={index}>
                {index > 0 && <div className={getLineClasses(index)} />}
                <div className={getCircleClasses(index)}>
                    <Typography variant="body1" color={index <= currentStep ? 'primary-500' : 'text-low-emphasis'}>
                        {index + 1}
                    </Typography>
                </div>
            </React.Fragment>
        ));

    const renderStepText = () =>
        steps.map((step, index) => (
            <div key={index} className={getTextClasses(index)}>
                <Typography
                    variant="body6"
                    color={getColorForStepName(index)}
                    style={{
                        textTransform: 'uppercase',
                        maxWidth: '10rem',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                    }}
                >
                    {step.stepperName}
                </Typography>
            </div>
        ));

    const renderStepperContent = () => (
        <div className="stepper-container__stepper-content">{steps[currentStep].stepperContent}</div>
    );

    return (
        <div className="stepper-container d-flex flex-column justify-content-between" data-testid="multi-step-wizard">
            <div className="d-flex flex-column">
                <div className="w-100 d-flex flex-column align-items-center gap-2">
                    <div className="stepper-container__circle-container d-flex justify-content-between align-items-baseline">
                        {renderCirclesAndLines()}
                    </div>
                    <div className="d-flex justify-content-between w-100">{renderStepText()}</div>
                </div>
                {renderStepperContent()}
            </div>
            <div className="d-flex justify-content-between">
                <Button
                    {...getButtonClasses(false)}
                    onClick={handleBackButtonClick}
                    style={{ visibility: currentStep === 0 ? 'hidden' : 'visible' }}
                    variant="outline-primary-500"
                    data-testid="back-button"
                >
                    <IoArrowBackOutline />
                    {STEPPER_BUTTON_LABELS[0]}
                </Button>
                <Button
                    {...getButtonClasses(currentStep === steps.length - 1)}
                    onClick={currentStep === steps.length - 1 ? handleSave : handleNextButtonClick}
                    variant={currentStep === steps.length - 1 ? 'primary-500' : 'outline-primary-500'}
                    data-testid={`save-${steps.length - 1}`}
                >
                    {currentStep === steps.length - 1 ? STEPPER_BUTTON_LABELS[2] : STEPPER_BUTTON_LABELS[1]}
                    {currentStep !== steps.length - 1 && <IoArrowForwardOutline />}
                </Button>
            </div>
        </div>
    );
};

export default Stepper;
