import { useState, ChangeEvent } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { AiOutlineClose } from '@react-icons/all-files/ai/AiOutlineClose';
import { Typography } from 'Components/atoms';
import { DropdownComponent } from 'Components/molecules';
import { COPY_SECURITY_TEAM, ENGAGE_HEADER, INCLUDE_FINDINGS_DATA, MEDIUM, SEND } from 'Core-utils/constants/constants';
import Mail from 'Assets/icons/Mail.svg';
import { RootState } from 'Rdx/store';
import { useSelector } from 'react-redux';
import { DeliveryStrategyKind } from '@ampsec/platform-client';
import classNames from 'classnames';
import { useProviderData } from 'Hooks/useProviderData';
import { useConnectorData } from 'Hooks/useConnectorsData';
import './styles.scss';

interface EngageModalProps {
    show: boolean;
    onClose: () => void;
    onSend: (text: string, strategy: DeliveryStrategyKind, includeFinding?: boolean) => void;
    copySecurityTeam: boolean;
    onChangeCheckbox: React.ChangeEventHandler<HTMLInputElement>;
    findingsData?: boolean;
}

type EngageDropdownItem = {
    id: string;
    icon: any;
    value: string;
    deliveryStrategy: DeliveryStrategyKind;
    disabled: boolean;
};

const EngageModal = ({
    show,
    onClose,
    onSend,
    copySecurityTeam,
    onChangeCheckbox,
    findingsData = true,
}: EngageModalProps) => {
    const featureFlag = useSelector((state: RootState) => state.featureModel.enableAlphaFeatures);
    const [includeFinding, setIncludeFinding] = useState(false);
    const [text, setText] = useState<string>('');
    const { connectorsByCategoryGroup } = useConnectorData();

    const formCheckClasses = classNames('mt-3 text-text-high-emphasis body5 mb-2', { invisible: !featureFlag });
    const includeFindingClass = classNames('engage-modal__switch p-0 text-text-high-emphasis body5', {
        invisible: findingsData === false,
    });
    const engageDropdownItems = [
        { id: '1', icon: Mail, value: 'Email', deliveryStrategy: DeliveryStrategyKind.EMAIL, disabled: false },
    ];
    const [selectedDropdownItem, setSelectedDropdownItem] = useState<EngageDropdownItem>(engageDropdownItems[0]);

    const hasBanner = useSelector((state: RootState) => state.featureModel.enableBanner);
    const { connectorProviderMap } = useProviderData();
    const collaborationDropdownItems = connectorsByCategoryGroup['Collaboration']?.map((collaboration) => ({
        id: collaboration.id,
        icon: connectorProviderMap.find((item) => item.cid === collaboration.cid)?.providerIcon,
        value: collaboration.title,
        deliveryStrategy: DeliveryStrategyKind.SLACK,
        disabled: hasBanner,
    }));

    const handleTextChange = (event: ChangeEvent<HTMLInputElement>) => {
        setText(event.target.value);
    };

    const handleSend = () => {
        onSend(text, selectedDropdownItem?.deliveryStrategy, includeFinding);
        setText('');
    };

    const dropdownItems = engageDropdownItems.concat(collaborationDropdownItems);
    const handleDropdownChange = (selected: string | null) => {
        setSelectedDropdownItem(dropdownItems?.find((item) => item.value === selected) as EngageDropdownItem);
    };

    const handleIncludeFindingChange = () => setIncludeFinding((includeFinding) => !includeFinding);

    return (
        <div>
            <Modal
                data-testid="modal"
                className="engage-modal"
                show={show}
                onHide={onClose}
                aria-hidden="true"
                centered
            >
                <Modal.Header>
                    <Modal.Title className="text-uppercase">
                        <Typography variant="h4" color="text-high-emphasis">
                            {ENGAGE_HEADER}
                        </Typography>
                    </Modal.Title>
                    <AiOutlineClose
                        className="text-text-high-emphasis engage-modal__icon"
                        onClick={onClose}
                        data-testid="close-icon"
                    />
                </Modal.Header>
                <Modal.Body>
                    <Typography variant="body3" color="text-high-emphasis">
                        {MEDIUM}
                    </Typography>
                    <div className="engage-modal__dropdown">
                        <DropdownComponent
                            value={selectedDropdownItem?.value}
                            dropDownItems={dropdownItems}
                            onChange={handleDropdownChange}
                            selectedItemIcon={selectedDropdownItem?.icon}
                        />
                    </div>
                    <Form.Control
                        as="textarea"
                        className="engage-modal__form mt-4 overflow-auto bg-body-color text-text-high-emphasis border-text-low-emphasis shadow-none custom-scrollbar"
                        onChange={handleTextChange}
                        value={text}
                        data-testid="text-input"
                    />
                    <div className="d-flex align-items-center justify-content-between pt-1">
                        <Form.Check
                            type="switch"
                            id="include-finding-data"
                            className={includeFindingClass}
                            label={INCLUDE_FINDINGS_DATA}
                            checked={includeFinding}
                            onChange={handleIncludeFindingChange}
                        />
                        <Form.Check
                            inline
                            type="checkbox"
                            label={COPY_SECURITY_TEAM}
                            className={formCheckClasses}
                            checked={copySecurityTeam}
                            onChange={onChangeCheckbox}
                            data-testid="copy-security-team-checkbox"
                        />
                        <div className="d-flex justify-content-end pt-1">
                            <Button
                                className="btn btn-primary-500 btn-lg body1 bg-structural-selected text-text-black  engage-modal__button text-uppercase"
                                disabled={text === ''}
                                onClick={handleSend}
                            >
                                {SEND}
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default EngageModal;
