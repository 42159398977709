import { useState } from 'react';
import { Button } from 'react-bootstrap';
import _ from 'lodash';
import { ACCESS_OPTIONS, INVITE } from 'Core-utils/constants/constants';
import { InviteUserModal } from 'Components/organisms';
import { MenuItem } from 'Core-utils/types';
import './styles.scss';

interface InviteSubHeaderContentProps {
    dropdownValue?: string;
    emailInput: string;
    isValidEmail: boolean;
    disableInviteButton: boolean;
    invitedUserList: MenuItem[];
    addUser: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    onInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onDropdownChange?: (eventKey: string | null, e: any) => void;
}

const InviteSubHeaderContent = ({
    isValidEmail,
    dropdownValue,
    emailInput,
    invitedUserList,
    disableInviteButton,
    onInputChange,
    onDropdownChange,
    addUser,
}: InviteSubHeaderContentProps) => {
    const [show, setShow] = useState<boolean>(false);

    const handleInviteClick = () => setShow(true);

    const handleClose = () => setShow(false);

    return (
        <div>
            <Button
                variant="primary"
                className="text-uppercase body1 bg-primary-500 text-structural-stroke-500 settings-page__invite-button px-3 py-2"
                onClick={handleInviteClick}
            >
                {INVITE}
            </Button>
            <InviteUserModal
                show={show}
                invitedUserList={invitedUserList}
                onClose={handleClose}
                addUser={addUser}
                revokeInvitation={() => {}}
                onDropdownChange={onDropdownChange}
                dropdownValue={dropdownValue}
                emailInput={emailInput}
                onInputChange={onInputChange}
                dropdownItems={ACCESS_OPTIONS}
                isValid={isValidEmail}
                disableInviteButton={disableInviteButton}
            />
        </div>
    );
};

export default InviteSubHeaderContent;
