import { REPORTS } from '@ampsec/platform-client';
import { CustomCard } from 'Components/molecules';
import { IconWithTypography } from 'Components/molecules';
import { DonutGraph } from 'Components/molecules';
import { TOP_RISK_CONTRIBUTORS } from 'Core-utils/constants/constants';
import ErrorHandlerAndLoaderWrapper from 'Wrappers/ErrorHandlerandLoaderWrapper';
import { getMetricsReportsData } from 'Apis/library';
import { BaseMetricResults } from 'Core-utils/types/types';
import { useApiObjectData } from 'Hooks/useApiObjectData';
import riskContributorsIcon from 'Assets/icons/riskContributors.svg';
import { toRiskContributorsData } from 'Core-utils/helpers/helpers';
import _ from 'lodash';
import './styles.scss';

const COMPONENT_IDENTIFIER = 'Organization Top Risk Contributors';

const TopRiskContributors = () => {
    const { data, error, isLoading, refresh } = useApiObjectData<BaseMetricResults, BaseMetricResults>(
        REPORTS.TOP_RISK_CONTRIBUTORS,
        getMetricsReportsData,
        (a) => a,
        { department: 'ALL' },
    );

    const datasets = [
        {
            data: toRiskContributorsData(data),
            backgroundColor: ['#9975ff', '#534d66', '#dc3b3b', '#f1df3a'],
            borderColor: '#000000',
            name: 'Organization',
        },
    ];
    return (
        <div className="top-risk-contributors h-100" data-testid="top-risk-contributors">
            <CustomCard
                cardType="oneThird"
                title={<IconWithTypography isRow={true} label={TOP_RISK_CONTRIBUTORS} labelColor="primary" />}
                content={
                    <div className="top-risk-contributors__error-container">
                        <ErrorHandlerAndLoaderWrapper
                            error={error}
                            isLoading={isLoading}
                            render={() => <DonutGraph datasets={datasets} />}
                            handleRefresh={refresh}
                            isEmpty={_.isEmpty(data)}
                            ImageSrc={riskContributorsIcon}
                            component={COMPONENT_IDENTIFIER}
                        />
                    </div>
                }
            />
        </div>
    );
};

export default TopRiskContributors;
