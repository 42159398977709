import { useContext, useEffect, useState } from 'react';
import { Table } from 'Components/organisms';
import { Typography } from 'Components/atoms';
import { RiErrorWarningFill } from '@react-icons/all-files/ri/RiErrorWarningFill';
import {
    CONFIGURE_FINDINGS_SCORE_LABELS,
    DEDUCTION_SCORE_ERROR,
    ERROR_SCORE_TEXT,
    POINTS,
} from 'Core-utils/constants/constants';
import { Image } from 'react-bootstrap';
import { TableColumn } from 'react-data-table-component';
import { Form } from 'react-bootstrap';
import { updateFindings } from 'Apis/library';
import { FindingSpecDto } from '@ampsec/platform-client';
import { useProviderData } from 'Hooks/useProviderData';
import AmplifierLogo from 'Assets/icons/AmpSec.svg';
import { ToastsContext } from 'Rdx/contexts/ToastsContext';
import { CustomTooltip } from 'Components/atoms';
import { ToastMessage } from 'Components/molecules';
import { useI18n } from 'Hooks/useI18n';
import { startCase } from 'lodash';
import './styles.scss';

const HealthScoreConfigurationTable = () => {
    const { addToast } = useContext(ToastsContext);
    const [refreshData, setRefreshData] = useState<boolean>(false);
    const { connectorProviderMap } = useProviderData();
    const { t } = useI18n();

    const HEALTH_SCORE_CONFIG_COLUMNS: TableColumn<FindingSpecDto>[] = [
        {
            id: 'findings',
            name: (
                <Typography variant="body2" color="text-black">
                    {CONFIGURE_FINDINGS_SCORE_LABELS[0]}
                </Typography>
            ),
            cell: (row: FindingSpecDto) => {
                const finding = connectorProviderMap.find((item) => item.cid === row.cid);
                return (
                    <div className="d-flex flex-row gap-2">
                        <Image src={finding?.providerIcon ?? AmplifierLogo} width={14} height={17} />
                        <Typography variant="body10" color="text-high-emphasis">
                            {startCase(row.displayValue)}
                        </Typography>
                    </div>
                );
            },
            center: false,
        },
        {
            id: 'score',
            name: (
                <Typography variant="body2" color="text-black">
                    {CONFIGURE_FINDINGS_SCORE_LABELS[1]}
                </Typography>
            ),
            sortable: false,
            wrap: true,
            width: '10.25rem',
            cell: (row: FindingSpecDto) => {
                const [formControlValue, setFormControlValue] = useState<number | null>(row?.score ?? 0);
                const [isValidInput, setIsValidInput] = useState<boolean>(true);
                const [initialLoad, setInitialLoad] = useState<boolean>(true);

                const handleFormControlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
                    const intValue = parseInt(e.target.value, 10);
                    if (!isNaN(intValue)) {
                        setFormControlValue(intValue);
                    } else {
                        setFormControlValue(null);
                    }
                };

                const updateCustomScoreValues = () => {
                    if (!initialLoad && isValidInput) {
                        updateFindings({
                            ...row,
                            score: formControlValue ?? 0,
                        })
                            .then(() => {
                                setRefreshData((refreshData) => !refreshData);
                            })
                            .catch((_error) => {
                                addToast({
                                    severity: 'error',
                                    body: DEDUCTION_SCORE_ERROR,
                                });
                            });
                    }
                };

                useEffect(() => {
                    const debounceTimer = setTimeout(() => {
                        updateCustomScoreValues();
                    }, 500);
                    return () => {
                        clearTimeout(debounceTimer);
                    };
                }, [formControlValue, isValidInput]);

                useEffect(() => {
                    if (initialLoad) {
                        setInitialLoad(false);
                    }
                    if ((formControlValue ?? 0) >= 0 && (formControlValue ?? 0) < 100) {
                        setIsValidInput(true);
                    } else {
                        setIsValidInput(false);
                    }
                }, [formControlValue]);

                const inputValueColor = isValidInput ? 'text-high-emphasis' : 'secondary-accent-red-2';

                return (
                    <div className="d-flex gap-2 align-items-center">
                        <Form.Control
                            size="sm"
                            className={`settings-page__form bg-structural-BG border-0 shadow-none text-${inputValueColor}`}
                            value={formControlValue ?? 0}
                            onChange={handleFormControlChange}
                        />
                        <Typography variant="body6" color="text-low-emphasis" style={{ marginBottom: '0%' }}>
                            {POINTS}
                        </Typography>
                        {!isValidInput && (
                            <CustomTooltip label={ERROR_SCORE_TEXT} labelColor="secondary-accent-red-2">
                                <RiErrorWarningFill className="text-secondary-accent-red-2" size={20} />
                            </CustomTooltip>
                        )}
                    </div>
                );
            },

            center: false,
        },
    ];

    return (
        <div className="d-flex flex-column gap-2">
            <ToastMessage toastSubText={t('CUSTOM_SCORE_HELP')} variant="info" />
            <Table
                cacheKey="findingSpecs"
                filter={{ 'sort[score]': 'DESC' }}
                columns={HEALTH_SCORE_CONFIG_COLUMNS}
                rowsPerPageOptions={[10, 20, 30, 40, 50]}
                transformer={(a: any) => a}
                showSubHeader={false}
                pagination={false}
                tableBodyHeight="21.5rem"
                refreshTableData={refreshData}
                currentLimit={250}
            />
        </div>
    );
};

export default HealthScoreConfigurationTable;
