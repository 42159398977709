import { createModel } from '@rematch/core';
import type { RootModel } from './models';

const featureModel = createModel<RootModel>()({
    state: {
        enableAlphaFeatures: false,
        enableBanner: false,
        enableDevFeatures: false,
    },
    reducers: {
        setIsAlphaRelease(state, payload) {
            return { ...state, enableAlphaFeatures: payload };
        },
        setBanner(state, payload) {
            return { ...state, enableBanner: payload };
        },
        setDevFeatures(state, payload) {
            return { ...state, enableDevFeatures: payload };
        },
    },
});

export default featureModel;
