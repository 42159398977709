import { ReportResultUpsertDto } from '@ampsec/platform-client';
import { Typography } from 'Components/atoms';
import { DepartmentCard } from 'Components/organisms';
import { SecurityHealthCardGroup } from 'Components/organisms';
import DepartmentScoreBars from './DepartmentScoreBars';
import './styles.scss';

export interface DepartmentContentProps {
    uniqueDepartments: ReportResultUpsertDto[];
    selectedDept: string;
    onDepartmentClick: (department: string) => void;
    isSelected: boolean;
    error: any;
    isLoading?: boolean;
    onRefresh?: React.MouseEventHandler<HTMLButtonElement>;
    isEmpty: boolean;
    onDropdownChange?: (eventKey: string | null, e: any) => void;
    onSearchChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    selectedEventKey: string | null;
}

const DepartmentContent = ({
    uniqueDepartments,
    selectedDept,
    onDepartmentClick,
    isSelected,
    error,
    isLoading,
    onRefresh,
    isEmpty,
    onDropdownChange,
    onSearchChange,
    selectedEventKey,
}: DepartmentContentProps) => {
    return (
        <div className="d-flex flex-column gap-3 department w-100 h-100">
            <DepartmentScoreBars
                uniqueDepartments={uniqueDepartments}
                selectedDept={selectedDept}
                onDepartmentClick={onDepartmentClick}
                isSelected={isSelected}
                error={error}
                isLoading={isLoading}
                onRefresh={onRefresh}
                isEmpty={isEmpty}
                onDropdownChange={onDropdownChange}
                onSearchChange={onSearchChange}
                selectedEventKey={selectedEventKey}
            />

            <div className="text-text-high-emphasis text-uppercase row w-100">
                <Typography variant="h4">{selectedDept}</Typography>
            </div>
            <div className="d-grid flex-column">
                <SecurityHealthCardGroup
                    disableDepartment={true}
                    department={selectedDept}
                    dashboardVariant="department"
                />
            </div>
            <div>
                <DepartmentCard department={selectedDept} />
            </div>
        </div>
    );
};

export default DepartmentContent;
