import axios from 'axios';
import { AmpApi, ProviderDto } from '@ampsec/platform-client';
import { store } from 'Rdx/store';
import { SIGN_IN } from 'Core-utils/routes';

const BASE_URL = process.env.PLATFORM_API_URL || '';

let api: AmpApi | null = null;

export const cacheTenantData = async () => {
    const dispatch = store.dispatch;

    const [providers, connectors] = await Promise.all([
        api?.providers.list({ limit: 100 }),
        api?.connectors.list({ limit: 100, relations: 'provider' }),
    ]);

    const providerMap = {} as Record<string, ProviderDto>;
    const pidProviderMap = {} as Record<string, ProviderDto>;
    const installedConnectorMap = {} as Record<string, any>;

    providers?.data?.forEach((datum) => {
        providerMap[datum.displayValue] = datum;
        pidProviderMap[datum.id] = datum;
    });
    dispatch.providerMap.setProviderMap(providerMap);
    dispatch.pidProviderMap.setPidProviderMap(pidProviderMap);

    connectors?.data?.forEach((datum) => {
        installedConnectorMap[datum.id] = {
            ...datum,
            provider: pidProviderMap[datum.pid!] || {},
        };
    });
    dispatch.installedConnectorMap.setInstalledConnectorMap(installedConnectorMap);
};

const instantiateApi = (token: string): AmpApi => {
    return AmpApi.instance({
        baseUrl: BASE_URL,
        token: token,
        client: axios,
        onError: (error: any) => {
            if (error?.response?.status === 401) {
                localStorage.clear();
                window.location.replace(SIGN_IN);
            } else {
                return Promise.reject(error);
            }
        },
    });
};

export const initializeApi = (token: string | null) => {
    api = instantiateApi(token ?? '');

    return api;
};

export const getApi = async (): Promise<AmpApi | null> => {
    if (!api) {
        throw new Error('Api not initialized');
    }
    if (!store.getState().auth.token) {
        return null;
    }
    return api;
};
