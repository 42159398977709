import { ProviderDto, KIND, ConnectorUpsertDto } from '@ampsec/platform-client';
import { getInstalledConnectors, getProvidersData } from 'Apis/library';
import { extractInfoFromConnectors } from 'Core-utils/helpers/helpers';
import { InstalledVendorProps } from 'Core-utils/types';
import { useContext, useMemo } from 'react';
import { useApiData } from './useApiData';
import { ContentfulContext } from 'Rdx/contexts/contentfulContext';

export const useConnectorData = () => {
    const {
        data: installedConnectors,
        isLoading: isFetchingConnectors,
        error: connectorError,
        refresh: refreshConnectors,
    } = useApiData<ConnectorUpsertDto, ConnectorUpsertDto>(
        KIND.CONNECTORS,
        () => getInstalledConnectors({ limit: 100 }),
        (a) => a,
    );
    const { contentfulData } = useContext(ContentfulContext);
    const integrations = contentfulData?.integrations ?? [];
    const providerNameIntegrationMap = contentfulData?.providerNameIntegrationMap ?? {};
    const { data: providersList } = useApiData<ProviderDto, ProviderDto>(
        KIND.PROVIDERS,
        () => getProvidersData({ limit: 100 }),
        (a) => a,
    );
    const filteredIntegrations =
        integrations &&
        Object.entries(integrations).filter((integrationData: any) => {
            return Array.isArray(providersList)
                ? providersList?.some(
                      (provider: ProviderDto) => provider.displayValue === integrationData[1]?.providerKey,
                  )
                : false;
        });
    const filteredConnectors: ConnectorUpsertDto[] = (Array.isArray(installedConnectors)
        ? installedConnectors
        : []
    ).filter((connector) =>
        Array.isArray(providersList) ? providersList.some((provider) => provider.id === connector.pid) : false,
    );
    const providers = {} as Record<string, ProviderDto>;
    (Array.isArray(providersList) ? providersList : [])?.forEach((datum: ProviderDto) => {
        providers[datum.displayValue] = datum;
    });
    const extractedData = useMemo(
        () => extractInfoFromConnectors(filteredConnectors, providers, providerNameIntegrationMap),
        [filteredConnectors],
    );
    const reconstructedIntegrations = filteredIntegrations && Object.fromEntries(filteredIntegrations);
    const domains: string[] = reconstructedIntegrations
        ? Object.values(reconstructedIntegrations).flatMap((integration: any) =>
              integration.domains.map((domain: { fields: { name: string } }) => domain.fields.name),
          )
        : [];
    const connectorsByCategoryGroup: { [category: string]: InstalledVendorProps[] } = domains.reduce(
        (prev: { [x: string]: InstalledVendorProps[] }, curr: string | number) => {
            const isMatchingConnector = (connector: any) => {
                const providerDomains = providerNameIntegrationMap?.[connector?.provider?.displayValue]?.domains || [];
                const domainNames = providerDomains.map((domain: { fields: { name: string } }) => domain.fields.name);
                return domainNames.join(' & ') === (curr as string);
            };

            const connectorsInCategory = extractedData
                .filter(isMatchingConnector)
                .sort((a, b) => a?.title?.localeCompare(b?.title));

            prev[curr] = connectorsInCategory;
            return prev;
        },
        {} as { [key: string]: InstalledVendorProps[] },
    );

    const domainsWithNoConnectors: string[] = domains.filter(
        (domain, index, self) => connectorsByCategoryGroup[domain]?.length === 0 && self.indexOf(domain) === index,
    );

    return {
        providers,
        providersList,
        reconstructedIntegrations,
        extractedData,
        filteredIntegrations,
        connectorsByCategoryGroup,
        domainsWithNoConnectors,
        isFetchingConnectors,
        connectorError,
        refreshConnectors,
    };
};
