import { Typography } from 'Components/atoms';
import { LineGraph } from 'Components/molecules';
import { useState } from 'react';
import { Form } from 'react-bootstrap';
import {
    COMPLETED_TRAINING,
    IN_THIS_QUARTER,
    OVERDUE_TRAINING,
    TRAINING_COMPLIANCE,
} from 'Core-utils/constants/constants';
import { StatisticsCard } from 'Components/atoms/StatisticsCard';
import './styles.scss';

interface TrainingComplianceProps {
    series: number[];
    overDueSeries: number[];
    categories: string[];
}

const TrainingCompliance = ({ series, categories, overDueSeries }: TrainingComplianceProps) => {
    const [value, setValue] = useState<string>('completed');
    const handleChange = () => {
        setValue(value === 'completed' ? 'overdue' : 'completed');
    };

    const renderHeaderBody = () => {
        return (
            <div className="d-flex flex-column align-items-center training gap-2">
                <div className="d-flex gap-2 align-items-center">
                    <Typography variant="body3" color="text-high-emphasis" style={{ marginBottom: '0%' }}>
                        {COMPLETED_TRAINING}
                    </Typography>
                    <Form>
                        <Form.Check type="switch" id="toggle-switch" value={value} onChange={handleChange} />
                    </Form>
                    <Typography variant="body3" color="text-high-emphasis" style={{ marginBottom: '0%' }}>
                        {OVERDUE_TRAINING}
                    </Typography>
                </div>
            </div>
        );
    };

    const renderBody = (
        <div className="d-flex align-items-center justify-content-center h-100 w-100">
            {value === 'completed' ? (
                <LineGraph series={series} categories={categories} />
            ) : (
                <LineGraph series={overDueSeries} categories={categories} showDataLabel={false} />
            )}
        </div>
    );
    return (
        <div className="h-100 w-100">
            <StatisticsCard
                header={value === 'completed' ? TRAINING_COMPLIANCE[0] : TRAINING_COMPLIANCE[1]}
                subHeader={IN_THIS_QUARTER}
                headerBody={renderHeaderBody()}
                body={renderBody}
            />
        </div>
    );
};

export default TrainingCompliance;
