import { useMemo } from 'react';
import { ExpandableContent } from '../ExpandableContent';
import { ADD_FINDINGS, FINDINGS } from 'Core-utils/constants/constants';
import { ExpandableMenuStack } from 'Components/molecules/ExpandableMenuStack';
import { Category } from '@ampsec/platform-client';
import { MenuItem } from 'Core-utils/types';
import { startCase } from 'lodash';
import './styles.scss';

export type ProviderMapType = {
    id: string;
    category: Category[];
    value: string;
    icon: string;
}[];
interface FindingsExpandableComponentProps {
    selectedOptions: {
        id: string;
        value: string;
        cid: string;
        eventType: string;
        icon: string;
    }[];
    onFindingsDropdownChange: (targetId: string, itemId: string, eventKey: string | null) => void;
    onFindingsDeleteSelectedOption: (indexToDelete: string) => void;
    onFindingsSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    findingsSearchQuery: string;
    findingsDropdownOptions: MenuItem[];
}

const FindingsExpandableComponent = ({
    selectedOptions,
    findingsDropdownOptions,
    onFindingsDropdownChange,
    onFindingsDeleteSelectedOption,
    onFindingsSearchChange,
    findingsSearchQuery,
}: FindingsExpandableComponentProps) => {
    const caption = useMemo(() => {
        const trimmedOptions: { icon: string; value: string }[] = selectedOptions.map(
            (option) =>
                option && {
                    icon: option?.icon,
                    value: startCase(option?.value),
                },
        );

        return trimmedOptions;
    }, [selectedOptions]);
    return (
        <ExpandableMenuStack
            header={FINDINGS}
            caption={caption}
            color={'vizualization-green-01'}
            defaultCaption={ADD_FINDINGS}
        >
            <ExpandableContent
                dropDownItems={findingsDropdownOptions}
                buttonLabel={ADD_FINDINGS}
                onDropdownChange={onFindingsDropdownChange}
                selectedOptions={selectedOptions}
                onSearchChange={onFindingsSearchChange}
                searchInput={findingsSearchQuery}
                onItemDelete={onFindingsDeleteSelectedOption}
                limit={10}
                hasSearchBar={true}
                buttonColor="vizualization-engage-green"
            />
        </ExpandableMenuStack>
    );
};

export default FindingsExpandableComponent;
