import { Typography } from 'Components/atoms';
import { ACTION_TABLE_HEADERS } from 'Core-utils/constants/constants';
import { Button, Image } from 'react-bootstrap';
import Delete from 'Assets/icons/delete.svg';
import { CustomTooltip } from 'Components/atoms';
import { IoMdInformationCircleOutline } from '@react-icons/all-files/io/IoMdInformationCircleOutline';
import '../styles.scss';

interface TableFormProps {
    label: string;
    headers: string[];
    data: Record<string, string>;
    handleAdd: () => void;
    handleDelete: (index: number) => () => void;
    handleChange: (index: number, field: string) => (value: React.ChangeEvent<HTMLTextAreaElement>) => void;
    renderInputField: (
        value: string,
        onChange: (newValue: React.ChangeEvent<HTMLTextAreaElement>) => void,
        isTableRow: boolean,
    ) => React.ReactNode;
    buttonLabel: string;
    tooltipLabel: string;
}

const TableForm = ({
    label,
    data,
    handleAdd,
    handleDelete,
    handleChange,
    renderInputField,
    buttonLabel,
    tooltipLabel,
}: TableFormProps) => {
    return (
        <div className="action-block__action-table d-flex flex-column gap-2 align-items-start">
            <div className="d-flex gap-1">
                <Typography variant="body3" color="text-medium-emphasis">
                    {label}
                </Typography>
                <CustomTooltip label={tooltipLabel}>
                    <IoMdInformationCircleOutline className="text-text-low-emphasis" />
                </CustomTooltip>
            </div>
            <table className="table table-striped table-bordered action-block____custom-table">
                <thead>
                    <tr>
                        <th>
                            <Typography variant="body2" color="text-high-emphasis">
                                {ACTION_TABLE_HEADERS[0]}
                            </Typography>
                        </th>
                        <th colSpan={2}>
                            <Typography variant="body2" color="text-high-emphasis">
                                {ACTION_TABLE_HEADERS[1]}
                            </Typography>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {data &&
                        Object.entries(data).map(([key, value], index) => {
                            return (
                                <tr key={index}>
                                    <td>{renderInputField(key, handleChange(index, 'key'), true)}</td>
                                    <td>{renderInputField(value, handleChange(index, 'value'), true)}</td>
                                    <td className="action-block__text-center d-flex align-items-center justify-content-center">
                                        <Image
                                            src={Delete}
                                            className="action-block__icon"
                                            onClick={handleDelete(index)}
                                            data-testid="delete-row"
                                        />
                                    </td>
                                </tr>
                            );
                        })}
                </tbody>
            </table>
            <Button className="body5 text-primary-500 text-uppercase border-0 p-0" onClick={handleAdd}>
                {buttonLabel}
            </Button>
        </div>
    );
};

export default TableForm;
