import { TableColumn } from 'react-data-table-component';
import { Avatar } from 'Components/atoms';
import { Typography } from 'Components/atoms';
import { AddUserType } from 'Core-utils/types/types';
import { NAME, EMAIL_ID, DEPARTMENT, POSITION } from 'Core-utils/constants/constants';

export const ADD_USER_GRID_COLUMNS: TableColumn<Omit<AddUserType, 'firstName' | 'lastName'>>[] = [
    {
        id: 'name',
        name: <Typography variant="body2">{NAME}</Typography>,
        sortable: false,
        wrap: true,
        cell: (row) => {
            return <Avatar variant={'name_avatar'} name={row.name} image={row?.image} />;
        },
    },
    {
        id: 'emailId',
        name: <Typography variant="body2">{EMAIL_ID}</Typography>,
        sortable: false,
        wrap: true,
        cell: (row) => {
            return <Typography variant="subtitle3">{row.emailId}</Typography>;
        },
    },
    {
        id: 'department',
        name: <Typography variant="body2">{DEPARTMENT}</Typography>,
        sortable: false,
        wrap: true,
        cell: (row) => {
            return <Typography variant="subtitle3">{row.department}</Typography>;
        },
    },
    {
        id: 'position',
        name: <Typography variant="body2">{POSITION}</Typography>,
        sortable: false,
        wrap: true,
        cell: (row) => {
            return <Typography variant="subtitle3">{row.position}</Typography>;
        },
    },
];
