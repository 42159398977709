import { createModel } from '@rematch/core';
import type { RootModel } from './models';
import { ProviderDto } from '@ampsec/platform-client';

const pidProviderMap = createModel<RootModel>()({
    state: {
        ...({} as Record<string, ProviderDto>),
    },
    reducers: {
        setPidProviderMap(_state, payload) {
            return { ...payload };
        },
    },
});

export default pidProviderMap;
