import { uniq } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

export const useSearchParamsState = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [filter, setFilter] = useState<{ [key: string]: any }>({});
    const [isUrlLoading, setIsLoading] = useState(true);

    const setSearchParamsState = useCallback((filters: { [key: string]: any }) => {
        const newSearchParams = new URLSearchParams();

        if (Object.keys(filters).length !== 0) {
            Object.keys(filters).forEach((key) => {
                if (Array.isArray(filters[key])) {
                    // eslint-disable-next-line max-nested-callbacks
                    filters[key].forEach((value: string) => {
                        newSearchParams.append(key, value);
                    });
                } else if (typeof filters[key] === 'object' && filters[key] !== null) {
                    newSearchParams.set(key, filters[key]);
                } else {
                    if (key === 'sortOrder' && filters[key] === null) {
                        newSearchParams.delete('sortColumn');
                        newSearchParams.delete('sortOrder');
                    } else if (key === 'q' && filters[key] === '') {
                        newSearchParams.delete('q');
                    } else {
                        newSearchParams.set(key, filters[key]);
                    }
                }
            });
        }
        setSearchParams(newSearchParams);
    }, []);
    function convertUndefined(value: string) {
        return value === 'undefined' ? undefined : value;
    }

    useEffect(() => {
        const filterKeys = searchParams.keys();
        const newFilter: { [key: string]: any } = {};
        for (const key of filterKeys) {
            const value = searchParams.getAll(key);

            if (value) {
                if (value.length > 1) {
                    const newValue = value
                        .map((val) => (convertUndefined(val) !== undefined ? val : undefined))
                        .filter((val) => val !== undefined);
                    newFilter[key] = uniq(newValue);
                } else {
                    if (value[0] !== 'undefined') {
                        newFilter[key] = value[0];
                    }
                }
            }
        }
        setFilter(newFilter);
        setIsLoading(false);
    }, [searchParams]);

    return { filter, isUrlLoading, setSearchParamsState };
};
