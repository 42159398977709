import Spinner from 'react-bootstrap/Spinner';
import { LOADING_MSG } from 'Core-utils/constants/constants';
import { Typography } from 'Components/atoms';
import './styles.scss';

const Loader = () => {
    return (
        <div className="loader" data-testid="loader">
            <Spinner animation="border" role="status" style={{ height: '48px', width: '48px' }} defaultValue="50%" />
            <Typography variant={'caption2'} children={LOADING_MSG} color="text-medium-emphasis" />
        </div>
    );
};

export default Loader;
