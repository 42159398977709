import { Typography } from 'Components/atoms';
import { UserLineGraph } from 'Components/molecules';
import { IN_THIS_QUARTER, TOOLING_COVERAGE_MTTR } from 'Core-utils/constants/constants';
import React from 'react';
import Google from 'Assets/icons/Google.svg';
import Okta from 'Assets/icons/Okta.svg';
import Kandji from 'Assets/icons/kandji.svg';
import { StatisticsCard } from 'Components/atoms/StatisticsCard';
import './styles.scss';

interface Series {
    data: number[];
    name: string;
}

interface ToolingMTTRAnalysisProps {
    series: Series[];
    categories: string[];
}

const colors = ['#9975FF', '#FFFFFF', '#958FA3', '#513A92'];

const labels = [
    {
        color: colors[0],
        label: 'Strong MFA',
        providers: [{ imageUrl: Google }, { imageUrl: Google }, { imageUrl: Okta }, { imageUrl: Kandji }],
    },
    { color: colors[1], label: 'Endpoint Management', providers: [{ imageUrl: Kandji }, { imageUrl: Okta }] },
    { color: colors[2], label: 'Endpoint Security', providers: [{ imageUrl: Kandji }] },
    {
        color: colors[3],
        label: 'Vulnerability Management',
        providers: [{ imageUrl: Kandji }, { imageUrl: Okta }, { imageUrl: Google }],
    },
];

const ToolingMTTRAnalysis = ({ series, categories }: ToolingMTTRAnalysisProps) => {
    const renderHeaderBody = () => {
        const firstRowLabels = labels.slice(0, 2);
        const secondRowLabels = labels.slice(2);

        return (
            <div className="d-flex flex-column align-items-center tooling-compliance gap-2">
                <div className="d-flex align-items-center gap-1">
                    {firstRowLabels.map(({ color, label, providers }, index) => (
                        <React.Fragment key={index}>
                            <div className="tooling-compliance__legend" style={{ backgroundColor: color }}></div>
                            <Typography variant="overline2" color="text-medium-emphasis" style={{ marginBottom: '0%' }}>
                                {label}
                            </Typography>
                            {providers && providers.length > 0 && (
                                <div className="d-flex align-items-center gap-1">
                                    {providers.slice(0, 3).map((provider, index) => (
                                        <img key={index} src={provider.imageUrl} alt="" />
                                    ))}
                                    {providers.length > 3 && (
                                        <Typography
                                            variant="overline2"
                                            color="text-medium-emphasis"
                                            style={{ marginBottom: '0%' }}
                                        >
                                            and {providers.length - 3} more
                                        </Typography>
                                    )}
                                </div>
                            )}
                            {index !== firstRowLabels.length - 1 && <div className="vr"></div>}
                        </React.Fragment>
                    ))}
                </div>
                <div className="d-flex align-items-center gap-1">
                    {secondRowLabels.map(({ color, label, providers }, index) => (
                        <React.Fragment key={index}>
                            <div className="tooling-compliance__legend" style={{ backgroundColor: color }}></div>
                            <Typography variant="overline2" color="text-medium-emphasis" style={{ marginBottom: '0%' }}>
                                {label}
                            </Typography>
                            {providers && providers.length > 0 && (
                                <div className="d-flex align-items-center gap-1">
                                    {providers.slice(0, 3).map((provider, index) => (
                                        <img key={index} src={provider.imageUrl} alt="" />
                                    ))}
                                    {providers.length > 3 && (
                                        <Typography
                                            variant="overline2"
                                            color="text-medium-emphasis"
                                            style={{ marginBottom: '0%' }}
                                        >
                                            and {providers.length - 3} more
                                        </Typography>
                                    )}
                                </div>
                            )}
                            {index !== secondRowLabels.length - 1 && <div className="vr"></div>}
                        </React.Fragment>
                    ))}
                </div>
            </div>
        );
    };

    const renderBody = (
        <div className="d-flex align-items-center justify-content-center h-100 w-100">
            <UserLineGraph
                series={series}
                categories={categories}
                lineColors={colors}
                showDataLabels={false}
                width={[2, 2, 2, 2]}
            />
        </div>
    );

    return (
        <div className="h-100 w-100">
            <StatisticsCard
                header={TOOLING_COVERAGE_MTTR}
                subHeader={IN_THIS_QUARTER}
                headerBody={renderHeaderBody()}
                body={renderBody}
            />
        </div>
    );
};

export default ToolingMTTRAnalysis;
