import { ToolingCoverage } from 'Components/organisms';
import { HealthMetrix } from 'Core-utils/types/types';
import { Vulnerability } from 'Components/organisms';
import { SecurityTrainingDeficiencies } from 'Components/organisms';
import { SecurityHealth } from 'Components/organisms';
import { MfaCoverageCard } from 'Components/organisms';
import { LineGraphCard } from 'Components/organisms';
import { TopRiskContributors } from 'Components/organisms';
import { EngagementCard } from 'Components/organisms';
import './style.scss';
import useTimeSpent from 'Hooks/useTimeSpent';

const COMPONENT_IDENTIFIER = 'Organisation Tab';

export interface OrganisationProps {
    vulnerabilities: HealthMetrix[];
    toolingCoverage: HealthMetrix[];
    securityTrainingDeficiencies: HealthMetrix[];
}

const Organisation = () => {
    useTimeSpent(COMPONENT_IDENTIFIER);
    return (
        <div className="org-dash d-grid gap-3 h-100 w-100 overflow-hidden">
            <div className="org-dash__row row">
                <div className="col-4">
                    <SecurityHealth />
                </div>
                <div className="col-4">
                    <LineGraphCard />
                </div>
                <div className="col-4">
                    <TopRiskContributors />
                </div>
            </div>
            <div className="org-dash__row row">
                <div className="col-8">
                    <ToolingCoverage />
                </div>
                <div className="col-4">
                    <Vulnerability />
                </div>
            </div>
            <div className="org-dash__row row">
                <div className="col-4">
                    <MfaCoverageCard />
                </div>
                <div className="col-4">
                    <SecurityTrainingDeficiencies />
                </div>
                <div className="col-4">
                    <EngagementCard />
                </div>
            </div>
        </div>
    );
};

export default Organisation;
