import React from 'react';
import { Container } from 'react-bootstrap';
import { Typography } from 'Components/atoms';
import { Color } from 'Core-utils/types/color';
import { TypographyVariant } from 'Core-utils/types/Variant';
import './styles.scss';

export interface MetricProps {
    ctxValue?: number;
    header?: React.ReactNode;
    label: string;
    labelColor: Color;
    labelVariant?: TypographyVariant;
    isTotal?: boolean;
    value?: React.ReactNode;
    valueVariant?: 'body1' | 'caption2' | 'overline';
    valueColor?: Color;
}
interface HealthMetricsProps {
    hideValue?: boolean;
    metric: MetricProps;
    lowerLabel?: string;
    headerClassName?: string;
}

const HealthMetrics = ({ metric, hideValue = false, lowerLabel, headerClassName }: HealthMetricsProps) => {
    const {
        ctxValue,
        header,
        label,
        labelColor,
        isTotal,
        labelVariant = 'h3',
        value,
        valueVariant = 'body1',
        valueColor,
    } = metric;

    const renderValue = () => {
        if (typeof value === 'string' && value) {
            return (
                <Typography variant={valueVariant} color={valueColor}>
                    <span className="text-uppercase">{value}</span>
                </Typography>
            );
        }
        return value || null;
    };

    const renderHeader = (headerClassName?: string) => {
        if (typeof header === 'string' && header) {
            return (
                <>
                    <Typography variant="body1" color="primary-100" className={`text-uppercase ${headerClassName}`}>
                        {header}
                    </Typography>
                    <Typography
                        variant="caption1"
                        color="text-high-emphasis"
                        style={{ minHeight: '1.5rem', marginBottom: 0 }}
                    >
                        {!isTotal ? `${ctxValue || 0} active` : ''}
                    </Typography>
                </>
            );
        }
        return header || null;
    };

    return (
        <div className="health-metrics" data-testid="health-metrics">
            <Container className="health-metrics__styled-container">
                {renderHeader(headerClassName)}
                <Typography variant={labelVariant} color={isTotal ? 'primary' : labelColor}>
                    {label}
                </Typography>
                {!hideValue && renderValue()}
                {lowerLabel && (
                    <Typography variant="caption1" color="text-high-emphasis">
                        {lowerLabel}
                    </Typography>
                )}
            </Container>
        </div>
    );
};

export default HealthMetrics;
