import { ReactSVG } from 'react-svg';
import { FiExternalLink } from '@react-icons/all-files/fi/FiExternalLink';
import { TabProps } from 'Core-utils/types/types';
import { SUPPORT, SUPPORT_TEXT } from 'Core-utils/constants/constants';
import { Typography } from 'Components/atoms';
import './styles.scss';

type SidebarTabProps = Omit<TabProps, 'eventKey'>;
export interface SidebarProps {
    imageUrl: string;
    signInText: string;
    navLinks: SidebarTabProps[];
}

const SidebarNavLinks = (navLinksArr: SidebarTabProps[]) => {
    return (
        <>
            {navLinksArr.map((dataItem: SidebarTabProps) => (
                <div key={dataItem.title} className="d-flex gap-1 align-items-center">
                    <a href={dataItem.link} className="sidebar__link" target="_blank">
                        <Typography variant="body1" color="primary-500">
                            {dataItem.title}
                        </Typography>
                    </a>
                    <FiExternalLink className="sidebar__ext-icon" />
                </div>
            ))}
        </>
    );
};

const IntegrationSidebar = ({ imageUrl, signInText, navLinks }: SidebarProps) => (
    <div
        className="sidebar d-flex flex-column justify-content-start pt-3 bg-structural-CardBG gap-3"
        data-testid="sidebar"
    >
        <div className="d-flex flex-column align-items-center gap-3 sidebar__icon">
            <ReactSVG src={imageUrl} className="d-flex align-items-center justify-content-center" />
            <Typography variant="subtitle4" color="text-high-emphasis">
                {signInText}
            </Typography>
            <div className="d-flex flex-column gap-2 ps-3 pe-2">
                <Typography variant="subtitle6" color="text-high-emphasis">
                    {SUPPORT}
                </Typography>
                <Typography variant="body3" color="text-high-emphasis">
                    <span className="text-text-high-emphasis">{SUPPORT_TEXT}</span>
                </Typography>
            </div>
        </div>
        <div className="d-flex gap-3 flex-column sidebar__typo-cont ps-3 pe-2">{SidebarNavLinks(navLinks)}</div>
    </div>
);

export default IntegrationSidebar;
