import { Badge, Image } from 'react-bootstrap';
import {
    chipRiskClassMap,
    chipStatusClassMap,
    severityMap,
    severityMapForResolvedIssue,
} from 'Core-utils/helpers/helpers';
import './styles.scss';
import classNames from 'classnames';

interface ChipProps {
    value: string;
    variant:
        | 'user_score'
        | 'top_risk_issue'
        | 'severity'
        | 'employee_risk_variant'
        | 'status'
        | 'resolved_issue_variant'
        | 'integrated_card_variant';
    image?: string;
    endIcon?: string;
    onClose?: (rowIndex: number, selectedId: string) => void;
    rowIndex?: number;
    selectedId?: string;
    variantValue?: string;
    isExcluded?: boolean;
}

const getVariantClassName = (variant: ChipProps['variant'], value: string) => {
    switch (variant) {
        case 'user_score': {
            const score = parseInt(value);
            return chipRiskClassMap(score);
        }
        case 'top_risk_issue':
            return 'align-items-center justify-content-center text-uppercase chip__top-risk-issue-variant overline1';
        case 'severity':
            return severityMap(value);
        case 'employee_risk_variant':
            return 'd-flex align-items-center justify-content-center text-uppercase chip__employee-risk caption4';
        case 'status':
            return chipStatusClassMap(value);
        case 'resolved_issue_variant': {
            return severityMapForResolvedIssue(value);
        }
        case 'integrated_card_variant': {
            return 'd-flex align-items-center justify-content-center text-uppercase chip__integrated-card-variant caption4';
        }
        default:
            return '';
    }
};

const Chip = ({
    value,
    image,
    variant,
    endIcon,
    onClose,
    rowIndex = -1,
    selectedId = '',
    variantValue,
    isExcluded,
}: ChipProps) => {
    const handleClose = () => {
        onClose && onClose(rowIndex, selectedId);
    };
    const variantClassName = getVariantClassName(variant, variantValue ? variantValue : value);
    const badgeClassName = classNames({
        'chip__user-cohort-variant body5 gap-1 d-flex align-items-center justify-content-center': endIcon,
        'text-uppercase chip__with-icon overline2 d-flex align-items-center justify-content-center': !endIcon,
        'text-decoration-line-through': isExcluded,
    });
    const renderChipContent = () => {
        if (endIcon || image) {
            return (
                <Badge className={badgeClassName}>
                    {image && <img src={image} className="chip__image" alt="Badge Icon" width={20} height={20} />}
                    {value}
                    {endIcon && <Image src={endIcon} onClick={handleClose} data-testid="close-chip" />}
                </Badge>
            );
        }

        return <Badge className={`chip ${variantClassName}`}>{value}</Badge>;
    };

    return <>{renderChipContent()}</>;
};

export default Chip;
