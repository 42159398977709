import { createModel } from '@rematch/core';
import type { RootModel } from './models';
import { TenantDto } from '@ampsec/platform-client';

const initialState = {
    token: null,
    user: {
        id: null,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        firstName: null,
        lastName: null,
        status: null,
        email: null,
        pictureUrl: null,
        role: null,
        tid: null,
        group: [],
        entitlement: [],
    },
    tenants: [] as TenantDto[],
    selectedTenant: {} as TenantDto,
};

export const auth = createModel<RootModel>()({
    state: initialState,
    reducers: {
        resetAuth(state) {
            return { ...state, ...initialState };
        },
        setAuthToken(state, payload) {
            return { ...state, token: payload };
        },
        setUser(state, payload) {
            return { ...state, user: payload };
        },
        setTenants(state, payload) {
            return { ...state, tenants: payload };
        },
        setSelectedTenants(state, payload) {
            return { ...state, selectedTenant: payload };
        },
    },
});

export default auth;
