/* eslint-disable max-nested-callbacks */
/* eslint-disable camelcase */
import './theme/main.scss';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import { MainTemplate } from 'Components/templates';
import { PAGE_ROUTE, SIGN_IN, TENANTS_ROUTE, NOT_FOUND_ROUTE, REDIRECT_ROUTE } from 'Core-utils/routes';
import { AuthRedirectPage, SignIn } from 'Components/pages';
import { ProtectedRoute } from 'Core-utils/ProtectedRoutes';
import { SWRConfig } from 'swr';
import { NotFoundPage } from 'Components/pages';
import { TenantSelectionPage } from 'Components/pages';
import { ContentfulContext } from 'Rdx/contexts/contentfulContext';
import { useEffect, useReducer, useState } from 'react';
import { RootState } from 'Rdx/store';
import { ToastsContext, ToastsDispatchContext } from 'Rdx/contexts/ToastsContext';
import ToastType from 'Core-utils/types/ToastType';
import { MdVerified } from '@react-icons/all-files/md/MdVerified';
import { MdOutlineError } from '@react-icons/all-files/md/MdOutlineError';
import { PiWarningFill } from '@react-icons/all-files/pi/PiWarningFill';
import { SeverityType } from 'Core-utils/types/types';
import { DEFAULT_ERROR_MSG, ERROR_VARIANT } from 'Core-utils/constants/constants';
import { Toaster } from 'Components/molecules/Toaster';
import { useSelector } from 'react-redux';

let nextToastId = 0;
type ToastReducerType =
    | {
          type: 'add';
          payload: Omit<ToastType, 'id'>;
      }
    | {
          type: 'delete';
          payload: {
              id: number;
          };
      }
    | any;

const toastsReducer = (toasts: ToastType[], action: ToastReducerType) => {
    switch (action.type) {
        case 'add': {
            return [...toasts, action.payload];
        }
        case 'delete': {
            return toasts.filter((toast) => toast.id !== action.payload.id);
        }
        default: {
            throw new Error('Unknown action type: ' + action);
        }
    }
};

const App = () => {
    const { tenants } = useSelector((state: RootState) => state.auth);
    const [contentfulData, setContentfulData] = useState({});
    const client = require('contentful').createClient({
        space: process.env.CONTENTFUL_SPACE_ID,
        accessToken: process.env.CONTENTFUL_ACCESS_TOKEN,
    });
    const [toasts, dispatch] = useReducer(toastsReducer, []);
    const hasBanner = useSelector((state: RootState) => state.featureModel.enableBanner);

    const getSnackBarBody = (toast: Pick<ToastType, 'severity' | 'body'>) => {
        if (toast.severity === ERROR_VARIANT && process.env.NODE_ENV !== 'development') {
            return DEFAULT_ERROR_MSG;
        }
        return toast.body;
    };

    const getSnackBarProperties = (severity: SeverityType) => {
        switch (severity) {
            case 'success':
                return {
                    borderColor: 'success-green',
                    icon: <MdVerified className="text-success-green" />,
                };
            case 'error':
                return {
                    borderColor: 'secondary-accent-red-4',
                    icon: <MdOutlineError className="text-secondary-accent-red-4" />,
                };
            case 'warning':
                return {
                    borderColor: 'secondary-accent-yellow-3',
                    icon: <PiWarningFill className="text-secondary-accent-yellow-3" />,
                };
            case 'info':
                return {
                    borderColor: 'primary-500',
                    icon: <MdOutlineError className="text-primary-500" />,
                };
            default:
                return {
                    borderColor: '',
                    icon: null,
                };
        }
    };
    const handleAddToast = (partialToast: Omit<ToastType, 'id' | 'borderColor' | 'icon'>) => {
        const newToastId = nextToastId++;
        const newToast = {
            ...partialToast,
            ...getSnackBarProperties(partialToast.severity),
            body: getSnackBarBody(partialToast),
            showDuration: partialToast.showDuration || 2000,
            id: newToastId,
        };
        dispatch({
            type: 'add',
            payload: newToast,
        });
        setTimeout(() => {
            dispatch({
                type: 'delete',
                payload: {
                    id: newToastId,
                },
            });
        }, partialToast.showDuration || 2000);
    };
    const handleDeleteToast = (id: number) => {
        dispatch({
            type: 'delete',
            payload: {
                id,
            },
        });
    };

    const mapEntries = (contentfulReponse: any) => {
        const entriesMap: any = {};
        contentfulReponse?.includes?.Entry?.forEach((entry: any) => {
            entriesMap[entry?.sys?.id] = entry?.fields;
        });
        return entriesMap;
    };

    const mapContentfulData = (data: string, key: string, contentfulReponse: any, entriesMap: any) => {
        const mappedData: any = {};
        contentfulReponse.items[0]?.fields[data]?.forEach((item: any) => {
            mappedData[entriesMap[item?.sys?.id][key]] = entriesMap[item?.sys?.id];
        });
        return mappedData;
    };

    useEffect(() => {
        if (!client || !(tenants?.length > 0 && tenants[0]?.id)) {
            return;
        }
        client
            .getEntries(
                { content_type: 'tenantData', 'fields.tenantId': tenants[0]?.id, include: 10 },
                { include: 'integrations' },
            )
            .then((response: any) => {
                const entriesMap = mapEntries(response);
                const findings = mapContentfulData('findings', 'kind', response, entriesMap);
                const tenantData = {
                    ...response?.items[0]?.fields,
                    findings,
                };
                setContentfulData((data: any) => ({
                    ...data,
                    ...tenantData,
                }));
            });
        client.getEntries({ content_type: 'integration' }).then((response: any) => {
            const mappedItems: Record<string, any> = {};
            const providerKeyMap: Record<string, any> = {};
            const integrationFindings: { integration: any; findingType: any }[] = [];

            response?.items.forEach((item: any) => {
                mappedItems[item?.fields?.name] = item.fields;
                providerKeyMap[item?.fields?.providerKey] = item?.fields;

                const findings = mappedItems[item?.fields?.name]?.findings;
                if (findings?.length > 0) {
                    const mappedFindings = findings.map((finding: any) => {
                        integrationFindings.push({ integration: item?.fields, findingType: finding?.fields });
                        return { ...finding?.fields };
                    });
                    mappedItems[item?.fields?.name].findings = mappedFindings;
                    providerKeyMap[item?.fields?.providerKey]['findings'] = mappedFindings;
                }
            });
            setContentfulData((data: any) => ({
                ...data,
                integrations: mappedItems,
                providerNameIntegrationMap: providerKeyMap,
                integrationFindings: integrationFindings,
            }));
        });
    }, [tenants]);

    return (
        <SWRConfig value={{ provider: () => new Map() }}>
            <BrowserRouter>
                <div className="App d-flex">
                    <Routes>
                        <Route path={SIGN_IN} element={<SignIn />} />
                        <Route path={REDIRECT_ROUTE} element={<AuthRedirectPage />} />
                        <Route
                            path={TENANTS_ROUTE}
                            element={
                                <ProtectedRoute>
                                    <TenantSelectionPage />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={PAGE_ROUTE}
                            element={
                                <ProtectedRoute>
                                    <ContentfulContext.Provider value={{ contentfulData, contentfulClient: client }}>
                                        <ToastsContext.Provider
                                            value={{ toasts, addToast: handleAddToast, deleteToast: handleDeleteToast }}
                                        >
                                            <ToastsDispatchContext.Provider value={dispatch}>
                                                <MainTemplate hasBanner={hasBanner}>
                                                    <AppRoutes />
                                                    <Toaster />
                                                </MainTemplate>
                                            </ToastsDispatchContext.Provider>
                                        </ToastsContext.Provider>
                                    </ContentfulContext.Provider>
                                </ProtectedRoute>
                            }
                        />
                        <Route path={NOT_FOUND_ROUTE} element={<NotFoundPage />} />
                    </Routes>
                </div>
            </BrowserRouter>
        </SWRConfig>
    );
};

export default App;
