import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'Rdx/store';
import { initializeApi } from 'Src/Api';
import { TenantDto } from '@ampsec/platform-client';
import { HOME_ROUTE, TENANTS_ROUTE, SIGN_IN } from 'Core-utils/routes';
import { useHash, useQuery } from 'Hooks/index';
import { incrementLoginMetric } from 'Core-utils/helpers/SentryHelpers';

export const AuthRedirectPage = () => {
    const hash = useHash();
    const query = useQuery();
    const navigate = useNavigate();
    const dispatch = useDispatch<Dispatch>();

    async function initPage() {
        const hashToken = hash.get('token');
        const queryToken = query.get('token');
        const token = hashToken || queryToken;

        if (!token) {
            navigate(SIGN_IN);
        }

        dispatch.auth.setAuthToken(token);

        const [meRes, tenantsRes] = await Promise.all([
            fetch(`${process.env.PLATFORM_API_URL}/me`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }),
            fetch(`${process.env.PLATFORM_API_URL}/api/v1/tenants`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }),
        ]);
        if (meRes.ok && tenantsRes.ok) {
            const me = await meRes.json();
            const tenantPage = await tenantsRes.json();
            const tenants = tenantPage.data;
            dispatch.auth.setUser(me);
            dispatch.auth.setTenants(
                tenants?.map((tenant: TenantDto) => ({ id: tenant.id, displayValue: tenant.displayValue })),
            );
            if (tenants) {
                if (tenants?.length === 1) {
                    const tenant = tenants[0];
                    dispatch.auth.setSelectedTenants(tenants);
                    incrementLoginMetric(tenant.displayValue, tenant.id, me.email!);
                    navigate(HOME_ROUTE);
                } else {
                    navigate(TENANTS_ROUTE);
                }
            }
            initializeApi(token);
        } else {
            localStorage.clear();
            window.location.replace(SIGN_IN);
        }
    }
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.has('error')) {
        const errorMessage = queryParams.get('error');
        dispatch.ssoModel.setError(errorMessage);
    }

    useEffect(() => {
        initPage();
    }, []);
    return <></>;
};

export default AuthRedirectPage;
