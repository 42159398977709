import { Typography } from 'Components/atoms';
import { ADD_INTEGRATION, SIDEBAR } from 'Core-utils/constants/constants';
import { Modal } from 'react-bootstrap';
import { AiOutlineClose } from '@react-icons/all-files/ai/AiOutlineClose';
import { IoArrowBackOutline } from '@react-icons/all-files/io5/IoArrowBackOutline';
import { IntegrationSidebar } from 'Components/molecules';
import CredentialsScreen, { CredentialsScreenData } from './CredentialsScreen';
import './styles.scss';

interface CredentialsScreenModalProps {
    onClose: () => void;
    imageUrl: string;
    providerName: string;
    show: boolean;
    onClick: () => void;
    data: CredentialsScreenData[];
    onBackClick: () => void;
}

const CredentialsScreenModal = ({
    onClose,
    imageUrl,
    providerName,
    onClick,
    data,
    show,
    onBackClick,
}: CredentialsScreenModalProps) => {
    return (
        <Modal
            show={show}
            backdrop="static"
            keyboard={false}
            tabIndex={-1}
            aria-hidden="true"
            className="credentials-screen-modal"
            centered
        >
            <Modal.Header>
                <Modal.Title>
                    <div className="d-flex align-items-center">
                        <IoArrowBackOutline
                            className="text-text-high-emphasis credentials-screen-modal__icon"
                            onClick={onBackClick}
                            data-testid="back-icon"
                        />
                        <Typography variant="h4" color="text-high-emphasis">
                            <span className="text-uppercase mb-0 ps-3 pt-1">{ADD_INTEGRATION}</span>
                        </Typography>
                    </div>
                </Modal.Title>
                <AiOutlineClose
                    onClick={onClose}
                    className="text-text-high-emphasis credentials-screen-modal__icon"
                    data-testid="close-icon"
                />
            </Modal.Header>

            <Modal.Body className="p-0">
                <div className="d-flex">
                    <IntegrationSidebar navLinks={SIDEBAR} imageUrl={imageUrl} signInText={providerName} />
                    <div className="d-flex flex-column align-items-center justify-content-center credentials-screen-modal__content">
                        <CredentialsScreen data={data} onClick={onClick} />
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default CredentialsScreenModal;
