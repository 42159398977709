import { Modal } from 'react-bootstrap';
import { AiOutlineClose } from '@react-icons/all-files/ai/AiOutlineClose';
import { EmptySearchResults } from 'Components/molecules';
import { IntegrationsFilter } from 'Components/molecules';
import { SearchBar } from 'Components/molecules';
import classNames from 'classnames';
import { useState, useMemo } from 'react';
import { VendorCard } from 'Components/molecules';
import { IntegratedCard } from 'Components/molecules';
import AmplifierLogo from 'Assets/icons/amplifierLogoSymbol.svg';
import { AMPLIFIER, API_CALL, CUSTOM_ACTION } from 'Core-utils/constants/constants';
import { CustomActionDto, ServiceProfile } from '@ampsec/platform-client';
import { extractDomain, getServiceInfoByKey } from 'Core-utils/helpers/helpers';
import { IntegrationFilterDataProps } from 'Components/molecules/IntegrationsFilter/IntegrationsFilter';
import { IntegrationDataProps } from 'Components/organisms/Integrations/Integrations';
import './styles.scss';

export type failedRunsType = {
    id: string;
    name: string;
    actionTarget: string;
    actionName: string;
    status: number;
    message: string;
};
export type CustomActionsModalType = Pick<
    IntegrationDataProps,
    'id' | 'imageUrl' | 'vendor' | 'tileDescription' | 'actionType'
> & {
    properties?: {
        url: string;
        payload: string;
    };
    headers?: {
        key: string;
        value: string;
    }[];
    parameters?: {
        key: string;
        value: string;
    }[];
    triggeredInLastMonth?: number;
    failedRuns?: failedRunsType[];
};

export interface CustomActionsModalProps {
    data: CustomActionDto[];
    show: boolean;
    onClose: () => void;
    onCardClick: (id: string) => void;
    variant: 'all' | 'selected';
    query: string;
    onSearchQueryChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    title: string;
    serviceProfileData: ServiceProfile[];
}

export interface ActionsIntegrationGridProps {
    data: CustomActionDto[];
    variant?: 'all' | 'selected';
    handleCardClick: (_id: string) => void;
    serviceProfileData: ServiceProfile[];
}

const IntegrationsGrid = ({ data, variant, handleCardClick, serviceProfileData }: ActionsIntegrationGridProps) => {
    const gridColClassName = classNames({
        'col-4': variant === 'all',
        'col-3': variant === 'selected',
    });
    const maxRowElements = variant === 'all' ? 3 : 4;

    const rows: JSX.Element[] = data.reduce((result: JSX.Element[]) => {
        const index = result.length * maxRowElements;

        const rowItems = data.slice(index, index + maxRowElements).map((item) => {
            const url = item?.meta?.request?.url;
            const domain = url && extractDomain(url);
            const serviceProfile = getServiceInfoByKey(serviceProfileData, domain ?? '');
            return (
                <div className={gridColClassName} key={item.id} data-testid={`card-${index}`}>
                    {item.displayValue === AMPLIFIER ? (
                        <div className="col-3 mb-3">
                            <IntegratedCard
                                vendor={API_CALL}
                                imageUrl={AmplifierLogo}
                                title={CUSTOM_ACTION.toUpperCase()}
                                handleCard={() => handleCardClick('')}
                                variant={'custom'}
                            />
                        </div>
                    ) : (
                        <VendorCard
                            imageUrl={serviceProfile?.logo ?? ''}
                            title={serviceProfile?.displayValue ?? ''}
                            subText={item.description ?? ''}
                            isIntegrated={false}
                            handleCard={() => handleCardClick(item.id)}
                            id={item.id}
                        />
                    )}
                </div>
            );
        });

        result.push(
            <div className="row me-0" key={index}>
                {rowItems}
            </div>,
        );

        return result;
    }, []);

    return <div className="integrations-grid d-grid gap-3 w-100 overflow-auto custom-scrollbar">{rows}</div>;
};

const getFiltersData = (data: any, serviceProfileData: ServiceProfile[]): IntegrationFilterDataProps[] => {
    const vendorCounts: { [vendor: string]: { count: number; image?: string } } = {};

    (Array.isArray(data) ? data : []).forEach((item: CustomActionDto) => {
        const url = item?.meta?.request?.url;
        const domain = url && extractDomain(url);
        const serviceProfile = getServiceInfoByKey(serviceProfileData, domain ?? '');
        const vendor = serviceProfile?.displayValue;
        if (vendor && vendorCounts[vendor]) {
            vendorCounts[vendor].count += 1;
        } else if (vendor && !vendorCounts[vendor]) {
            vendorCounts[vendor] = { count: 1, image: serviceProfile?.logo };
        } else {
            return;
        }
    });

    const filters: IntegrationFilterDataProps[] = [{ text: 'All', count: data.length }];
    Object.keys(vendorCounts).map((key) => {
        filters.push({
            text: key,
            count: vendorCounts[key].count,
            image: vendorCounts[key].image,
        });
    });

    return filters;
};

const CustomActionsModal = ({
    data,
    show,
    onClose,
    onCardClick,
    onSearchQueryChange,
    variant,
    query,
    title,
    serviceProfileData,
}: CustomActionsModalProps) => {
    const [filter, setFilter] = useState('All');

    const filteredData = useMemo(() => {
        const tempData =
            filter === 'All'
                ? Array.isArray(data)
                    ? [...data]
                    : []
                : data.filter((ele) => {
                      const url = ele?.meta?.request?.url;
                      if (!url) {
                          return false;
                      }

                      const domain = url && extractDomain(url);
                      const serviceProfile = getServiceInfoByKey(serviceProfileData, domain ?? '');
                      return serviceProfile?.displayValue === filter;
                  });

        if (!query) {
            return tempData;
        }

        const searchString = query.trim().toLocaleLowerCase();
        return tempData.filter((ele) => ele.displayValue.toLocaleLowerCase().includes(searchString));
    }, [query, filter, data]);

    const filters = getFiltersData(data, serviceProfileData);
    const filteredDataClassName = classNames('d-flex justify-content-center gap-3 h-100 w-100 ', {
        'align-items-center': filteredData.length === 0,
    });
    return (
        <Modal show={show} className="custom-actions-modal w-100" centered onHide={onClose}>
            <Modal.Header>
                <Modal.Title>{title}</Modal.Title>
                <AiOutlineClose onClick={onClose} className="close-button" data-testid="close-icon" />
            </Modal.Header>
            <Modal.Body className="p-3 d-flex flex-column gap-3 m-0 overflow-hidden">
                <SearchBar onChange={onSearchQueryChange} searchClassName="search-container__input-cont" />
                <div className="d-flex gap-3 overflow-hidden custom-scrollbar h-100">
                    {variant === 'all' && (
                        <IntegrationsFilter
                            activeFilter={filter}
                            integrationsFilterData={filters}
                            onClickCategory={setFilter}
                        />
                    )}
                    <div className={filteredDataClassName}>
                        {filteredData.length === 0 ? (
                            <EmptySearchResults />
                        ) : (
                            <IntegrationsGrid
                                data={filteredData}
                                variant={variant}
                                handleCardClick={onCardClick}
                                serviceProfileData={serviceProfileData}
                            />
                        )}
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default CustomActionsModal;
