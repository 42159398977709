import { ChangeEvent, useState } from 'react';
import { FormControl } from 'react-bootstrap';
import { FiEye } from '@react-icons/all-files/fi/FiEye';
import { FiEyeOff } from '@react-icons/all-files/fi/FiEyeOff';
import { Typography } from 'Components/atoms';
import classNames from 'classnames';
import './styles.scss';

interface InputFieldProps {
    className?: string;
    label?: string;
    name?: string;
    placeholder?: string;
    required?: boolean;
    variant?: 'default' | 'encrypted';
    value?: string | number;
    onValueChange?: (event: string) => void;
    id?: string;
}

const InputField = ({
    className,
    label,
    name,
    placeholder,
    required,
    onValueChange,
    value,
    id,
    variant = 'default',
}: InputFieldProps) => {
    const [showValue, setShowValue] = useState<boolean>(false);

    const toggleShowValue = () => {
        setShowValue((prevState) => !prevState);
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        onValueChange && onValueChange(event.target.value);
    };

    const Icon = showValue ? FiEye : FiEyeOff;

    return (
        <div
            className={classNames('position-relative input-field', { [className ?? 'unknown']: Boolean(className) })}
            data-testid="input-field"
        >
            <Typography variant="body3" color="text-medium-emphasis">
                {label}
            </Typography>
            {variant === 'encrypted' && (
                <Icon
                    className="input-field__icon position-absolute"
                    onClick={toggleShowValue}
                    data-testid="encrypt-icon"
                />
            )}
            <FormControl
                name={name}
                type={variant === 'encrypted' && !showValue ? 'password' : 'text'}
                value={value}
                className="input-field__input bg-transparent"
                placeholder={placeholder}
                onChange={handleChange}
                required={required}
                id={id}
                data-testid="input-field__input"
            />
        </div>
    );
};

export default InputField;
