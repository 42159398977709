import _ from 'lodash';
import { HEALTH_TRENDS_TEXT } from 'Core-utils/constants/constants';
import { CustomCard } from 'Components/molecules';
import { LineGraph } from 'Components/molecules';
import ErrorHandlerAndLoaderWrapper from 'Wrappers/ErrorHandlerandLoaderWrapper';
import { Typography } from 'Components/atoms';
import { useApiObjectData } from 'Hooks/useApiObjectData';
import { BaseMetricResults } from 'Core-utils/types/types';
import { getMetricsReportsData } from 'Apis/library';
import healthTrendIcon from 'Assets/icons/healthTrend.svg';
import './styles.scss';

const COMPONENT_IDENTIFIER = 'Organization Health Trend';

const LineGraphCard = () => {
    const { data, error, isLoading, refresh } = useApiObjectData<BaseMetricResults, BaseMetricResults>(
        'monthly-health-trend',
        getMetricsReportsData,
        (a) => a,
        { department: 'ALL' },
    );

    const seriesData = _.values(data)
        .map((item) => item.value)
        .reverse();
    return (
        <div className="line-graph h-100">
            <CustomCard
                cardType="oneThird"
                title={<Typography variant="subtitle4">{HEALTH_TRENDS_TEXT}</Typography>}
                content={
                    <ErrorHandlerAndLoaderWrapper
                        error={error}
                        isLoading={isLoading}
                        render={() => <LineGraph series={seriesData} />}
                        handleRefresh={refresh}
                        isEmpty={_.isEmpty(data)}
                        ImageSrc={healthTrendIcon}
                        component={COMPONENT_IDENTIFIER}
                    />
                }
            />
        </div>
    );
};

export default LineGraphCard;
