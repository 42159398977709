import { useContext, useMemo } from 'react';
import {
    ADD_CHANNEL,
    ADD_ENGAGEMENT_CHANNEL,
    EMAIL_ENGAGEMENT_CHANNEL,
    ENGAGEMENT_CHANNEL,
} from 'Core-utils/constants/constants';
import { ExpandableContent } from 'Components/organisms';
import { ExpandableMenuStack } from 'Components/molecules/ExpandableMenuStack';
import { Category, ConnectorUpsertDto, EMAIL_CID, KIND, ProviderDto } from '@ampsec/platform-client';
import { getInstalledConnectors, getProvidersData } from 'Apis/library';
import { useApiData } from 'Hooks/useApiData';
import { MenuItem } from 'Core-utils/types';
import _ from 'lodash';
import { ContentfulContext } from 'Rdx/contexts/contentfulContext';
import Mail from 'Assets/icons/Mail.svg';

interface EngagementChannelExpandableProps {
    selectedOptions: { id: string; icon: string; value: string }[];
    setSelectedOptions: React.Dispatch<
        React.SetStateAction<
            {
                id: string;
                icon: string;
                value: string;
            }[]
        >
    >;
    dropdownOptions: MenuItem[];
}

export const getEngagementChannelOptions = () => {
    const { contentfulData } = useContext(ContentfulContext);
    const integrations = contentfulData?.integrations;
    const { data: installedConnectors } = useApiData<ConnectorUpsertDto, ConnectorUpsertDto>(
        KIND.CONNECTORS,
        () => getInstalledConnectors({ limit: 100 }),
        (a) => a,
    );
    const { data: providersList } = useApiData<ProviderDto, ProviderDto>(
        KIND.PROVIDERS,
        () => getProvidersData({ limit: 100 }),
        (a) => a,
    );
    const providerDisplayValueToLogoMap = integrations
        ? Object.keys(integrations)?.reduce((acc: { [k: string]: string }, integration: string) => {
              acc[
                  integrations[integration].providerKey
              ] = `https:${integrations[integration]?.logo?.fields?.file?.url}`;
              return acc;
          }, {})
        : {};
    const notificationCategory = (Array.isArray(providersList) ? providersList : [])
        .filter((provider) => provider.categories.includes('NOTIFICATION' as Category))
        .map((provider) => ({
            id: provider.id,
            category: provider.categories,
            value: provider.displayValue,
            icon: providerDisplayValueToLogoMap[provider.displayValue],
        }));

    const connectorsArray = Array.isArray(installedConnectors)
        ? installedConnectors
        : ((Object.values(installedConnectors || {}) as unknown) as ConnectorUpsertDto[]);

    const oldestConnectors = [];
    const connectors = connectorsArray
        .map((connector) => {
            const matchingCategory = notificationCategory.find((category) => category.id === connector.pid);
            return matchingCategory
                ? {
                      ...connector,
                      category: matchingCategory.category,
                      displayValue: matchingCategory.value,
                      icon: matchingCategory.icon,
                  }
                : null;
        })
        .filter(Boolean)
        .sort((a, b) => new Date(a?.createdAt ?? '').getTime() - new Date(b?.createdAt ?? '').getTime());

    if (connectors.length > 0) {
        const oldestCreatedAt = new Date(connectors[0]?.createdAt ?? '').getTime();
        for (const connector of connectors) {
            if (new Date(connector?.createdAt ?? '').getTime() === oldestCreatedAt) {
                oldestConnectors.push(connector);
            } else {
                break;
            }
        }
    }

    const activeConnectorItems = connectors.map((item) => ({
        id: item?.id,
        value: item?.displayValue,
        icon: item?.icon,
    }));

    const emailDropdownItem = [
        {
            id: EMAIL_CID, //TODO : Configure CID Dynamically..
            value: EMAIL_ENGAGEMENT_CHANNEL,
            icon: Mail,
            selected: false,
        },
    ];

    const oldestConnectorId = oldestConnectors.length > 0 ? oldestConnectors[0]?.id : null;

    const dropdownItems = activeConnectorItems
        .map((item) => ({
            ...item,
            selected: item.id === oldestConnectorId,
        }))
        .concat(emailDropdownItem);

    return { dropdownItems, activeConnectorItems, oldestConnectors };
};

const EngagementChannelExpandable = ({
    selectedOptions,
    setSelectedOptions,
    dropdownOptions,
}: EngagementChannelExpandableProps) => {
    const { activeConnectorItems } = getEngagementChannelOptions();

    const handleDropdownChange = (targetID: string, itemId: string, eventKey: string | null) => {
        setSelectedOptions((prevSelectedOptions) => {
            const updatedOptions = [...prevSelectedOptions];
            const selectedItem = dropdownOptions.find((option) => option.id === targetID);
            const existingItemIndex = updatedOptions.findIndex((opt) => opt.id === itemId);
            if (existingItemIndex !== -1) {
                updatedOptions[existingItemIndex] = {
                    ...updatedOptions[existingItemIndex],
                    id: selectedItem?.id || '',
                    icon: selectedItem?.icon || '',
                    value: eventKey ?? '',
                };
            } else {
                updatedOptions.push({
                    id: selectedItem?.id || '',
                    icon: selectedItem?.icon || '',
                    value: eventKey ?? '',
                });
            }

            return updatedOptions;
        });
    };

    const caption = useMemo(() => {
        const trimmedOptions: { icon: string; value: string }[] = selectedOptions.map(
            (option) =>
                option && {
                    icon: option?.icon,
                    value: option?.value,
                },
        );

        return trimmedOptions;
    }, [selectedOptions]);

    const handleDeleteSelectedItem = (indexToDelete: string) => {
        setSelectedOptions((prevSelectedOptions) => {
            const updatedOptions = prevSelectedOptions.filter((item) => item.id !== indexToDelete);
            updatedOptions.map((option) => `${option?.icon} - ${option?.value}`).join(', ');
            return updatedOptions;
        });
    };

    const limit = activeConnectorItems.length + 1 > 5 ? 5 : activeConnectorItems.length + 1;
    return (
        <ExpandableMenuStack
            header={ENGAGEMENT_CHANNEL}
            caption={caption}
            color={'vizualization-purple'}
            defaultCaption={ADD_ENGAGEMENT_CHANNEL}
        >
            <ExpandableContent
                dropDownItems={(dropdownOptions as unknown) as MenuItem[]}
                buttonLabel={ADD_CHANNEL}
                limit={limit}
                onDropdownChange={handleDropdownChange}
                selectedOptions={selectedOptions}
                onItemDelete={handleDeleteSelectedItem}
                buttonColor="vizualization-engage-pink"
            />
        </ExpandableMenuStack>
    );
};

export default EngagementChannelExpandable;
