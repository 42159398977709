import classNames from 'classnames';
import { MdArrowRightAlt } from '@react-icons/all-files/md/MdArrowRightAlt';
import { SortComponentProps, SortType } from 'Core-utils/types/types';
import { Typography } from 'Components/atoms';
import './styles.scss';

const SortDropdown = ({ handleSort, sortDirection, sortHighLabel, sortLowLabel }: SortComponentProps) => {
    const toggleSort = (selectedSortDirection: SortType) => {
        const newSortDirection = sortDirection === selectedSortDirection ? null : selectedSortDirection;
        handleSort(newSortDirection);
    };

    const defaultClassName = 'data-grid-dropdown__dropdown-item dropdown-item d-flex align-items-center';

    const ascClassNames = classNames(defaultClassName, { active: sortDirection === 'ASC' });
    const descClassNames = classNames(defaultClassName, { active: sortDirection === 'DESC' });

    const handleAscSort = () => {
        toggleSort('ASC');
    };

    const handleDescSort = () => {
        toggleSort('DESC');
    };

    return (
        <>
            <li>
                <div className="dropdown-header">
                    <Typography variant="body5" color="text-high-emphasis">
                        Sort
                    </Typography>
                </div>
            </li>
            <li>
                <div className={ascClassNames} onClick={handleAscSort}>
                    <Typography variant="body2">
                        Sort {sortLowLabel || 'A'} <MdArrowRightAlt data-testid="right-arrow" /> {sortHighLabel || 'Z'}
                    </Typography>
                </div>
            </li>
            <li>
                <div className={descClassNames} onClick={handleDescSort}>
                    <Typography variant="body2">
                        Sort {sortHighLabel || 'Z'} <MdArrowRightAlt /> {sortLowLabel || 'A'}
                    </Typography>
                </div>
            </li>
        </>
    );
};

export default SortDropdown;
