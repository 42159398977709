import * as Sentry from '@sentry/react';

export const initializeSentry = (() => {
    let sentryInstance: typeof Sentry | null = null;

    return (): typeof Sentry => {
        if (!sentryInstance) {
            Sentry.init({
                dsn: process.env.SENTRY_DSN,
                integrations: [Sentry.replayIntegration()],
                tracesSampleRate: 1.0,
                replaysSessionSampleRate: 0.1,
                replaysOnErrorSampleRate: 1.0,
                tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
            });

            sentryInstance = Sentry;
        }

        return sentryInstance;
    };
})();
