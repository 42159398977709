import { NOT_COVERAGE_TEXT } from 'Core-utils/constants/constants';
import './styles.scss';

interface MfaCoverageProps {
    total: number;
    unsafe: number;
}

const MfaCoverage: React.FC<MfaCoverageProps> = ({ total, unsafe }) => {
    const unsafePercentage = (unsafe / total) * 100;

    return (
        <div className="mfa-coverage" data-testid="mfa-coverage">
            <div className="mfa-coverage__not-protected"></div>
            <div className="mfa-coverage__protected" style={{ width: `calc(100% - ${unsafePercentage || 0}%)` }}></div>
            <div className="mfa-coverage__content">
                <div className="mfa-coverage__number h1">{unsafe}</div>
                <div className="mfa-coverage__text body2">{NOT_COVERAGE_TEXT}</div>
            </div>
        </div>
    );
};

export default MfaCoverage;
