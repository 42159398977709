import { Navigate, RouteProps } from 'react-router-dom';
import { NOT_FOUND_ROUTE } from './routes';

interface PrivateRouteProps extends Omit<RouteProps, 'element'> {
    element: JSX.Element;
    userRole: string | null;
    allowedRoles: string[];
}

const PrivateRoute = ({ element, userRole, allowedRoles }: PrivateRouteProps) => {
    const isAuthorized = userRole && allowedRoles.includes(userRole);

    return isAuthorized ? element : <Navigate to={NOT_FOUND_ROUTE} replace />;
};
export default PrivateRoute;
