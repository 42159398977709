import React from 'react';
import { ERROR_MSG } from 'Core-utils/constants/constants';
import { ErrorState } from 'Components/molecules';
import { Loader } from 'Components/molecules';
import { EmptyState } from 'Components/molecules/EmptyState';
import { initializeSentry } from '../sentry';
import { AmpSentryTags } from 'Core-utils/types';
import { generateSentryEvent } from 'Core-utils/helpers/helpers';

type ErrorHandlerAndLoaderWrapperProps = {
    error: any;
    isLoading?: boolean;
    ImageSrc: string;
    render: () => React.ReactNode;
    handleRefresh?: React.MouseEventHandler<HTMLButtonElement>;
    noDescription?: boolean;
    description?: string;
    isEmpty: boolean;
    component?: string;
};

const ErrorHandlerAndLoaderWrapper = ({
    error,
    isLoading,
    isEmpty,
    ImageSrc,
    render,
    handleRefresh,
    noDescription = false,
    description,
    component,
}: ErrorHandlerAndLoaderWrapperProps) => {
    const sentry = initializeSentry();

    if (isLoading) {
        return <Loader />;
    }
    if (error) {
        sentry.captureException(error);
        return (
            <ErrorState primaryMessage={ERROR_MSG[0]} secondaryMessage={ERROR_MSG[1]} onRefreshClick={handleRefresh} />
        );
    }
    if (isEmpty) {
        component && sentry.captureEvent(generateSentryEvent(component, AmpSentryTags.EMPTY_RESPONSE));
        return <EmptyState ImageSrc={ImageSrc} noDescription={noDescription} description={description} />;
    }

    return <>{render()}</>;
};

export default ErrorHandlerAndLoaderWrapper;
