import { Typography } from 'Components/atoms';
import classNames from 'classnames';
import { IntegrationFilterProps } from './IntegrationsFilter';

const IntegrationList = ({ integrationsFilterData, activeFilter, onClickCategory }: IntegrationFilterProps) => {
    const handleClick = (category: string) => () => {
        onClickCategory(category);
    };
    return (
        <>
            {integrationsFilterData?.map(({ text, count, image }, index) => {
                const listItemClassName = classNames(
                    'list-group-item bg-transparent border-0 text-text-high-emphasis',
                    {
                        active: activeFilter === text,
                    },
                );

                return (
                    <li className={listItemClassName} onClick={handleClick(text)} key={`list-group-${index}`}>
                        <div className="d-flex justify-content-between">
                            <div className="d-flex gap-2 justify-content-center">
                                {image && <img src={image} alt="Image" height={'20px'} width={'20px'} />}
                                <Typography variant="body2" style={{ marginBottom: '0%' }}>
                                    {text}
                                </Typography>
                            </div>
                            <Typography variant="body2">{count}</Typography>
                        </div>
                    </li>
                );
            })}
        </>
    );
};

export default IntegrationList;
