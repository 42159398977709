import { TableColumn } from 'react-data-table-component';
import { Avatar } from 'Components/atoms';
import { Chip } from 'Components/atoms';
import { Typography } from 'Components/atoms';
import { dateCalculation } from 'Core-utils/helpers/helpers';
import { USERS_DASHBOARD_ROUTE } from 'Core-utils/routes';
import { Link } from 'react-router-dom';
import { CustomTooltip } from 'Components/atoms';

// interface TopRiskContributor {
//     createdAt?: string;
//     deletedAt?: string;
//     description?: string;
//     displayValue: string;
//     id: string;
//     type: string;
//     uid?: string;
//     updatedAt?: string;
// }

type TopRiskContributorsProps = {
    // topRiskContributors: TopRiskContributor[];
    topRiskContributors: string[];
};

export interface Employee {
    id: string;
    name: string;
    score: number;
    // topRiskContributors: TopRiskContributor[];
    topRiskContributors: string[];
    department: string;
    title: string;
    tenure: Date | undefined;
    organization: string;
    image?: string;
}

const topRiskContributorMap = (topRiskContributors: string[]) => {
    return topRiskContributors.length ? () => <TopRiskContributors topRiskContributors={topRiskContributors} /> : '-';
};

const TopRiskContributors = ({ topRiskContributors }: TopRiskContributorsProps) => (
    <div className="d-flex gap-1">
        {topRiskContributors.map((topRiskContributor) => (
            <Chip value={topRiskContributor} variant={'top_risk_issue'} key={topRiskContributor} />
        ))}
    </div>
);

export const USERS_COLUMNS: TableColumn<Employee>[] = [
    {
        name: (
            <div className="w-100 text-center">
                <Typography variant="body2">User Score</Typography>
            </div>
        ),
        center: true,
        sortable: true,
        maxWidth: '9.75rem',
        cell: (row: Employee) => <Chip variant="user_score" value={row.score.toString()} />,
        ignoreRowClick: true,
        id: 'score',
    },
    {
        id: 'firstName',
        name: <Typography variant="body2">Name</Typography>,
        sortable: true,
        style: { cursor: 'pointer' },
        cell: (row: Employee) => {
            return (
                <Link className={`text-decoration-none data-grid__link`} to={`${USERS_DASHBOARD_ROUTE}/${row.id}`}>
                    <Avatar variant={'name_avatar'} name={row.name} image={row?.image} />
                </Link>
            );
        },
    },
    {
        id: 'topRiskContributors',
        name: <Typography variant="body2">Top Risk Contributors</Typography>,
        ignoreRowClick: true,
        minWidth: '15rem',
        cell: (row: Employee) => {
            const uniqueRisks = Array.from(new Set(row.topRiskContributors));
            uniqueRisks.sort();
            // TODO fix this once the backend is complete
            const TopRisks = topRiskContributorMap(uniqueRisks);
            if (typeof TopRisks === 'string') {
                return <div className="d-flex justify-content-start gap-1 w-100">{TopRisks}</div>;
            } else {
                return <TopRisks />;
            }
        },
    },
    {
        id: 'department',
        name: <Typography variant="body2">Department</Typography>,
        sortable: true,
        ignoreRowClick: true,
        cell: (row: Employee) => (
            <CustomTooltip label={row.department ?? ''}>
                <Typography variant="body5" style={{ width: '12rem' }} className="text-truncate">
                    {row.department}
                </Typography>
            </CustomTooltip>
        ),
    },
    {
        id: 'title',
        name: <Typography variant="body2">Position</Typography>,
        sortable: true,
        ignoreRowClick: true,
        cell: (row: Employee) => <Typography variant="body5">{row.title}</Typography>,
    },
    {
        id: 'startDate',
        name: (
            <div className="w-100 text-center">
                <Typography variant="body2">Tenure</Typography>
            </div>
        ),
        sortable: true,
        center: true,
        ignoreRowClick: true,
        maxWidth: '8.75rem',
        cell: (row: Employee) => (
            <Typography variant="body5">
                {row.tenure ? dateCalculation(new Date(row.tenure), new Date(), 'short') : '-'}
            </Typography>
        ),
    },
    {
        id: 'organization',
        name: <Typography variant="body2">Organization</Typography>,
        sortable: true,
        ignoreRowClick: true,
        cell: (row: Employee) => <Typography variant="body5">{row.organization}</Typography>,
    },
];
