import { CustomCard } from 'Components/molecules';
import { REPORTS } from '@ampsec/platform-client';
import { getMetricsReportsData } from 'Apis/library';
import {
    EMPLOYEE,
    MFA,
    MFA_TOTAL_USERS_LABEL,
    MFA_TOTAL_ACTIVE_LABEL,
    NOT_COVERAGE_TEXT,
    OUT_OF_SLA,
    TOOLING,
    TOTAL_ENDPOINTS,
    TRAINING_DEFICIENCIES_LABELS,
    TRAINING_TEXT,
    TrainingDeficiencyLabelMap,
    VulnerabilitiesLabelMap,
} from 'Core-utils/constants/constants';
import { BaseMetricResults, TrendingMetricResults } from 'Core-utils/types/types';
import { useApiObjectData } from 'Hooks/useApiObjectData';
import _ from 'lodash';
import {
    employeeToolingTransformerFunction,
    endpointDepartmentToolingTransformerFunction,
    labelColorArr,
    metricLabelColor,
} from 'Core-utils/helpers/ReportsHelper';
import { transformDataToLabelValue } from 'Core-utils/helpers/helpers';
import CardContent from './CardContent';
import { Color } from 'Core-utils/types/color';
import Employee from 'Assets/icons/employee.svg';
import Tooling from 'Assets/icons/toolingCoverage.svg';
import Mfa from 'Assets/icons/mfaCoverage.svg';
import Training from 'Assets/icons/securityTraining.svg';
import OutOfSla from 'Assets/icons/vulnerability.svg';
import './styles.scss';

interface DepartmentCardProps {
    department: string;
}

const DepartmentCard = ({ department }: DepartmentCardProps) => {
    const { data: mfaData, error: mfaError, isLoading: mfaIsLoading, refresh: mfaRefresh } = useApiObjectData<
        BaseMetricResults,
        BaseMetricResults
    >(REPORTS.MFA_COVERAGE, getMetricsReportsData, (a) => a, { department: department });

    const {
        data: securityTrainingData,
        error: securityTrainingError,
        isLoading: securityTrainingIsLoading,
        refresh: securityTrainingRefresh,
    } = useApiObjectData<TrendingMetricResults, TrendingMetricResults>(
        REPORTS.TRAINING_DEFICIENCIES,
        getMetricsReportsData,
        (dataItem: TrendingMetricResults) => {
            return _.pick(dataItem, TRAINING_DEFICIENCIES_LABELS);
        },
        { department: department },
    );

    const {
        data: endpointToolingData,
        error: endpointToolingError,
        isLoading: endpointToolingIsLoading,
        refresh: endpointToolingRefresh,
    } = useApiObjectData(
        REPORTS.ENDPOINT_TOOLING_COVERAGE,
        getMetricsReportsData,
        endpointDepartmentToolingTransformerFunction,
        {
            department: department,
        },
    );

    const {
        data: vulnerabilitiesData,
        error: vulnerabilitiesError,
        isLoading: vulnerabilitiesIsLoading,
        refresh: vulnerabilitiesRefresh,
    } = useApiObjectData<BaseMetricResults, BaseMetricResults>(
        REPORTS.VULNERABILITY_SLA,
        getMetricsReportsData,
        (a) => a,
        { department: department },
    );

    const {
        data: employeeToolingData,
        error: employeeToolingDataError,
        isLoading: employeeToolingDataIsLoading,
        refresh: employeeToolingDataRefresh,
    } = useApiObjectData(REPORTS.EMPLOYEE_TOOLING_COVERAGE, getMetricsReportsData, employeeToolingTransformerFunction, {
        department: department,
    });

    const employeeToolingMetrics = {
        metric: transformDataToLabelValue(employeeToolingData),
        error: employeeToolingDataError,
        isLoading: employeeToolingDataIsLoading,
        refresh: employeeToolingDataRefresh,
        isEmpty: _.isEmpty(employeeToolingData),
        imageSrc: Employee,
    };
    const vulnerabilityMetrics = transformDataToLabelValue(vulnerabilitiesData, VulnerabilitiesLabelMap);
    const endpointToolingMetrics = transformDataToLabelValue(endpointToolingData);
    const trainingMetrics = {
        metric: transformDataToLabelValue(securityTrainingData, TrainingDeficiencyLabelMap),
        error: securityTrainingError,
        isLoading: securityTrainingIsLoading,
        refresh: securityTrainingRefresh,
        isEmpty: _.isEmpty(securityTrainingData),
        imageSrc: Training,
    };
    const mfaMetrics = {
        metric: [
            {
                label: NOT_COVERAGE_TEXT,
                value: mfaData?.[MFA_TOTAL_USERS_LABEL]?.value - mfaData?.[MFA_TOTAL_ACTIVE_LABEL]?.value ?? '-',
                valueColor: 'secondary-accent-red-2' as Color,
            },
        ],
        error: mfaError,
        isLoading: mfaIsLoading,
        refresh: mfaRefresh,
        isEmpty: _.isEmpty(mfaData),
        imageSrc: Mfa,
    };
    const endpointMetrics = {
        metric: endpointToolingMetrics.map((item) => {
            let valueColor;
            if (item.label !== TOTAL_ENDPOINTS) {
                valueColor = metricLabelColor(item.value);
            }
            return {
                label: item.label,
                value: item.value,
                valueColor: valueColor,
            };
        }),
        error: endpointToolingError,
        isLoading: endpointToolingIsLoading,
        refresh: endpointToolingRefresh,
        isEmpty: _.isEmpty(endpointToolingData),
        imageSrc: Tooling,
    };

    const outOfSlaMetrics = {
        metric: vulnerabilityMetrics.map((item, index) => {
            return {
                label: item.label,
                labelColor: labelColorArr[index],
                value: item.value,
            };
        }),
        error: vulnerabilitiesError,
        isLoading: vulnerabilitiesIsLoading,
        refresh: vulnerabilitiesRefresh,
        isEmpty: _.isEmpty(vulnerabilitiesData),
        imageSrc: OutOfSla,
    };

    const data = [
        {
            title: EMPLOYEE,
            metrics: employeeToolingMetrics,
        },
        {
            title: TOOLING,
            metrics: endpointMetrics,
        },
        {
            title: MFA,
            metrics: mfaMetrics,
        },
        {
            title: TRAINING_TEXT,
            metrics: trainingMetrics,
        },
        {
            title: OUT_OF_SLA,
            metrics: outOfSlaMetrics,
        },
    ];
    return (
        <div className="dept-card">
            <CustomCard cardType={'custom'} content={<CardContent data={data} departmentFilter={department} />} />
        </div>
    );
};
export default DepartmentCard;
