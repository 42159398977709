import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { Typography } from 'Components/atoms';
import { Color, TypographyVariant } from 'Core-utils/types';
import { Placement } from 'react-bootstrap/esm/types';
import './styles.scss';

interface TooltipProps {
    children: React.ReactNode;
    id?: string;
    label: string;
    labelColor?: Color;
    labelVariant?: TypographyVariant;
    overlayContainerClassName?: string;
    overlayPlacement?: Placement;
    tooltipClassName?: string;
}
const CustomTooltip = ({
    id = 'tooltip',
    label,
    labelColor,
    labelVariant,
    overlayContainerClassName,
    overlayPlacement,
    children,
    tooltipClassName,
}: TooltipProps) => {
    const tooltip = (
        <Tooltip
            id={id}
            className={
                tooltipClassName ?? 'custom-tooltip text-text-black d-flex justify-content-center align-items-center'
            }
        >
            <Typography
                variant={labelVariant ?? 'overline2'}
                color={labelColor ?? 'text-black'}
                className="d-flex justify-content-center align-items-center custom-tooltip__label-container"
            >
                <span className="d-flex justify-content-center align-items-center">{label}</span>
            </Typography>
        </Tooltip>
    );

    return (
        <OverlayTrigger placement={overlayPlacement ?? 'top'} overlay={tooltip}>
            <div
                className={
                    overlayContainerClassName ??
                    'custom-tooltip__content d-flex justify-content-center align-items-center'
                }
            >
                {children}
            </div>
        </OverlayTrigger>
    );
};

export default CustomTooltip;
