import { Image } from 'react-bootstrap';
import Antenna from 'Assets/gif/Antenna.gif';
import './styles.scss';

const PageLoader = () => {
    return (
        <div
            className="page-loader__vessel d-flex justify-content-center align-items-center bg-structural-BG"
            data-testid="page-loader"
        >
            <Image src={Antenna} alt="page-loader" />
        </div>
    );
};

export default PageLoader;
