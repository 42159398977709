import { Toast, ToastContainer } from 'react-bootstrap';
import { AiOutlineClose } from '@react-icons/all-files/ai/AiOutlineClose';
import { Typography } from 'Components/atoms';
import { CustomTooltip } from 'Components/atoms';
import { SELECTED } from 'Core-utils/constants/constants';
import classNames from 'classnames';
import './styles.scss';

interface ActionsProps {
    label: string;
    icon: React.ReactNode;
    onClick: () => void;
    disable?: boolean;
}

interface ActionsBarProps {
    show: boolean;
    selectedFindings: number;
    actions: ActionsProps[];
    onActionsClose: () => void;
}

const ActionsBar = ({ selectedFindings, actions, onActionsClose, show }: ActionsBarProps) => {
    return (
        <ToastContainer
            position="bottom-center"
            className="actions-bar w-100 p-0 position-absolute bottom-0"
            style={{ backdropFilter: 'blur(2px)' }}
            data-testid="actions-bar"
        >
            <Toast className={`d-inline-block m-0 w-100`} onClose={onActionsClose} show={show}>
                <Toast.Body className="p-0 w-80 h-100 d-flex justify-content-center pb-5">
                    <div className="d-flex actions-bar justify-content-between rounded align-items-center bg-structural-stroke-100">
                        <div className="d-flex justify-content-between gap-4">
                            <AiOutlineClose
                                className="text-text-high-emphasis actions-bar__icon"
                                onClick={onActionsClose}
                            />
                            <Typography variant={'subtitle3'} color="text-high-emphasis">
                                {`${selectedFindings} ${SELECTED}`}
                            </Typography>
                        </div>
                        <div className="d-flex justify-content-between gap-5">
                            {actions.map((action: ActionsProps) => {
                                const actionClassName = classNames(
                                    'd-flex justify-content-center actions-bar__action-icon align-items-center',
                                    {
                                        'actions-bar__action-icon__disabled pe-none': action.disable,
                                    },
                                );
                                return (
                                    <CustomTooltip label={action.label}>
                                        <div className={actionClassName} key={action.label} onClick={action.onClick}>
                                            {action.icon}
                                        </div>
                                    </CustomTooltip>
                                );
                            })}
                        </div>
                    </div>
                </Toast.Body>
            </Toast>
        </ToastContainer>
    );
};
export default ActionsBar;
