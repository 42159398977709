import { DropdownComponent } from 'Components/molecules';
import { MenuItem } from 'Core-utils/types/types';
import Button from 'react-bootstrap/Button';
import { RiDeleteBin6Line } from '@react-icons/all-files/ri/RiDeleteBin6Line';
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { startCase } from 'lodash';
import './styles.scss';

interface ExpandableContentProps {
    dropDownItems: MenuItem[];
    buttonLabel: string;
    limit?: number;
    onDropdownChange: (targetId: string, itemId: string, eventKey: string | null) => void;
    selectedOptions: { id: string; icon: string; value: string }[];
    onSearchChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    searchInput?: string;
    onItemDelete?: (itemId: string) => void;
    hasSearchBar?: boolean;
    buttonColor?: 'vizualization-engage-pink' | 'vizualization-engage-green';
}

const ExpandableContent = ({
    dropDownItems,
    buttonLabel,
    limit,
    onDropdownChange,
    selectedOptions,
    onSearchChange,
    searchInput,
    onItemDelete,
    hasSearchBar = false,
    buttonColor,
}: ExpandableContentProps) => {
    const filteredOptions = selectedOptions.filter((option) => option !== undefined);
    const [items, setItems] = useState<{ id: string; selectedOption: string }[]>([]);

    const handleAddItem = () => {
        const newItem = { id: Date.now().toString(), selectedOption: '' };
        setItems([...items, newItem]);
    };

    const handleDeleteItem = (itemId: string) => () => {
        if (onItemDelete) {
            onItemDelete(itemId);
        }
        const updatedItems = items.filter((item) => item.id !== itemId);
        setItems(updatedItems);
    };

    const className = classNames(`text-${buttonColor}`, {
        'expandable-content__disable': items.length === 1,
    });
    const buttonClassName = classNames(
        `body5 border-0 d-flex justify-content-start expandable-content__button text-${buttonColor}`,
        {
            'expandable-content__disable': items.length === limit,
        },
    );

    const handleChange = (item: { id: string; selectedOption: string }) => (eventKey: string | null, event: any) => {
        onDropdownChange(event.currentTarget.id, item.id, eventKey);
    };

    useEffect(() => {
        setItems(
            filteredOptions.length === 0
                ? [{ id: '1', selectedOption: '' }]
                : filteredOptions.map((item) => ({
                      id: item.id,
                      selectedOption: startCase(item.value),
                  })),
        );
    }, [selectedOptions]);

    return (
        <div className="d-flex flex-column gap-2" data-testid="expandable-content">
            <div className="d-flex flex-column gap-2 expandable-content">
                {items.map((item) => (
                    <div key={`element-${item.id}`} className="d-flex gap-2 justify-content-center align-items-center">
                        <div className={'w-100'}>
                            <DropdownComponent
                                onSearchChange={onSearchChange}
                                searchInput={searchInput}
                                value={startCase(selectedOptions.find((option) => option.id === item.id)?.value ?? '')}
                                onChange={handleChange(item)}
                                dropDownItems={dropDownItems}
                                hasSearchBar={hasSearchBar}
                                selectedItemIcon={selectedOptions.find((option) => option.id === item.id)?.icon ?? ''}
                            />
                        </div>
                        <RiDeleteBin6Line
                            onClick={handleDeleteItem(item.id)}
                            className={className}
                            data-testid="delete-button"
                            cursor={'pointer'}
                        />
                    </div>
                ))}
            </div>
            <Button variant={'outline-primary'} onClick={handleAddItem} className={buttonClassName}>
                {buttonLabel}
            </Button>
        </div>
    );
};

export default ExpandableContent;
