import React, { useCallback, useEffect, useRef, useState } from 'react';
import _, { startCase } from 'lodash';
import { Dropdown, Form, Image } from 'react-bootstrap';
import classNames from 'classnames';
import { Chip } from 'Components/atoms';
import close from 'Assets/icons/close.svg';
import { AutoCompleteItem, MenuItem } from 'Core-utils/types';
import { Typography } from 'Components/atoms';
import { AND, MORE } from 'Core-utils/constants/constants';
import './styles.scss';

interface AutoCompleteDropdownProps {
    dropDownItems: MenuItem[];
    onSelectedItemsChange?: (selectedItems: AutoCompleteItem[]) => void;
    disabledItems?: string[];
    onToggleDropdown?: any;
    rowIndex: any;
    onCloseChip?: (closedItem: string, rowIndex: any) => void;
    selectedItems: MenuItem[];
    isExpanded?: boolean;
    onChangeSelectedValues?: () => void;
    placeholder?: string;
    isSimpleAutoComplete?: boolean;
    searchQuery?: string;
    onSearchChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    isExcluded?: boolean;
}

type OnSelectedItemsChange = (selectedItems: MenuItem[]) => void;

type OnCloseChip = (closedItem: string, rowIndex: string) => void;

const AutoCompleteDropdown: React.FC<AutoCompleteDropdownProps> = ({
    dropDownItems: initialDropDownItems,
    onSelectedItemsChange,
    disabledItems = [],
    onToggleDropdown,
    rowIndex,
    onCloseChip,
    selectedItems,
    isExpanded = false,
    placeholder,
    onChangeSelectedValues,
    isSimpleAutoComplete = false,
    searchQuery = '',
    onSearchChange,
    isExcluded,
}) => {
    const [dropDownItems, setDropDownItems] = useState<MenuItem[]>(initialDropDownItems);
    const [closedItems, setClosedItems] = useState<string[]>([]);
    const [rowDropdownStates, setRowDropdownStates] = useState<boolean[]>(
        Array(initialDropDownItems.length).fill(false),
    );
    const dropdownRef = useRef<HTMLDivElement | null>(null);
    const toggleRef = useRef<HTMLButtonElement | null>(null);
    useEffect(() => {
        const handleMouseDownOutside = (event: MouseEvent) => {
            if (!dropdownRef.current || !dropdownRef.current.contains(event.target as Node)) {
                setRowDropdownStates((prevStates) => {
                    const newStates = [...prevStates];
                    newStates[rowIndex] = false;
                    return newStates;
                });
            }
        };

        document.addEventListener('mousedown', handleMouseDownOutside);

        return () => {
            document.removeEventListener('mousedown', handleMouseDownOutside);
        };
    }, [rowIndex]);

    const handleToggleDropdown = () => {
        setRowDropdownStates((prevStates) => {
            const newStates = [...prevStates];
            newStates[rowIndex] = !newStates[rowIndex];
            return newStates;
        });

        if (onToggleDropdown) {
            onToggleDropdown(rowIndex);
        }
    };

    const handleItemSelectLogic = (
        selectedItem: MenuItem,
        selectedItems: MenuItem[],
        onSelectedItemsChange: ((selectedItems: MenuItem[]) => void) | undefined,
        handleToggleDropdown: () => void,
    ) => {
        const selectedId = selectedItems.map((selectedItem) => selectedItem.id);
        if (!selectedId.includes(selectedItem.id) && onSelectedItemsChange) {
            const formattedItems = [...selectedItems, selectedItem].map((itemId) => {
                const selectedItem = dropDownItems.find((item) => item.id === itemId.id);
                return selectedItem
                    ? {
                          id: selectedItem.id,
                          kind: selectedItem.kind,
                          displayValue: selectedItem.displayValue,
                          value: selectedItem.value,
                          icon: selectedItem.icon,
                          inclusive: selectedItem.inclusive,
                      }
                    : null;
            });

            const filteredFormattedItems = formattedItems.filter(Boolean) as MenuItem[];

            onSelectedItemsChange(filteredFormattedItems);
        }
        handleToggleDropdown();
    };

    const handleItemSelect = useCallback(
        (selectedItem: MenuItem): React.MouseEventHandler<HTMLElement> => (event) => {
            event.preventDefault();
            handleItemSelectLogic(selectedItem, selectedItems, onSelectedItemsChange, handleToggleDropdown);
        },
        [selectedItems, onSelectedItemsChange, handleToggleDropdown],
    );

    const handleCloseChipLogic = (
        closedItem: string,
        rowIndex: string,
        setClosedItems: React.Dispatch<React.SetStateAction<string[]>>,
        selectedItems: MenuItem[],
        dropDownItems: MenuItem[],
        onSelectedItemsChange?: OnSelectedItemsChange,
        onCloseChip?: OnCloseChip,
    ) => {
        setClosedItems([...closedItems, closedItem]);

        const updatedItems = selectedItems.filter((item) => item.id !== closedItem);

        const updatedDropDownItems = dropDownItems.map((item) => {
            if (item.id === closedItem) {
                return { ...item, disabled: false };
            }
            return item;
        });

        setDropDownItems(updatedDropDownItems);
        if (onSelectedItemsChange) {
            const formattedItems = updatedItems.map((itemId) => {
                const selectedItem = dropDownItems.find((item) => item.id === itemId.id);
                return selectedItem
                    ? {
                          id: selectedItem.id,
                          kind: selectedItem.kind,
                          displayValue: selectedItem.displayValue,
                          value: selectedItem.value,
                          inclusive: selectedItem.inclusive,
                          icon: selectedItem.icon,
                          disabled: false,
                      }
                    : null;
            });

            const filteredFormattedItems = formattedItems.filter(Boolean) as MenuItem[];

            onSelectedItemsChange(filteredFormattedItems);
        }

        if (onCloseChip) {
            onCloseChip(closedItem, rowIndex);
        }
    };

    const handleCloseChip = useCallback(
        (closedItem: string, rowIndex: string) => {
            handleCloseChipLogic(
                closedItem,
                rowIndex,
                setClosedItems,
                selectedItems,
                dropDownItems,
                onSelectedItemsChange,
                onCloseChip,
            );
        },
        [setClosedItems, selectedItems, dropDownItems, onSelectedItemsChange, onCloseChip],
    );

    const handleChipClose = (rowIndex: string, selectedItemId: string) => {
        handleCloseChip(selectedItemId, rowIndex);
    };
    useEffect(() => {
        setDropDownItems(initialDropDownItems);
    }, [initialDropDownItems]);

    const renderDropdownMenuItems = () => {
        const enabledItems = dropDownItems.filter((item) => !item.disabled && !disabledItems.includes(item.id));
        const disabledItemsSorted = dropDownItems.filter((item) => disabledItems.includes(item.id));

        const sortedItems = [...enabledItems, ...disabledItemsSorted];

        return sortedItems.map((item: MenuItem) => (
            <Dropdown.Item
                key={item.id}
                eventKey={item.value}
                className="auto-complete__menu-item d-flex align-items-center gap-2"
                disabled={item.disabled || disabledItems.includes(item.id)}
                onClick={handleItemSelect(item)}
                data-testid="dropdown-item"
            >
                <Image src={item.icon} className={classNames({ opacity: item.disabled })} width={18} height={18} />
                <span>{startCase(item.displayValue)}</span>
            </Dropdown.Item>
        ));
    };

    const renderExpandedState = () =>
        selectedItems.map((selectedItem: MenuItem) => {
            return (
                <div key={selectedItem.id} className="auto-complete__chip">
                    <Chip
                        value={startCase(selectedItem?.displayValue || '')}
                        variant="user_score"
                        image={selectedItem?.icon || ''}
                        onClose={() => handleChipClose(selectedItem.id, selectedItem.id ?? '')}
                        endIcon={close}
                        rowIndex={rowIndex}
                        selectedId={selectedItem.id}
                        isExcluded={isExcluded}
                    />
                </div>
            );
        });

    const renderCollapsedState = () => (
        <div
            className="auto-complete__expanded-container d-flex align-items-center gap-3"
            onClick={onChangeSelectedValues}
        >
            {selectedItems.slice(0, 4).map((selectedItem) => (
                <div key={selectedItem.id} className="d-flex gap-1 justify-content-center align-items-center mt-2">
                    {selectedItem?.icon && <Image src={selectedItem.icon} width={15} height={17} />}
                    <Typography variant="body5">{startCase(selectedItem.displayValue)}</Typography>
                </div>
            ))}
            <div>
                {selectedItems.length === 0 && (
                    <Typography variant="body4" color="text-low-emphasis">
                        {placeholder}
                    </Typography>
                )}
                {selectedItems.length > 4 && (
                    <div className="d-flex gap-1 justify-content-center align-items-center mt-2">
                        <Typography variant="body5">{`${AND} ${selectedItems.length - 4} ${MORE}`}</Typography>
                    </div>
                )}
            </div>
        </div>
    );

    const renderForm = () => {
        return (
            <Form.Control
                type="text"
                className="bg-transparent border-0 shadow-none text-text-high-emphasis"
                onClick={handleToggleDropdown}
                onChange={onSearchChange}
                value={searchQuery}
            />
        );
    };
    const scrollToBottom = () => {
        if (toggleRef.current) {
            toggleRef.current.scrollTop = toggleRef.current.scrollHeight;
        }
    };

    useEffect(() => {
        scrollToBottom();
    }, [selectedItems]);

    useEffect(() => {
        setRowDropdownStates((prevStates) => {
            const newStates = [...prevStates];
            newStates[rowIndex] = searchQuery !== '';
            return newStates;
        });
    }, [searchQuery]);

    const dropdownToggleClass = classNames('text-text-high-emphasis', {
        ' auto-complete__toggle custom-scrollbar': isSimpleAutoComplete,
    });

    const isDropdownExpanded =
        (isSimpleAutoComplete || (!isSimpleAutoComplete && isExpanded)) && rowDropdownStates[rowIndex];
    return (
        <Dropdown
            ref={dropdownRef}
            className="auto-complete h-100"
            autoClose={false}
            data-testid="input-field"
            show={isDropdownExpanded}
        >
            <Dropdown.Toggle ref={toggleRef} className={dropdownToggleClass}>
                <div className="input-group">
                    <div className="auto-complete__chips-container">
                        {isSimpleAutoComplete && renderExpandedState()}
                        {!isSimpleAutoComplete && (isExpanded ? renderExpandedState() : renderCollapsedState())}
                    </div>
                    {(isSimpleAutoComplete || (!isSimpleAutoComplete && isExpanded)) && renderForm()}
                </div>
            </Dropdown.Toggle>
            <Dropdown.Menu className="p-0 w-100 bg-body-color border-text-medium-emphasis custom-scrollbar">
                {renderDropdownMenuItems()}
            </Dropdown.Menu>
        </Dropdown>
    );
};
export default AutoCompleteDropdown;
