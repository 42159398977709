import { Dropdown, Image } from 'react-bootstrap';
import { RiArrowDownSLine } from '@react-icons/all-files/ri/RiArrowDownSLine';
import { Typography } from 'Components/atoms';
import classNames from 'classnames';
import { MenuItem } from 'Core-utils/types/types';
import { SearchBar } from 'Components/molecules';
import { startCase } from 'lodash';
import './styles.scss';

interface DropdownComponentProps {
    value: string;
    dropDownItems: MenuItem[];
    variant?: 'filled' | 'default';
    onChange?: (eventKey: string | null, e: any) => void;
    hasSearchBar?: boolean;
    onSearchChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    searchInput?: string;
    selectedItemIcon?: string;
    placeholder?: string;
    disableToggle?: boolean;
    maxWidth?: string;
    isStartCase?: boolean;
}

const DropdownComponent = ({
    value,
    dropDownItems,
    variant = 'filled',
    onChange,
    hasSearchBar = false,
    onSearchChange,
    searchInput,
    selectedItemIcon,
    placeholder,
    disableToggle = false,
    maxWidth,
    isStartCase = true,
}: DropdownComponentProps) => {
    const isDefault = variant === 'default';
    const typographyVariant = isDefault ? 'body1' : 'body10';
    const itemTypographyVariant = isDefault ? 'body2' : 'caption1';
    const arrowIconColor = isDefault ? 'text-black' : 'text-high-emphasis';

    const sortedDropDownItems = dropDownItems.slice().sort((a, b) => {
        return a.disabled === b.disabled ? 0 : a.disabled ? 1 : -1;
    });

    return (
        <div className={classNames('dropdown  h-100', 'w-100', variant)} data-testid="dropdown">
            <Dropdown onSelect={onChange} className="h-100">
                <Dropdown.Toggle
                    className="d-flex flex-row justify-content-between align-items-center w-100 h-100"
                    disabled={disableToggle}
                >
                    <div className="d-flex flex-row gap-2">
                        {selectedItemIcon && <Image src={selectedItemIcon} width={14} height={17} />}
                        <Typography
                            variant={typographyVariant}
                            color={arrowIconColor}
                            style={{ overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: maxWidth }}
                        >
                            {value ? (
                                value
                            ) : (
                                <span>
                                    <Typography variant="body4" color="text-low-emphasis">
                                        {placeholder}
                                    </Typography>
                                </span>
                            )}
                        </Typography>
                    </div>
                    <RiArrowDownSLine className={classNames('dropdown__dropdown-icon', arrowIconColor)} />
                </Dropdown.Toggle>

                <Dropdown.Menu className="p-0 w-100 gap-2 bg-body-color border-text-medium-emphasis  dropdown__custom-menu">
                    {hasSearchBar && (
                        <div className="p-2 w-72 d-flex justify-content-start">
                            <SearchBar
                                onChange={onSearchChange}
                                value={searchInput}
                                searchClassName="search-container__input-cont"
                            />
                        </div>
                    )}
                    <div className="custom-scrollbar dropdown__scrollable-cont">
                        {sortedDropDownItems.map((item: MenuItem) => {
                            const imgClassName = classNames({ opacity: item?.disabled });
                            const itemClassNames = classNames(
                                'dropdown__menu-item',
                                'd-flex',
                                'align-items-center',
                                'gap-2',
                                {
                                    'dropdown__link-text': item?.link,
                                },
                            );

                            const displayItemValue = isStartCase
                                ? startCase(item?.value ?? item?.displayValue)
                                : item?.value ?? item?.displayValue;

                            return (
                                <Dropdown.Item
                                    key={item?.id}
                                    eventKey={item?.value}
                                    className={itemClassNames}
                                    disabled={item?.disabled}
                                    href={item?.link}
                                    id={item?.id}
                                >
                                    {item?.icon && typeof item?.icon === 'string' ? (
                                        <Image
                                            src={item?.icon}
                                            className={imgClassName}
                                            width={14}
                                            height={17}
                                            alt="icon"
                                        />
                                    ) : (
                                        item?.icon
                                    )}
                                    <Typography variant={itemTypographyVariant} style={{ textTransform: 'uppercase' }}>
                                        {displayItemValue}
                                    </Typography>
                                </Dropdown.Item>
                            );
                        })}
                    </div>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
};

export default DropdownComponent;
