import { StatisticsCard } from 'Components/atoms/StatisticsCard';
import { LineGraph } from 'Components/molecules';
import { IN_THIS_QUARTER, TRAINING_MTTR_ANALYSIS } from 'Core-utils/constants/constants';

interface TrainingMTTRAnalysisProps {
    series: number[];
    categories: string[];
}

const TrainingMTTRAnalysis = ({ series, categories }: TrainingMTTRAnalysisProps) => {
    const renderBody = (
        <div className="d-flex align-items-center justify-content-center h-100 w-100">
            <LineGraph series={series} categories={categories} showDataLabel={false} />
        </div>
    );
    return (
        <div className="h-100 w-100">
            <StatisticsCard header={TRAINING_MTTR_ANALYSIS} subHeader={IN_THIS_QUARTER} body={renderBody} />
        </div>
    );
};

export default TrainingMTTRAnalysis;
