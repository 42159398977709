import { ReactSVG } from 'react-svg';
import { Typography } from 'Components/atoms';
import EmptyTableIcon from 'Assets/icons/EmptyTableIcon.svg';
import { EMPTY_TABLE_MESSAGE } from 'Core-utils/constants/constants';
import { Loader } from 'Components/molecules';

const TableSubStates = ({ variant = 'loader' }: { error?: string; variant: 'empty' | 'loader' }) => {
    const EmptyTableComponent = (
        <>
            <ReactSVG src={EmptyTableIcon} className="empty-table__icon" />
            <Typography variant="body5">{EMPTY_TABLE_MESSAGE}</Typography>
        </>
    );

    const children = variant === 'loader' ? <Loader /> : EmptyTableComponent;

    return (
        <div className="d-flex flex-column gap-4 text-center text-uppercase align-items-center justify-content-center bg-structural-CardBG text-text-medium-emphasis sub-table">
            {children}
        </div>
    );
};

export default TableSubStates;
