import { Card } from 'react-bootstrap';
import { Typography } from 'Components/atoms';
import { Chip } from 'Components/atoms';
import { INTEGRATED } from 'Core-utils/constants/constants';
import classnames from 'classnames';
import './styles.scss';

interface VendorCardProps {
    id: string;
    imageUrl: string;
    title: string;
    subTitle?: string;
    subText: string;
    isIntegrated: boolean;
    handleCard: (_id: string) => void;
}

const VendorCard = ({ id, imageUrl, title, subTitle, subText, isIntegrated = false, handleCard }: VendorCardProps) => {
    const cardClassName = classnames('vendor-card bg-text-black d-flex', {
        disabled: isIntegrated,
    });

    return (
        <div className="vendor-card" data-testid="vendor-card">
            {isIntegrated && <Chip value={INTEGRATED} variant="integrated_card_variant" />}
            <Card className={cardClassName} onClick={() => handleCard(id)} data-testid={`${title}`}>
                <div className="vendor-card__image-text d-flex flex-column align-items-center">
                    {imageUrl && <img src={imageUrl} alt={`${title}-logo`} className="vendor-card__icon" />}
                    <Typography variant="subtitle5" color="text-high-emphasis">
                        {title}
                    </Typography>
                    <Typography variant="body7" color="text-medium-emphasis">
                        {subTitle}
                    </Typography>
                    <div className="divider bg-structural-selected" />
                </div>
                <div className="text-center">
                    <Typography variant="body2" color="text-medium-emphasis">
                        {subText}
                    </Typography>
                </div>
            </Card>
        </div>
    );
};

export default VendorCard;
