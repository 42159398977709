import { useState } from 'react';
import { Toast } from 'react-bootstrap';
import classnames from 'classnames';
import { AiOutlineClose } from '@react-icons/all-files/ai/AiOutlineClose';
import { BsInfoCircle } from '@react-icons/all-files/bs/BsInfoCircle';
import { Typography } from 'Components/atoms';
import { NOTIFICATIONS_DISABLED, NOTIFICATIONS_DISABLED_CAPTION } from 'Core-utils/constants/constants';
import { FiAlertCircle } from '@react-icons/all-files/fi/FiAlertCircle';
import { Link } from 'react-router-dom';
import { SETTINGS_SPECIFIC_ROUTE } from 'Core-utils/routes';
import './styles.scss';

interface ToastMessageProps {
    variant?: 'default' | 'error' | 'info';
    toastText?: string;
    toastSubText?: string;
}

const ToastMessage = ({ variant = 'default', toastText, toastSubText }: ToastMessageProps) => {
    const [showToast, setShowToast] = useState(true);

    const handleCloseToast = () => {
        setShowToast(false);
    };
    const borderColor = variant === 'info' ? 'primary-500' : 'structural-color-chip-BG';
    const subTextColor = variant === 'info' ? 'text-medium-emphasis' : 'text-high-emphasis';
    const bgColor = variant === 'info' ? 'vizualization-purple-03' : 'stroke-400';
    const toastBodyClasses = classnames(
        `toast-message__toast-body bg-${bgColor} border border-${borderColor} rounded-2 overflow-x-hidden`,
        {
            'custom-class': showToast,
        },
    );

    return (
        <div className="toast-message" data-testid="toast-message">
            {variant === 'default' || variant === 'info' ? (
                <Toast className="w-100" show={showToast} onClose={handleCloseToast}>
                    <Toast.Body className={toastBodyClasses}>
                        <div className="d-flex flex-row justify-content-between align-items-center">
                            <div className="d-flex flex-row">
                                <BsInfoCircle />
                                <div className="d-flex flex-column gap-2 justify-content-center">
                                    {toastText && (
                                        <Typography variant="body1" color="text-high-emphasis">
                                            {toastText}
                                        </Typography>
                                    )}
                                    {toastSubText && (
                                        <Typography variant="caption1" color={subTextColor} className="mb-0">
                                            {toastSubText}
                                        </Typography>
                                    )}
                                </div>
                            </div>
                            {variant === 'default' && <AiOutlineClose onClick={handleCloseToast} />}
                        </div>
                    </Toast.Body>
                </Toast>
            ) : (
                <Toast
                    className="w-100 toast-message__error border border-secondary-accent-red-4 rounded-2"
                    show={true}
                >
                    <Toast.Body>
                        <div className="d-flex flex-row justify-content-between align-items-center">
                            <div className="d-flex flex-row">
                                <FiAlertCircle className="text-secondary-accent-red-4" />
                                <div className="d-flex flex-column">
                                    <Typography variant="body1" color="text-high-emphasis">
                                        {NOTIFICATIONS_DISABLED}
                                    </Typography>
                                    <div className="d-flex gap-1">
                                        <Typography variant="caption1" color="text-medium-emphasis">
                                            {NOTIFICATIONS_DISABLED_CAPTION[0]}
                                        </Typography>
                                        <Link to={SETTINGS_SPECIFIC_ROUTE} className="text-decoration-none">
                                            <Typography variant="caption1" color="primary-500">
                                                {NOTIFICATIONS_DISABLED_CAPTION[1]}
                                            </Typography>
                                        </Link>
                                        <Typography variant="caption1" color="text-medium-emphasis">
                                            {NOTIFICATIONS_DISABLED_CAPTION[2]}
                                        </Typography>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Toast.Body>
                </Toast>
            )}
        </div>
    );
};

export default ToastMessage;
