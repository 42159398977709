import { createModel } from '@rematch/core';
import { MenuItem } from 'Core-utils/types';
import type { RootModel } from './models';

export type FindingSpecsWithProviderName = MenuItem & { providerDisplayValue: string };

interface FindingSpecsModelState {
    findingSpecs: FindingSpecsWithProviderName[];
}

const initialState: FindingSpecsModelState = {
    findingSpecs: [],
};

const findingSpecsModel = createModel<RootModel>()({
    state: initialState,
    reducers: {
        setFindings(state, payload: FindingSpecsWithProviderName[]) {
            return { ...state, findingSpecs: payload };
        },
    },
});

export default findingSpecsModel;
