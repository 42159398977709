import { Color } from 'Core-utils/types/color';
import { TOTAL_EMPLOYEE_LABEL, TOTAL_ENDPOINT_LABEL } from 'Core-utils/constants/constants';
import { DescriptiveMetric, DescriptiveMetricResults } from 'Core-utils/types/types';

export const employeeToolingTransformerFunction = (dataItem: DescriptiveMetricResults) => {
    const filteredItems = Object.values(dataItem).filter(
        (item) =>
            item.label !== TOTAL_EMPLOYEE_LABEL && item.label !== 'report_tooling_employees_with_no_account_label',
    );

    if (filteredItems.length === 0) {
        return { [TOTAL_EMPLOYEE_LABEL]: dataItem[TOTAL_EMPLOYEE_LABEL] };
    }

    const maxObj = filteredItems.reduce((maxObj, obj) => {
        if (obj.value > maxObj.value) {
            return obj;
        }
        return maxObj;
    });

    return {
        [TOTAL_EMPLOYEE_LABEL]: dataItem[TOTAL_EMPLOYEE_LABEL],
        [maxObj.label]: maxObj,
    };
};

export const endpointOrganizationToolingTransformerFunction = (dataItem: DescriptiveMetricResults) => {
    const sortedValues = Object.values(dataItem)
        .filter((item) => item.label !== TOTAL_ENDPOINT_LABEL)
        .sort((a, b) => b.value - a.value);

    const topFourValues = sortedValues.slice(0, 4);

    const resultObject: Record<string, DescriptiveMetric> = {
        [TOTAL_ENDPOINT_LABEL]: dataItem[TOTAL_ENDPOINT_LABEL],
    };

    topFourValues.forEach((valueObj, index) => {
        if (valueObj) {
            resultObject[`${valueObj.label}_${index + 1}`] = valueObj;
        }
    });

    return resultObject;
};

export const endpointDepartmentToolingTransformerFunction = (dataItem: DescriptiveMetricResults) => {
    const sortedValues = Object.values(dataItem)
        .filter((item) => item.label !== TOTAL_ENDPOINT_LABEL)
        .sort((a, b) => b.value - a.value);

    const topThreeValues = sortedValues.slice(0, 3);

    const resultObject: Record<string, DescriptiveMetric> = {
        [TOTAL_ENDPOINT_LABEL]: dataItem[TOTAL_ENDPOINT_LABEL],
    };

    topThreeValues.forEach((valueObj, index) => {
        if (valueObj) {
            resultObject[`${valueObj.label}_${index + 1}`] = valueObj;
        }
    });

    return resultObject;
};

export const metricLabelColor = (metricValue: number): Color => {
    switch (metricValue) {
        case 0:
            return 'success-green';
        case 1:
            return 'secondary-accent-yellow-1';
        case 2:
            return 'secondary-accent-orange-2';
        default:
            return 'secondary-accent-red-2';
    }
};

export const labelColorArr: Color[] = [
    'secondary-accent-red-2',
    'secondary-accent-orange-2',
    'secondary-accent-yellow-1',
    'success-green',
];
