import { Form } from 'react-bootstrap';
import { Typography } from 'Components/atoms';
import classNames from 'classnames';
import './styles.scss';

interface ToggleTextPanelProps {
    header: string;
    subText?: string;
    onToggle: (_event: React.ChangeEvent<HTMLInputElement>) => void;
    checked: boolean;
    disabled?: boolean;
}

const ToggleTextPanel = ({ header, subText, onToggle, checked, disabled }: ToggleTextPanelProps) => {
    const toggleSwitchClassName = classNames('toggle-switch', {
        'pe-none opacity-50': disabled,
    });

    return (
        <div className={toggleSwitchClassName}>
            <div className="d-flex justify-content-between">
                <Typography variant="body2" color="text-high-emphasis">
                    {header}
                </Typography>
                <Form.Check type="switch" checked={checked} onChange={onToggle} disabled={disabled} />
            </div>
            <Typography variant="body3" color="text-medium-emphasis">
                {subText}
            </Typography>
        </div>
    );
};

export default ToggleTextPanel;
