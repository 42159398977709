import type { ToastType, ToastDispatchType } from 'Core-utils/types/ToastType';
import { createContext, Dispatch } from 'react';

export type ToastsContextType = {
    toasts: ToastType[];
    addToast: (toast: Omit<ToastType, 'id'>) => void;
    deleteToast: (id: number) => void;
};

export const ToastsContext = createContext<ToastsContextType>({
    toasts: [] as ToastType[],
    addToast: () => {},
    deleteToast: () => {},
});
export const ToastsDispatchContext = createContext<Dispatch<ToastDispatchType>>({} as Dispatch<ToastDispatchType>);
