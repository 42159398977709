import { Button } from 'react-bootstrap';
import { FilterActionButtonsProps } from 'Core-utils/types/types';
import { Typography } from 'Components/atoms';
import { APPLY_TEXT, CLEAR_TEXT } from 'Core-utils/constants/constants';

const FilterActionButtons = ({
    onClearClick,
    onApplyClick,
    clearDisabled = true,
    applyDisabled = true,
}: FilterActionButtonsProps) => {
    return (
        <div className="d-flex justify-content-between m-3">
            <Button className="border-0" onClick={onClearClick} disabled={clearDisabled}>
                <Typography variant="body4" color="primary-500">
                    {CLEAR_TEXT}
                </Typography>
            </Button>
            <Button className="bg-primary-500 text-uppercase border-0" onClick={onApplyClick} disabled={applyDisabled}>
                <Typography variant="body4" color="text-black">
                    {APPLY_TEXT}
                </Typography>
            </Button>
        </div>
    );
};

export default FilterActionButtons;
