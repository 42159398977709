import { ConnectorUpsertDto, KIND, ProviderDto } from '@ampsec/platform-client';
import { getInstalledConnectors, getProvidersData } from 'Apis/library';
import { ContentfulContext } from 'Rdx/contexts/contentfulContext';
import { useContext, useMemo } from 'react';
import { useApiData } from './useApiData';

export const useProviderData = () => {
    const { contentfulData } = useContext(ContentfulContext);
    const integrations = contentfulData?.integrations;

    const providerValueToLogoMap = integrations
        ? Object.keys(integrations)?.reduce((acc: { [k: string]: string }, integration: string) => {
              acc[
                  integrations[integration].providerKey
              ] = `https:${integrations[integration]?.logo?.fields?.file?.url}`;
              return acc;
          }, {})
        : {};

    const { data: installedConnectors } = useApiData<ConnectorUpsertDto, ConnectorUpsertDto>(
        KIND.CONNECTORS,
        () => getInstalledConnectors({ limit: 100 }),
        (a) => a,
    );
    const { data: providersList } = useApiData<ProviderDto, ProviderDto>(
        KIND.PROVIDERS,
        () => getProvidersData({ limit: 100 }),
        (a) => a,
    );
    const connectorsArray = useMemo(
        () =>
            Array.isArray(installedConnectors)
                ? installedConnectors
                : ((Object.values(installedConnectors || {}) as unknown) as ConnectorUpsertDto[]),
        [installedConnectors],
    );
    const providerMap = useMemo(
        () =>
            (Array.isArray(providersList) ? providersList : []).map((provider) => ({
                id: provider.id,
                category: provider.categories,
                value: provider.displayValue,
                icon: providerValueToLogoMap[provider.displayValue],
            })),
        [providersList],
    );
    const connectorProviderMap = useMemo(() => {
        const getConnectorInfo = (connector: ConnectorUpsertDto) => {
            const provider = providerMap.find((dataItem) => dataItem.id === connector.pid);
            return {
                cid: connector.id,
                providerDisplayValue: provider?.value,
                providerIcon: provider?.icon,
            };
        };

        return (Array.isArray(connectorsArray) ? connectorsArray : []).map(getConnectorInfo);
    }, [providerMap, connectorsArray]);
    return { connectorProviderMap };
};
