import './styles.scss';
import { FlowSpecStatusKind, FlowSpecUpsertDto } from '@ampsec/platform-client';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { TfiClose } from '@react-icons/all-files/tfi/TfiClose';
import {
    CANCEL,
    CREATE,
    CREATE_ENGAGEMENT,
    ENGAGEMENT_DESCRIPTION,
    ENGAGEMENT_TITLE,
    ENGAGEMENT_TITLE_VALIDATION_TEXT,
} from 'Core-utils/constants/constants';
import { DEFAULT_WORKFLOW_ROUTE } from 'Core-utils/routes';
import { createFlow } from 'Apis/library';
import { useSelector } from 'react-redux';
import { RootState } from 'Rdx/store';
import _ from 'lodash';

const DESCRIPTION_CHAR_LIMIT = 500;

type CreateEngagementModalProps = {
    show: boolean;
    onClose: () => void;
    flowSpec?: FlowSpecUpsertDto;
};

const CreateEngagementModal = ({ show, onClose, flowSpec: defaultFlowSpec }: CreateEngagementModalProps) => {
    const navigate = useNavigate();
    const { selectedTenant } = useSelector((state: RootState) => state.auth);

    const flowSpecUpdateData = _.omit(defaultFlowSpec, ['id', 'activity', 'status']);
    const newFlowSpec: FlowSpecUpsertDto = {
        description: '',
        engagementChannelCids: [],
        filter: { cohorts: [] },
        fslStrategy: 'STANDARD',
        name: '',
        status: FlowSpecStatusKind.PAUSED,
        tid: selectedTenant.id,
        triggerFilter: { trigger: [] },
        ...flowSpecUpdateData,
    };

    const [validated, setValidated] = useState(false);
    const [flowSpec, setFlowSpec] = useState<FlowSpecUpsertDto>({ ...newFlowSpec });

    const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFlowSpec((flowSpec: FlowSpecUpsertDto) => ({ ...flowSpec, name: event.target.value }));
    };
    const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFlowSpec((flowSpec: FlowSpecUpsertDto) => ({ ...flowSpec, description: event.target.value }));
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
            return;
        }

        setValidated(false);
        createFlow(flowSpec).then((response) =>
            navigate(`${DEFAULT_WORKFLOW_ROUTE}/create-engagement/${response?.id}`),
        );

        setFlowSpec(newFlowSpec);
        onClose();
    };

    const onCancelHandler = () => {
        setValidated(false);
        setFlowSpec(newFlowSpec);
        onClose();
    };

    return (
        <Modal show={show} onHide={onCancelHandler} centered size="lg" className="create-engagement-modal">
            <Modal.Header className="create-engagement-modal__header bg-body-color text-text-high-emphasis border border-structural-stroke-100 rounded-top">
                <Modal.Title className="body11 p-1">{CREATE_ENGAGEMENT}</Modal.Title>
                <TfiClose
                    className="text-text-high-emphasis btn-close position-absolute end-0 me-2"
                    onClick={onClose}
                    size={10}
                />
            </Modal.Header>
            <Modal.Body className="create-engagement-modal__body bg-body-color text-text-high-emphasis rounded-bottom border border-structural-stroke-100 border-top-0">
                <Form noValidate validated={validated} onSubmit={handleSubmit} data-testid="form-submit">
                    <Form.Group className="mb-3">
                        <Form.Label className="body5">{ENGAGEMENT_TITLE}</Form.Label>
                        <Form.Control
                            required
                            type="input"
                            className="bg-transparent text-text-high-emphasis border border-structural-stroke-100"
                            onChange={handleTitleChange}
                            autoFocus
                            value={flowSpec.name}
                            data-testid="engagementTitle"
                        />
                        <Form.Control.Feedback type="invalid">{ENGAGEMENT_TITLE_VALIDATION_TEXT}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <div className="create-engagement-modal__body__textarea-wrapper">
                            <Form.Label className="body5">{ENGAGEMENT_DESCRIPTION}</Form.Label>
                            <Form.Control
                                data-testid="engagementDescription"
                                as="textarea"
                                className="create-engagement-modal__body__textarea-wrapper__textarea bg-transparent text-text-high-emphasis border border-structural-stroke-100 custom-scrollbar"
                                rows={3}
                                maxLength={DESCRIPTION_CHAR_LIMIT}
                                onChange={handleDescriptionChange}
                                value={flowSpec.description}
                            />
                            <div className="create-engagement-modal__body__textarea-wrapper__count caption4 end-0 bottom-0 pe-2 pb-1 text-text-low-emphasis">
                                {flowSpec.description.length} / {DESCRIPTION_CHAR_LIMIT}
                            </div>
                        </div>
                    </Form.Group>
                    <div className="create-engagement-modal__actions d-flex gap-3 justify-content-center mt-">
                        <Button
                            variant="outline-text-low-emphasis"
                            className="body1 btn-cancel"
                            onClick={onCancelHandler}
                        >
                            {CANCEL}
                        </Button>
                        <Button variant="primary-500" className="body1 text-text-black" type="submit">
                            {CREATE}
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default CreateEngagementModal;
