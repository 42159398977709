import { ReactSVG } from 'react-svg';
import classnames from 'classnames';
import { Button, Card } from 'react-bootstrap';
import { INTEGRATEDTOOLHEADERS } from 'Core-utils/constants/constants';
import { Typography } from 'Components/atoms';
import { ConnectorStatus } from '@ampsec/platform-client';
import './styles.scss';

type IntegratedVendorCardProps = {
    imageUrl: string;
    title: string;
    tool: string;
    handleCard: (_title: string) => void;
} & (
    | {
          variant: ConnectorStatus;
      }
    | {
          variant: 'custom';
      }
);

const IntegratedVendorCard = ({
    imageUrl,
    title,
    tool,
    handleCard,
    variant = ConnectorStatus.ACTIVE,
    ...syncProps
}: IntegratedVendorCardProps) => {
    const isActive = variant === ConnectorStatus.ACTIVE;
    const isDisabled = variant === ConnectorStatus.DISABLED;
    const isFailed = variant === ConnectorStatus.FAILED;
    const isSync = variant === ConnectorStatus.SYNCING;
    const isDegraded = variant === ConnectorStatus.DEGRADED;
    const isPaused = variant === ConnectorStatus.PAUSED;
    const isDeleted = variant === ConnectorStatus.DELETED;
    const isCustom = variant === 'custom';
    const inactive = 'inactive' in syncProps ? syncProps.inactive : false;

    const cardHeaderClasses = classnames(
        'integrated-card__card-header d-flex justify-content-center align-items-center',
        {
            'bg-success-green': isActive || isSync,
            'bg-secondary-accent-red-4': isFailed || isDegraded,
            'bg-text-low-emphasis': isDisabled || isPaused || isDeleted,
            'bg-primary-500': isCustom,
            'text-center': tool,
            'text-uppercase': tool,
        },
    );

    const cardBodyClasses = classnames('integrated-card__card-body mt-4', {
        'd-flex': tool,
        'flex-column': tool,
        'align-items-center': tool,
    });

    const handleCardClick = () => handleCard(title);

    return (
        <Card
            className={classnames(
                'integrated-card__card-integrated bg-text-black rounded',
                {
                    'border-success-green': isActive || isSync,
                    'border-secondary-accent-red-4': isFailed || isDegraded,
                    'border-text-low-emphasis': isDisabled || isPaused || isDeleted,
                    'border-primary-500': isCustom,
                    'integrated-card__card-integrated--inactive': isSync && inactive,
                },
                'overflow-hidden',
            )}
            data-testid={`${tool}`}
        >
            <div className={classnames(cardHeaderClasses, { 'integrated-card--disabled': isDisabled })}>
                <Typography variant="body8" color="text-black" style={{ marginBottom: '0rem' }}>
                    {title}
                </Typography>
            </div>
            <div className={cardBodyClasses}>
                <div className="d-flex align-items-center justify-content-center" style={{ maxWidth: '77px' }}>
                    {imageUrl &&
                        (imageUrl.endsWith('.svg') ? (
                            <ReactSVG src={imageUrl} className="integrated-card__icon" />
                        ) : (
                            <img src={imageUrl} className="integrated-card__icon" />
                        ))}
                </div>
                <Typography variant="subtitle5" color="text-high-emphasis">
                    {tool}
                </Typography>
                <Button
                    onClick={handleCardClick}
                    className="text-center text-uppercase border-text-high-emphasis mt-2 rounded"
                >
                    <Typography variant="subtitle6" color="text-high-emphasis" style={{ marginBottom: '0rem' }}>
                        {INTEGRATEDTOOLHEADERS[2]}
                    </Typography>
                </Button>
            </div>
        </Card>
    );
};

export default IntegratedVendorCard;
