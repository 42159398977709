import { useState } from 'react';
import { Button, FormControl, Modal } from 'react-bootstrap';
import { IoClose } from '@react-icons/all-files/io5/IoClose';
import { Typography } from 'Components/atoms';
import { InviteUserDetails } from 'Components/molecules';
import { MenuItem } from 'Core-utils/types/types';
import { ENTER_VALID_EMAIL, INVITE } from 'Core-utils/constants/constants';
import RoleDropdown from 'Components/pages/SettingsPage/RoleDropdown';
import classNames from 'classnames';
import 'Theme/scroll.scss';
import './styles.scss';

export interface Option {
    id: number;
    name: string;
}
interface InviteUserModalProps {
    show: boolean;
    invitedUserList: MenuItem[];
    onClose: () => void;
    addUser: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    revokeInvitation: (id: string) => void;
    onDropdownChange?: (eventKey: string | null, e: any) => void;
    dropdownValue?: string;
    dropdownItems: MenuItem[];
    onInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    emailInput: string;
    isValid: boolean;
    disableInviteButton: boolean;
}

const InviteUserModal = ({
    show,
    invitedUserList,
    onClose,
    addUser,
    revokeInvitation,
    onDropdownChange,
    dropdownValue,
    dropdownItems,
    emailInput,
    onInputChange,
    isValid,
    disableInviteButton,
}: InviteUserModalProps) => {
    const [isActive, setIsActive] = useState<boolean>(false);

    const handleFocus = () => {
        setIsActive(true);
    };

    const handleBlur = () => {
        setIsActive(false);
    };
    const containerClass = classNames('d-flex justify-content-between custom-scrollbar', {
        'border border-grey-50 rounded': isValid,
        'border border-vizualization-red-200': !isValid,
    });
    const className = classNames('position-relative', 'search-container', {
        'bg-transparent text-primary': isActive,
    });

    const formControlClass = classNames('border-0 bg-transparent p-2');

    return (
        <div data-testid="invite-users">
            <Modal show={show} centered className="invite-users w-100" onHide={onClose}>
                <Modal.Header className="text-text-high-emphasis">
                    <Modal.Title className="subtitle1">{INVITE}</Modal.Title>
                    <IoClose className="invite-users__icon" size={20} onClick={onClose} />
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex flex-column gap-4">
                        <div className="d-flex gap-2 justify-content-between">
                            <div className="d-flex flex-column gap-2">
                                <div className="d-flex gap-2">
                                    <div className={containerClass}>
                                        <div className={className}>
                                            <FormControl
                                                className={formControlClass}
                                                placeholder={'Email'}
                                                value={emailInput}
                                                onChange={onInputChange}
                                                onFocus={handleFocus}
                                                onBlur={handleBlur}
                                            />
                                        </div>
                                    </div>
                                    <RoleDropdown
                                        dropDownItems={dropdownItems}
                                        value={dropdownValue ?? ''}
                                        onChange={onDropdownChange}
                                    />
                                </div>
                                {!isValid && (
                                    <Typography
                                        variant={'caption4'}
                                        color="vizualization-red-200"
                                        style={{ marginBottom: '0%' }}
                                    >
                                        {ENTER_VALID_EMAIL}
                                    </Typography>
                                )}
                            </div>
                            <Button
                                className="invite-users__button-cont"
                                onClick={addUser}
                                disabled={disableInviteButton}
                            >
                                <Typography variant="body1" color="text-black">
                                    {INVITE}
                                </Typography>
                            </Button>
                        </div>
                        <div className="invite-users__user-details d-flex flex-column custom-scrollbar">
                            {invitedUserList.map((user: MenuItem) => (
                                <InviteUserDetails
                                    key={user.id}
                                    userId={user.id}
                                    email={user.value}
                                    revokeInvitation={() => revokeInvitation(user.id)}
                                />
                            ))}
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default InviteUserModal;
