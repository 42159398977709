import { createModel } from '@rematch/core';
import type { RootModel } from './models';

const orgModel = createModel<RootModel>()({
    state: {
        org: 'ALL',
    },
    reducers: {
        setOrg(state, payload) {
            return { ...state, org: payload };
        },
    },
});

export default orgModel;
