import { Button, Modal } from 'react-bootstrap';
import { IoClose } from '@react-icons/all-files/io5/IoClose';
import { FINDINGS_GRID_MODAL_HEIGHT, VIEW_IN_SECURITY_FINDINGS } from 'Core-utils/constants/constants';
import { Typography } from 'Components/atoms';
import { useNavigate } from 'react-router-dom';
import { FINDINGS_ROUTE } from 'Core-utils/routes';
import { FindingsGrid } from 'Components/organisms';
import './styles.scss';
import { useSearchParamsState } from 'Hooks/useSearchParamsState';

interface FindingsGridModalProps {
    title: string;
    onClose: () => void;
    show: boolean;
    defaultFilter?: { [key: string]: string[] };
}

const FindingsGridModal = ({ title, onClose, show, defaultFilter = {} }: FindingsGridModalProps) => {
    const { filter } = useSearchParamsState();
    const navigate = useNavigate();
    const handleViewClick = () => {
        const filters = {
            ...filter,
            ...defaultFilter,
        };
        navigate(FINDINGS_ROUTE, { state: filters });
    };
    if (!show) {
        return null;
    }
    return (
        <Modal show={show} className="findingsgrid-modal w-100" centered onHide={onClose}>
            <Modal.Header className="text-text-high-emphasis d-flex justify-content-between">
                <Modal.Title className="h6">{title}</Modal.Title>
                <div className="d-flex gap-3 align-items-center">
                    <Button className="findingsgrid-modal__button" onClick={handleViewClick}>
                        <Typography variant="body1" color="primary-500" style={{ marginBottom: '0%' }}>
                            {VIEW_IN_SECURITY_FINDINGS}
                        </Typography>
                    </Button>
                    <IoClose
                        className="findingsgrid-modal__icon"
                        size={20}
                        onClick={onClose}
                        data-testid="close-icon"
                    />
                </div>
            </Modal.Header>
            <Modal.Body className="p-0 w-0">
                <FindingsGrid
                    defaultFilter={defaultFilter}
                    enableUpdatingURLWithFiltersAndSorting={true}
                    tableBodyHeight={FINDINGS_GRID_MODAL_HEIGHT}
                    showSubHeader={false}
                />
            </Modal.Body>
        </Modal>
    );
};

export default FindingsGridModal;
