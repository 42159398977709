// From react-router-dom docs: https://v5.reactrouter.com/web/example/query-parameters
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export const useHash = () => {
    const { hash } = useLocation();
    const hashString = hash.substring(1);

    return useMemo(() => new URLSearchParams(hashString), [hashString]);
};
