import { AiOutlineClose } from '@react-icons/all-files/ai/AiOutlineClose';
import { RiDeleteBin6Line } from '@react-icons/all-files/ri/RiDeleteBin6Line';
import { IoMdInformationCircleOutline } from '@react-icons/all-files/io/IoMdInformationCircleOutline';
import { Typography } from 'Components/atoms';
import { Button, Form, Modal } from 'react-bootstrap';
import {
    CANCEL,
    EVENT_TYPE,
    EMPTY_TYPE_CANNOT_BE_EMPTY,
    DESCRIPTION,
    DESCRIPTION_CANNOT_BE_EMPTY,
    FINDINGS,
    FINDING_CANNOT_BE_EMPTY,
    FINDING_DISPLAY_NAME,
    FINDING_NAME,
    FINDING_NAME_CANNOT_BE_EMPTY,
    IMPORT_FROM_PAYLOAD,
    SAVE_TEXT,
    SEVERITY_TEXT,
} from 'Core-utils/constants/constants';
import { DropdownComponent } from 'Components/molecules';
import { useEffect, useState } from 'react';
import { FindingSeverity, FindingSpecDto } from '@ampsec/platform-client';
import { useI18n } from 'Hooks/useI18n';
import { CustomTooltip } from 'Components/atoms';
import './styles.scss';
interface FindingsModalProp {
    show: boolean;
    onClose: () => void;
    onSave: (finding: FindingSpecDto) => void;
    defaultFindingType: FindingSpecDto;
}

const FindingsModal = ({ show, onClose, onSave, defaultFindingType }: FindingsModalProp) => {
    const [findingData, setFindingData] = useState<FindingSpecDto>({ ...defaultFindingType });
    const [validated, setValidated] = useState<boolean>(false);
    const [checked, setChecked] = useState<boolean>(false);
    const { t } = useI18n();

    const [rows, setRows] = useState<
        {
            id: number;
            value: string;
        }[]
    >([{ id: 0, value: '' }]);

    const handleSubmit = (event: any) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
            return;
        }
        if (form.checkValidity() === true) {
            handleSave();
        }
    };
    const handleSave = () => {
        const newFinding: FindingSpecDto = {
            ...findingData,
        };
        onSave(newFinding);
        onClose();
        setValidated(false);
    };

    const handleChange = (field: keyof FindingSpecDto, value?: string | boolean) => {
        setFindingData((prevData) => ({
            ...prevData,
            [field]: value,
        }));
    };

    const handleHide = () => {
        onClose();
        setValidated(false);
    };

    const handleToggle = () => {
        setChecked((checked) => !checked);
    };

    useEffect(() => {
        setFindingData({ ...defaultFindingType });
    }, [defaultFindingType]);

    const _handleAddRow = () => {
        setRows((prevRows) => [...prevRows, { id: prevRows.length + 1, value: '' }]);
    };

    const _renderTable = () => {
        const handleDeleteRow = (id: number) => () => {
            setRows((prevRows) => prevRows.filter((row) => row.id !== id));
        };
        return (
            <div>
                <table className="w-100">
                    <tbody>
                        {rows.map((row) => (
                            <tr key={row.id}>
                                <td className="findings-config__table-cell p-0">
                                    <Form.Control
                                        as="textarea"
                                        className="findings-config__input bg-transparent shadow-none text-text-high-emphasis border-0 overflow-auto custom-scrollbar"
                                        value={findingData.description as string}
                                        onChange={(e) => handleChange('description', e.target.value)}
                                        required
                                    />
                                </td>
                                <td className="findings-config__table-cell d-flex justify-content-center align-items-center p-0">
                                    <RiDeleteBin6Line
                                        className="text-text-high-emphasis"
                                        cursor={'pointer'}
                                        onClick={handleDeleteRow(row.id)}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    };

    const renderDescription = () => {
        return (
            <div className="d-flex flex-column">
                <div className="findings-config__description">
                    <div className="d-flex gap-2 ps-3 pb-3 pt-2 align-items-center findings-config__check findings-config_display">
                        <Form.Check type="switch" checked={checked} onChange={handleToggle} data-testid="checkbox" />
                        <Typography variant="body5" color="text-high-emphasis" style={{ marginBottom: '0%' }}>
                            {IMPORT_FROM_PAYLOAD}
                        </Typography>
                    </div>

                    <Form.Control
                        as="textarea"
                        className="findings-config__description-form bg-transparent shadow-none text-text-high-emphasis border border-structural-stroke-200 overflow-auto custom-scrollbar"
                        value={findingData.description as string}
                        onChange={(e) => handleChange('description', e.target.value)}
                        required
                        data-testid="finding-description"
                    />
                    <Form.Control.Feedback type="invalid">{DESCRIPTION_CANNOT_BE_EMPTY}</Form.Control.Feedback>
                </div>
            </div>
        );
    };

    return (
        <Modal
            show={show}
            className="findings-config"
            centered
            onHide={handleHide}
            keyboard={false}
            tabIndex={-1}
            data-testid="modal-dialog"
        >
            <Modal.Header>
                <Modal.Title>
                    <Typography variant="subtitle1" color="text-high-emphasis" style={{ marginBottom: '0%' }}>
                        {FINDINGS}
                    </Typography>
                </Modal.Title>
                <AiOutlineClose
                    className="text-text-high-emphasis"
                    onClick={onClose}
                    cursor={'pointer'}
                    data-testid="close-icon"
                />
            </Modal.Header>
            <Modal.Body>
                <Form noValidate className="d-flex gap-2 flex-column" validated={validated} onSubmit={handleSubmit}>
                    <div className="d-flex gap-2 flex-column findings-config__content overflow-auto custom-scrollbar">
                        <div className="d-flex flex-column gap-2 align-items-start">
                            <div className="d-flex gap-1">
                                <Typography variant="body5" color="text-high-emphasis" style={{ marginBottom: '0%' }}>
                                    {FINDING_NAME}
                                </Typography>
                                <CustomTooltip label={t('CUSTOM_FINDING_FINDING_NAME_HELP')}>
                                    <IoMdInformationCircleOutline className="text-text-low-emphasis" />
                                </CustomTooltip>
                            </div>

                            <Form.Control
                                size="sm"
                                className="findings-config__form bg-transparent shadow-none text-text-high-emphasis"
                                value={findingData.name as string}
                                onChange={(e) => handleChange('name', e.target.value)}
                                required
                                data-testid="finding-name"
                            />
                            <Form.Control.Feedback type="invalid">{FINDING_NAME_CANNOT_BE_EMPTY}</Form.Control.Feedback>
                        </div>
                        <div className="d-flex flex-column gap-2">
                            <div className="d-flex gap-1">
                                <Typography variant="body5" color="text-high-emphasis" style={{ marginBottom: '0%' }}>
                                    {EVENT_TYPE}
                                </Typography>
                                <CustomTooltip label={t('CUSTOM_FINDING_EVENT_TYPE_HELP')}>
                                    <IoMdInformationCircleOutline className="text-text-low-emphasis" />
                                </CustomTooltip>
                            </div>
                            <Form.Control
                                size="sm"
                                className="findings-config__form bg-transparent shadow-none text-text-high-emphasis"
                                value={findingData.eventType as string}
                                onChange={(e) => handleChange('eventType', e.target.value)}
                                required
                                data-testid="event-type"
                            />
                            <Form.Control.Feedback type="invalid">{EMPTY_TYPE_CANNOT_BE_EMPTY}</Form.Control.Feedback>
                        </div>
                        <div className="d-flex flex-column gap-2">
                            <div className="d-flex gap-1">
                                <Typography
                                    variant="body5"
                                    color="text-high-emphasis"
                                    style={{ marginBottom: '0%', textTransform: 'capitalize' }}
                                >
                                    {SEVERITY_TEXT}
                                </Typography>
                                <CustomTooltip label={t('CUSTOM_FINDING_SEVERITY_HELP')}>
                                    <IoMdInformationCircleOutline className="text-text-low-emphasis" />
                                </CustomTooltip>
                            </div>
                            <DropdownComponent
                                dropDownItems={Object.values(FindingSeverity).map((value, index) => ({
                                    id: index + 1,
                                    value: value as string,
                                }))}
                                value={findingData.severity as FindingSeverity}
                                onChange={(value) => handleChange(`severity` as keyof FindingSpecDto, value ?? '')}
                            />
                        </div>
                        <div className="d-flex flex-column gap-2">
                            <div className="d-flex gap-1">
                                <Typography variant="body5" color="text-high-emphasis" style={{ marginBottom: '0%' }}>
                                    {FINDING_DISPLAY_NAME}
                                </Typography>
                                <CustomTooltip label={t('CUSTOM_FINDING_FINDING_DISPLAY_NAME_HELP')}>
                                    <IoMdInformationCircleOutline className="text-text-low-emphasis" />
                                </CustomTooltip>
                            </div>
                            <Form.Control
                                size="sm"
                                className="findings-config__form bg-transparent shadow-none text-text-high-emphasis"
                                value={findingData.displayValue as string}
                                onChange={(e) => handleChange('displayValue', e.target.value)}
                                required
                                data-testid="finding-display-name"
                            />
                            <Form.Control.Feedback type="invalid">{FINDING_CANNOT_BE_EMPTY}</Form.Control.Feedback>
                        </div>
                        <div className="d-flex flex-column gap-2">
                            <div className="d-flex gap-1">
                                <Typography variant="body5" color="text-high-emphasis" style={{ marginBottom: '0%' }}>
                                    {DESCRIPTION}
                                </Typography>
                                <CustomTooltip label={t('CUSTOM_FINDING_DESCRIPTION_HELP')}>
                                    <IoMdInformationCircleOutline className="text-text-low-emphasis" />
                                </CustomTooltip>
                            </div>
                            {renderDescription()}
                        </div>
                    </div>
                    <div className="d-flex justify-content-center gap-3">
                        <Button
                            className="text-text-high-emphasis bg-transparent body1 text-uppercase findings-config__cancel-button"
                            onClick={handleHide}
                            data-testid="cancel-btn"
                        >
                            {CANCEL}
                        </Button>
                        <Button
                            className="text-text-black bg-primary-500 body1 text-uppercase"
                            type="submit"
                            data-testid="save-btn"
                        >
                            {SAVE_TEXT}
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default FindingsModal;
