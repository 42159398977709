import { Row, Col } from 'react-bootstrap';
import { ReactSVG } from 'react-svg';
import { EMPTY_MESSAGE } from 'Core-utils/constants/constants';

interface EmptyStateProps {
    ImageSrc: string;
    noDescription?: boolean;
    description?: string;
}

const EmptyState = ({ ImageSrc, noDescription = false, description }: EmptyStateProps) => {
    return (
        <div
            className="w-100 d-flex justify-content-center align-items-center align-content-center"
            data-testid="empty-state"
        >
            <Col className="d-flex flex-column justify-content-center  gap-4">
                <Row className="d-grid flex-row align-items-center justify-content-center">
                    <ReactSVG src={ImageSrc} />
                </Row>
                {!noDescription && (
                    <div className="d-flex align-items-center justify-content-center">
                        <div className="d-flex flex-column align-items-center justify-content-center">
                            <span className="body5 text-text-high-emphasis">
                                {description ? description : EMPTY_MESSAGE}
                            </span>
                        </div>
                    </div>
                )}
            </Col>
        </div>
    );
};

export default EmptyState;
