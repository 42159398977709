import { Button, Modal } from 'react-bootstrap';
import { AiOutlineLike } from '@react-icons/all-files/ai/AiOutlineLike';
import { AiOutlineDislike } from '@react-icons/all-files/ai/AiOutlineDislike';
import { AiOutlineClose } from '@react-icons/all-files/ai/AiOutlineClose';
import JSONPretty from 'react-json-pretty';
import { ENGAGEMENT_SAVE, LinkItems, PROMPT_HELPER_TEXT, RESET_LABEL } from 'Core-utils/constants/constants';
import { Typography } from 'Components/atoms';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import './styles.scss';

interface ViewQueryModalProps {
    onClose: () => void;
    show: boolean;
    json: Object;
    isFooter: boolean;
    title: string;
}

const ViewQueryModal = ({ show, onClose, json, isFooter, title }: ViewQueryModalProps) => {
    const editorClassName = classNames({
        'view-query-modal__editor overflow-auto custom-scrollbar': !isFooter,
        'view-query-modal__action-editor overflow-auto custom-scrollbar': isFooter,
    });

    return (
        <Modal show={show} className="w-100 view-query-modal" centered onHide={onClose}>
            <Modal.Header className="text-text-high-emphasis d-flex justify-content-between">
                <Modal.Title className="subtitle1">{title}</Modal.Title>
                <AiOutlineClose className="text-text-high-emphasis view-query-modal__icon" onClick={onClose} />
            </Modal.Header>
            <Modal.Body className="d-flex flex-column gap-3 p-3 w-0 text-text-high-emphasis bg-text-color">
                <div className={`border border-structural-stroke-200 p-3 ${editorClassName}  rounded`}>
                    <JSONPretty data={json} style={{ fontFamily: 'monospace', whiteSpace: 'pre-wrap' }} />
                </div>
                {!isFooter && (
                    <div className="d-flex justify-content-between">
                        <div className="d-flex flex-column gap-1">
                            <div className="d-flex gap-1">
                                <Typography variant={'body3'} color="text-high-emphasis" style={{ marginBottom: '0%' }}>
                                    {PROMPT_HELPER_TEXT}
                                </Typography>
                                <AiOutlineLike className="text-text-medium-emphasis" size={20} />
                                <AiOutlineDislike className="text-text-medium-emphasis" size={20} />
                            </div>
                            {LinkItems.map((item) => (
                                <Link
                                    to={item.id}
                                    key={`link-${item.text}`}
                                    className="d-flex gap-1 view-query-modal__link"
                                >
                                    <Typography variant={'body7'} color="primary-500" style={{ marginBottom: '0%' }}>
                                        {item.text}
                                    </Typography>
                                    {item.Icon}
                                </Link>
                            ))}
                        </div>
                        <div className="d-flex gap-2">
                            <Button
                                className="d-flex align-items-center bg-transparent border border-text-low-emphasis ps-3 pe-3 pt-2 pb-2 text-text-high-emphasis body1 view-query-modal__btn"
                                disabled
                            >
                                {ENGAGEMENT_SAVE}
                            </Button>
                            <Button
                                className="d-flex align-items-center bg-transparent border border-text-low-emphasis ps-3 pe-3 pt-2 pb-2 text-text-high-emphasis body1 view-query-modal__btn"
                                disabled
                            >
                                {RESET_LABEL}
                            </Button>
                        </div>
                    </div>
                )}
            </Modal.Body>
        </Modal>
    );
};

export default ViewQueryModal;
