import { Button } from 'react-bootstrap';
import { useState } from 'react';
import classNames from 'classnames';
import { ReactSVG } from 'react-svg';
import { TbArrowsDiagonalMinimize2 } from '@react-icons/all-files/tb/TbArrowsDiagonalMinimize2';
import { BiExpandAlt } from '@react-icons/all-files/bi/BiExpandAlt';
import PromptHeaderIcon from 'Assets/icons/PromptHeaderIcon.svg';
import Inbox from 'Assets/icons/Inbox.svg';
import Reset from 'Assets/icons/Reset.svg';
import { Typography } from 'Components/atoms';
import { CREATE_ENGAGEMENT, FINDINGS_PROMPT_HEADER } from 'Core-utils/constants/constants';
import { CreateEngagementModal } from 'Components/organisms/CreateEngagementModal';
interface PromptHeader {
    onOpenSavedPrompt: () => void;
    onClearPrompts: () => void;
    showResetButton: boolean;
    onToggleOpen: () => void;
    open: boolean;
    loading?: boolean;
    promptsListed?: boolean;
}

const PromptHeader = ({
    onOpenSavedPrompt,
    onClearPrompts,
    showResetButton,
    onToggleOpen,
    open,
    loading = false,
    promptsListed = false,
}: PromptHeader) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const headerClassName = classNames(
        'findings-prompt__header w-100 p-3 text-text-high-emphasis d-flex justify-content-between align-items-center',
        {
            rounded: !open,
            ' rounded-top': open,
        },
    );

    return (
        <div className={headerClassName}>
            <div className="d-flex align-items-center">
                <ReactSVG src={PromptHeaderIcon} /> &nbsp;
                <Typography variant="subtitle1" style={{ margin: '0%' }}>
                    {FINDINGS_PROMPT_HEADER}
                </Typography>
            </div>
            <div className="d-flex gap-3 align-items-center">
                {open && promptsListed && (
                    <Button
                        variant="primary-500"
                        className="engagement-flow__tab-cont__engagement_button body1 text-text-black"
                        onClick={handleShow}
                        disabled={!promptsListed || loading}
                    >
                        {CREATE_ENGAGEMENT}
                    </Button>
                )}
                {showResetButton && open && (
                    <ReactSVG
                        className="findings-prompt__header__icon"
                        src={Reset}
                        onClick={onClearPrompts}
                        cursor={'pointer'}
                        data-testid="reset-icon"
                    />
                )}
                {open && (
                    <ReactSVG
                        className="findings-prompt__header__icon"
                        src={Inbox}
                        onClick={onOpenSavedPrompt}
                        cursor={'pointer'}
                        data-testid="inbox-icon"
                    />
                )}
                <span className="findings-prompt__header__icon text-primary-500" onClick={onToggleOpen}>
                    {open ? <TbArrowsDiagonalMinimize2 size={20} /> : <BiExpandAlt size={20} />}
                </span>
            </div>
            <CreateEngagementModal show={show} onClose={handleClose} />
        </div>
    );
};

export default PromptHeader;
