import { useEffect, useState } from 'react';
import { Button, Form, Tab, Tabs } from 'react-bootstrap';
import { Typography } from 'Components/atoms';
import { ADD_INTEGRATION } from 'Core-utils/constants/constants';
import './styles.scss';

type IntegrationTabsType = {
    title: string;
    content: React.ReactNode;
};

interface IntegrationTabsProps {
    hideButton?: boolean;
    tabs?: IntegrationTabsType[];
    onIntegration?: () => void;
}

const MockIntegrationTabs = ({ tabs, onIntegration }: IntegrationTabsProps) => {
    const [activeTab, setActiveTab] = useState(tabs && Array.isArray(tabs) ? `0-${tabs[0]?.title}` : '');
    const handleTabChange = (eventKey: any) => {
        setActiveTab(eventKey);
    };

    useEffect(() => {
        if (activeTab === '' && tabs && tabs.length > 0) {
            setActiveTab(`0-${tabs[0].title}`);
        }
    }, [tabs]);

    return (
        <div className="d-flex justify-content-between flex-column bg-body-black text-text-high-emphasis add-integration add-integration__content w-100">
            <div className="add-integration__tab-cont ps-4 pe-4">
                <Form>
                    <Tabs activeKey={activeTab} onSelect={handleTabChange} className="add-integration__custom-tabs">
                        {tabs?.map((tab, index) => (
                            <Tab
                                key={`${index}-${tab.title}`}
                                eventKey={`${index}-${tab.title}`}
                                title={<Typography variant="subtitle2">{tab.title}</Typography>}
                            >
                                <div className="content-container pt-4 add-integration__body-content custom-scrollbar">
                                    {tab.content}
                                </div>
                            </Tab>
                        ))}
                    </Tabs>
                </Form>
            </div>
            <div className="d-flex justify-content-end pb-3 pe-3">
                <Button
                    className="btn btn-primary-500 btn-lg body1 text-text-black add-integration__button text-uppercase"
                    onClick={onIntegration}
                    data-testid="add-integration-button"
                >
                    {ADD_INTEGRATION}
                </Button>
            </div>
        </div>
    );
};

export default MockIntegrationTabs;
