import IntegrationList from './IntegrationList';
import './styles.scss';

export interface IntegrationFilterDataProps {
    id?: number;
    text: string;
    count: number;
    image?: string;
}

export interface IntegrationFilterProps {
    integrationsFilterData: IntegrationFilterDataProps[];
    activeFilter: string;
    onClickCategory: (filter: string) => void;
}

const IntegrationsFilter = ({ activeFilter, integrationsFilterData, onClickCategory }: IntegrationFilterProps) => {
    return (
        <ul className="list-group bg-structural-CardBG integration-filter custom-scrollbar overflow-auto">
            <IntegrationList
                integrationsFilterData={integrationsFilterData}
                activeFilter={activeFilter}
                onClickCategory={onClickCategory}
            />
        </ul>
    );
};

export default IntegrationsFilter;
