import { areaGraphData } from 'Core-utils/constants/charts';
import ReactApexChart from 'react-apexcharts';
import './styles.scss';

interface Series {
    name?: string;
    data: Array<number | never>;
}

export interface AreaGraphProps {
    series: Series[];
    categories: string[];
}

const AreaGraph = (props: AreaGraphProps) => {
    const chartData = areaGraphData(props);

    return (
        <div className="area-graph" data-testid="area-graph">
            <ReactApexChart series={chartData.series} options={chartData.options} type="area" width="100%" />
        </div>
    );
};

export default AreaGraph;
