import { useEffect, useState } from 'react';
import useSWR from 'swr';
import { FilterCriteria } from '@ampsec/platform-client';
import { store } from 'Rdx/store';

export const useApiObjectData = <DataT, RenderT>(
    cacheKey: string,
    fetcher: any,
    transformer: (_dataItem: DataT) => RenderT = (dataItem) => (({ ...dataItem } as unknown) as RenderT),
    filter?: FilterCriteria,
) => {
    const updatedFilter: FilterCriteria = { organization: store.getState().orgModel.org, ...filter };
    const { data: apiData, error, isLoading: isApiLoading, mutate } = useSWR([cacheKey, updatedFilter], () =>
        fetcher(cacheKey, updatedFilter),
    );
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState<RenderT>({} as RenderT);

    useEffect(() => {
        setData(apiData ? transformer(apiData) : ({} as RenderT));
        setIsLoading(false);
    }, [apiData]);

    return {
        data,
        error,
        isLoading: isLoading || isApiLoading,
        refresh: mutate,
    };
};
