import { useState } from 'react';
import { InputField } from 'Components/molecules';
import { Button } from 'react-bootstrap';
import { Typography } from 'Components/atoms';
import { ENTER_CREDENTIALS, INTEGRATE_NOW } from 'Core-utils/constants/constants';
import './styles.scss';

export type CredentialsScreenData = {
    label: string;
    placeholder: string;
    value: string;
    variant?: 'default' | 'encrypted';
};

interface CredentialsScreenProps {
    data: CredentialsScreenData[];
    onClick: () => void;
}

const CredentialsScreen = ({ data, onClick }: CredentialsScreenProps) => {
    const [updatedText, setUpdatedText] = useState<CredentialsScreenData[]>(data);

    const handleChange = (index: number, newValue: string) => {
        const updatedTextCopy = [...updatedText];
        updatedTextCopy[index] = { ...updatedTextCopy[index], value: newValue };
        setUpdatedText(updatedTextCopy);
    };

    const isDisabled = updatedText.some((dataItem) => !dataItem.value);

    return (
        <div className="credentials-screen-modal">
            <div className="mb-4 border-0 p-0">
                <Typography variant="body11" color="text-high-emphasis">
                    {ENTER_CREDENTIALS}
                </Typography>
            </div>
            <div className="d-flex flex-column gap-4">
                {updatedText.map((dataItem, index) => (
                    <InputField
                        key={index}
                        label={dataItem.label}
                        placeholder={dataItem.placeholder}
                        value={dataItem.value}
                        onValueChange={(newValue: string) => handleChange(index, newValue)}
                        variant="encrypted"
                    />
                ))}
            </div>
            <div className="mt-4 border-0 d-flex justify-content-start">
                <Button
                    className="credentials-screen-modal__button text-uppercase bg-primary-500 text-text-black d-flex align-items-center m-0"
                    onClick={onClick}
                    disabled={isDisabled}
                >
                    <Typography variant="body1">{INTEGRATE_NOW}</Typography>
                </Button>
            </div>
        </div>
    );
};

export default CredentialsScreen;
