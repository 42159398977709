import { AiOutlineClose } from '@react-icons/all-files/ai/AiOutlineClose';
import { Typography } from 'Components/atoms';
import { DRAG_PROMPT_TEXT, REWARDS_HEADING } from 'Core-utils/constants/constants';
import { CanvasType } from 'Core-utils/types';
import classNames from 'classnames';
import './styles.scss';

interface CanvasProps {
    data: CanvasType[];
    onDeleteClick: (id: string) => () => void;
    draggable?: boolean;
}

const Canvas = ({ data, onDeleteClick, draggable = false }: CanvasProps) => {
    const rewardsData = data.filter((item) => item.name === REWARDS_HEADING);
    const remainingData = data.filter((item) => item.name !== REWARDS_HEADING);

    const className = classNames(
        'canvas__canvas-styles bg-text-black d-flex flex-grow-1 custom-scrollbar w-100 h-100',
        {
            'justify-content-center align-items-center': data.length === 0,
            'ps-3 flex-column gap-3': data.length !== 0,
            'canvas__dotted-border-card pt-2': draggable,
            'pt-2': !draggable,
        },
    );

    function renderPromptItems(items: CanvasType[]) {
        return items.map((item) => (
            <div key={item.id} className="d-flex canvas__prompt-item justify-content-between">
                {renderTypography(item.value)}
                <AiOutlineClose
                    className="delete-button"
                    data-testid="delete-button"
                    onClick={onDeleteClick(item.id)}
                />
            </div>
        ));
    }

    function renderTypography(value: React.ReactNode) {
        return (
            <Typography
                variant="subtitle7"
                style={{
                    marginBottom: '0%',
                    marginLeft: '0.35rem',
                    width: '100%',
                    display: 'flex',
                    paddingLeft: '1rem',
                    paddingRight: '1rem',
                }}
                color="text-high-emphasis"
            >
                {value}
            </Typography>
        );
    }

    function renderRewardsSection(rewards: CanvasType[]) {
        if (rewards.length > 0) {
            return (
                <>
                    <Typography
                        variant="subtitle1"
                        style={{ marginBottom: '0%', marginLeft: '0.5rem', textTransform: 'uppercase' }}
                        color="text-high-emphasis"
                    >
                        {REWARDS_HEADING} :
                    </Typography>
                    {renderPromptItems(rewards)}
                </>
            );
        }
        return null;
    }

    return (
        <div className={className} id="canvas-id" data-testid="canvas-id">
            {data.length === 0 ? (
                <Typography variant="body8" style={{ marginBottom: '0%' }} color="text-low-emphasis">
                    {DRAG_PROMPT_TEXT}
                </Typography>
            ) : (
                <div className="d-flex flex-column gap-4">
                    {renderPromptItems(remainingData)}
                    {renderRewardsSection(rewardsData)}
                </div>
            )}
        </div>
    );
};

export default Canvas;
