import { Typography } from 'Components/atoms';
import { Modal } from 'react-bootstrap';
import { ReactSVG } from 'react-svg';
import Star from 'Assets/icons/Star.svg';
import classNames from 'classnames';
import { SAVED_QUERIES_EMPTY_MESSAGE, SAVED_QUESTIONS, VIEW_QUERY } from 'Core-utils/constants/constants';
import ErrorHandlerAndLoaderWrapper from 'Wrappers/ErrorHandlerandLoaderWrapper';
import SavedQueryIcon from 'Assets/icons/savedQuery.svg';
import { SavedQueryType } from 'Core-utils/types/types';
import _ from 'lodash';
import './styles.scss';

interface ViewSavedQueriesProps {
    onPromptClick: (id: string) => void;
    onViewQueryClick: (id: string) => void;
    onStarClick: (id: string) => void;
    queriesData: SavedQueryType[];
    show: boolean;
    onClose: () => void;
}

const ViewSavedQueries = ({
    onPromptClick,
    onStarClick,
    onViewQueryClick,
    queriesData,
    show,
    onClose,
}: ViewSavedQueriesProps) => {
    const renderSavedQueries = () =>
        queriesData.map((item, index) => (
            <div
                key={item.id}
                className={classNames('d-flex justify-content-between', {
                    'mb-4': index !== queriesData.length - 1,
                })}
                data-testid="saved-query"
            >
                <div className="d-flex gap-3">
                    <Typography variant="body5" color="text-high-emphasis">
                        #{index + 1}
                    </Typography>
                    <div className="d-flex flex-column gap-1">
                        <div onClick={() => onPromptClick(item.id)} className="saved__clickable">
                            <Typography variant="body5" color="text-high-emphasis">
                                {item.value}
                            </Typography>
                        </div>
                        <div
                            onClick={() => onViewQueryClick(item.id)}
                            className="saved__clickable invisible"
                            data-testid={`view-query-${index}`}
                        >
                            <Typography variant="body5" color="primary-500">
                                {VIEW_QUERY}
                            </Typography>
                        </div>
                    </div>
                </div>
                <ReactSVG
                    src={Star}
                    onClick={() => onStarClick(item.id)}
                    className="saved__clickable"
                    data-testid={`save-icon-${index}`}
                />
            </div>
        ));

    return (
        <Modal show={show} className="saved" onHide={onClose}>
            <Modal.Header>
                <Modal.Title className="text-uppercase">
                    <Typography variant="subtitle4" color="text-high-emphasis">
                        {SAVED_QUESTIONS}
                    </Typography>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="custom-scrollbar">
                <ErrorHandlerAndLoaderWrapper
                    error={undefined}
                    isEmpty={_.isEmpty(queriesData)}
                    ImageSrc={SavedQueryIcon}
                    render={renderSavedQueries}
                    description={SAVED_QUERIES_EMPTY_MESSAGE}
                />
            </Modal.Body>
        </Modal>
    );
};

export default ViewSavedQueries;
