import { Routes, Route, Navigate } from 'react-router-dom';
import {
    DASHBOARD_ROUTE,
    USER_SPECIFIC_ROUTE,
    HOME_ROUTE,
    WORKFLOW_ROUTE,
    DEFAULT_ROUTE,
    FINDINGS_ROUTE,
    SETTINGS_SPECIFIC_ROUTE,
    INTEGRATIONS_ROUTE,
    NOT_FOUND_ROUTE,
    POLICIES_ROUTE,
    CREATE_ENGAGEMENT_ROUTE,
    CUSTOM_FINDINGS_ROUTE,
    TOOLING_COVERAGE_ROUTE,
} from 'Core-utils/routes';
import { VendorsIntegration } from 'Components/organisms';
import { INTEGRATIONSDATA } from './core-utils/constants/constants';
import { EngagementFlowPage, DashboardPage, UserDetailPage, NotFoundPage, ToolingCoveragePage } from 'Components/pages';
import 'Theme/scroll.scss';
import CreateEngagementPage from 'Components/pages/CreateEngagementPage';
import { CustomFindingsPage } from 'Components/pages/CustomFindingsPage';
import { AuthRole } from '@ampsec/platform-client';
import PrivateRoute from 'Core-utils/PrivateRoute';
import { useSelector } from 'react-redux';
import { RootState } from 'Rdx/store';
import { SecurityFindingsPage } from 'Components/pages/SecurityFindingsPage';
import VendorDetailsPage from 'Components/pages/VendorDetailsPage/VendorDetailsPage';
import { SettingsPage } from 'Components/pages/SettingsPage';

const AppRoutes = () => {
    const auth = useSelector((state: RootState) => state.auth);

    return (
        <Routes>
            <Route
                path={DASHBOARD_ROUTE}
                element={
                    <PrivateRoute
                        element={<DashboardPage />}
                        userRole={(auth.user?.role as unknown) as AuthRole}
                        allowedRoles={[AuthRole.ADMIN, AuthRole.OWNER, AuthRole.VIEWER]}
                    />
                }
            />

            <Route
                path={USER_SPECIFIC_ROUTE}
                element={
                    <PrivateRoute
                        element={<UserDetailPage />}
                        userRole={(auth.user.role as unknown) as AuthRole}
                        allowedRoles={[AuthRole.ADMIN, AuthRole.OWNER, AuthRole.VIEWER]}
                    />
                }
            />
            <Route
                path={FINDINGS_ROUTE}
                element={
                    <PrivateRoute
                        element={<SecurityFindingsPage />}
                        userRole={(auth.user.role as unknown) as AuthRole}
                        allowedRoles={[AuthRole.ADMIN, AuthRole.OWNER, AuthRole.VIEWER]}
                    />
                }
            />
            <Route
                path={TOOLING_COVERAGE_ROUTE}
                element={
                    <PrivateRoute
                        element={<ToolingCoveragePage />}
                        userRole={auth.user.role}
                        allowedRoles={[AuthRole.ADMIN, AuthRole.OWNER, AuthRole.VIEWER]}
                    />
                }
            />
            <Route
                path={WORKFLOW_ROUTE}
                element={
                    <PrivateRoute
                        element={<EngagementFlowPage />}
                        userRole={(auth.user.role as unknown) as AuthRole}
                        allowedRoles={[AuthRole.ADMIN, AuthRole.OWNER]}
                    />
                }
            />
            <Route
                path={SETTINGS_SPECIFIC_ROUTE}
                element={
                    <PrivateRoute
                        element={<SettingsPage />}
                        userRole={(auth.user.role as unknown) as AuthRole}
                        allowedRoles={[AuthRole.ADMIN, AuthRole.OWNER]}
                    />
                }
            />

            <Route
                path={POLICIES_ROUTE}
                element={
                    <PrivateRoute
                        element={<VendorsIntegration integratedData={INTEGRATIONSDATA} />}
                        userRole={(auth.user.role as unknown) as AuthRole}
                        allowedRoles={[AuthRole.ADMIN, AuthRole.OWNER]}
                    />
                }
            />
            <Route
                path={CUSTOM_FINDINGS_ROUTE}
                element={
                    <PrivateRoute
                        element={<CustomFindingsPage />}
                        userRole={(auth.user.role as unknown) as AuthRole}
                        allowedRoles={[AuthRole.ADMIN, AuthRole.OWNER]}
                    />
                }
            />
            <Route
                path={INTEGRATIONS_ROUTE}
                element={
                    <PrivateRoute
                        element={<VendorDetailsPage />}
                        userRole={(auth.user.role as unknown) as AuthRole}
                        allowedRoles={[AuthRole.ADMIN, AuthRole.OWNER]}
                    />
                }
            />
            <Route
                path={CREATE_ENGAGEMENT_ROUTE}
                element={
                    <PrivateRoute
                        element={<CreateEngagementPage />}
                        userRole={(auth.user.role as unknown) as AuthRole}
                        allowedRoles={[AuthRole.ADMIN, AuthRole.OWNER]}
                    />
                }
            />

            <Route path={DEFAULT_ROUTE} element={<Navigate to={`${HOME_ROUTE}/org`} replace />} />
            <Route path={NOT_FOUND_ROUTE} element={<NotFoundPage />} />
        </Routes>
    );
};

export default AppRoutes;
