import { Typography } from 'Components/atoms';
import { VIEW_QUERY_BUTTON } from 'Core-utils/constants/constants';
import { BiUserCircle } from '@react-icons/all-files/bi/BiUserCircle';
import { AiOutlineStar } from '@react-icons/all-files/ai/AiOutlineStar';
import { AiFillStar } from '@react-icons/all-files/ai/AiFillStar';
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { PromptType } from 'Core-utils/types/types';

const Prompt = ({ id, value, avatar: _avatar, disabled, saved = false, onSaveQuery, onViewQueryClick }: PromptType) => {
    const [isSaved, setIsSaved] = useState<boolean>(saved);

    const handleToggleIsSaved = () => {
        if (!saved) {
            if (onSaveQuery) {
                onSaveQuery(true, { id: id, value: value });
            }
        }
        setIsSaved((saved) => !saved);
    };

    return (
        <div className="d-flex gap-1 mt-2 justify-content-between" key={id}>
            <div className="d-flex gap-1 m-1">
                <BiUserCircle size={40} className="text-text-low-emphasis" />
                <div className="findings-prompt__prompt text-wrap">
                    <Typography variant="body3" color="text-high-emphasis" style={{ margin: '0%' }}>
                        {value}
                    </Typography>
                    <Button
                        variant="outline-primary-500"
                        className="findings-prompt__prompt__btn border-0 p-0"
                        onClick={() => onViewQueryClick(id)}
                        disabled={disabled}
                    >
                        {VIEW_QUERY_BUTTON}s
                    </Button>
                </div>
            </div>
            <span className="findings-prompt__prompt text-text-medium-emphasis" onClick={handleToggleIsSaved}>
                {isSaved ? (
                    <AiFillStar size={25} cursor={'pointer'} data-testid="fill-star" />
                ) : (
                    <AiOutlineStar size={25} cursor={'pointer'} data-testid="line-star" />
                )}
            </span>
        </div>
    );
};

export default Prompt;
