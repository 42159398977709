import { Chip } from 'Components/atoms';
import { TOP_RISK_CONTRIBUTORS_MAP } from 'Core-utils/constants/constants';

type FindingChipProps = {
    provider: string;
    category: string;
    className?: string;
};

const FindingChip = ({ category, className }: FindingChipProps) => (
    <div className={className}>
        <Chip value={TOP_RISK_CONTRIBUTORS_MAP[category]} variant={'top_risk_issue'} />
    </div>
);

export default FindingChip;
