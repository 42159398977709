import { TableColumn } from 'react-data-table-component';
import { IoClose } from '@react-icons/all-files/io5/IoClose';
import { Typography } from 'Components/atoms';
import { SelectedUserGroupType } from 'Core-utils/types/types';
import { NAME, ADDED_DATE, DESCRIPTION, SOURCE_TYPE } from 'Core-utils/constants/constants';

export const SELECTED_USER_GROUP_GRID_COLUMNS: TableColumn<SelectedUserGroupType>[] = [
    {
        id: 'name',
        name: <Typography variant="body2">{NAME}</Typography>,
        sortable: false,
        wrap: true,
        cell: (row: SelectedUserGroupType) => {
            return <Typography variant="subtitle3">{row.name}</Typography>;
        },
    },
    {
        id: 'addedDate',
        name: <Typography variant="body2">{ADDED_DATE}</Typography>,
        sortable: false,
        wrap: true,
        cell: (row: SelectedUserGroupType) => {
            return <Typography variant="subtitle3">{row.addedDate}</Typography>;
        },
    },
    {
        id: 'description',
        name: <Typography variant="body2">{DESCRIPTION}</Typography>,
        sortable: false,
        wrap: true,
        width: '483px',
        cell: (row: SelectedUserGroupType) => {
            return <Typography variant="subtitle3">{row.description}</Typography>;
        },
    },
    {
        id: 'sourceType',
        name: <Typography variant="body2">{SOURCE_TYPE}</Typography>,
        sortable: false,
        wrap: true,
        cell: (row: SelectedUserGroupType) => {
            return <Typography variant="subtitle3">{row.sourceType}</Typography>;
        },
    },
    {
        id: 'close',
        name: '',
        sortable: false,
        wrap: true,
        width: '68px',
        cell: () => {
            return <IoClose size={20} />;
        },
    },
];
