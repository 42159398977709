import { CustomTooltip } from 'Components/atoms';
import { SOLO } from 'Core-utils/constants/constants';
import { Typography } from 'Components/atoms';
import { renderBars, roundOffValue } from 'Core-utils/helpers/helpers';
import './styles.scss';

interface VerticalSecScoreBarProps {
    percentage: number;
    department: string;
    isSelected: boolean;
    onClick: (department: string) => void;
    noOfUsers?: string | null;
}

const VerticalSecScoreBar = ({ percentage, department, noOfUsers, isSelected, onClick }: VerticalSecScoreBarProps) => {
    const textSelectedColor = isSelected ? 'text-high-emphasis' : 'text-high-emphasis';
    const opacityClass = !isSelected ? 'opacity-25' : '';
    const borderSelectedColor = isSelected ? 'text-high-emphasis rounded vertical-bars__border-selected border' : '';
    const handleOnClick = (department: string) => () => onClick(department);
    const variant = isSelected ? 'caption5' : 'caption4';
    const label = noOfUsers ? `${department}\n${noOfUsers} users` : department;

    return (
        <div
            className={`d-flex flex-column gap-1 justify-content-center align-items-center vertical-bars  border-${borderSelectedColor}`}
            onClick={handleOnClick(department)}
        >
            <Typography variant="subtitle2" color="text-high-emphasis">
                {roundOffValue(percentage)}
            </Typography>
            {renderBars(percentage)}
            {department === 'ALL' ? (
                <Typography variant={variant} color="text-high-emphasis" className="vertical-bars__text-style ">
                    {department}
                </Typography>
            ) : (
                <CustomTooltip label={label}>
                    <Typography variant={variant} color="text-high-emphasis" className="vertical-bars__text-style ">
                        {department}
                    </Typography>
                </CustomTooltip>
            )}
            <div className={`vertical-bars__solo border border-${textSelectedColor}  ${opacityClass}`}>
                <Typography variant="overline" color={textSelectedColor}>
                    {SOLO}
                </Typography>
            </div>
        </div>
    );
};

export default VerticalSecScoreBar;
