import { Typography } from 'Components/atoms';
import { Form, Image } from 'react-bootstrap';
import { INTEGRATIONHEADER, INTEGRATIONSTATUSLABEL, NEVER } from 'Core-utils/constants/constants';
import { ConnectorStatus } from '@ampsec/platform-client/src/dto';
import prettyMilliseconds from 'pretty-ms';
import moment from 'moment';
import './styles.scss';

interface IntegrationHeaderProps {
    icon: string;
    label: string;
    updatedTime: string;
    syncTime: string;
    status: ConnectorStatus;
    checked?: boolean;
    onToggle?: () => void;
}
const IntegrationHeaderContent = ({ icon, label, updatedTime, status, syncTime }: IntegrationHeaderProps) => {
    const syncTimeText = !isNaN(parseInt(syncTime ?? ''))
        ? `(${INTEGRATIONHEADER[3]} ${prettyMilliseconds(parseInt(syncTime) * 1000)})`
        : `(${NEVER})`;

    return (
        <div className="d-flex flex-row gap-3">
            <Image src={icon} className="integration-header__icon" />
            <div className="d-flex flex-column gap-2">
                <Typography variant="subtitle5" color="text-high-emphasis" className="m-0">
                    {label}
                </Typography>
                <div className="text-uppercase d-flex flex-row align-items-center gap-2">
                    <Typography variant="body3" color="text-medium-emphasis" className="m-0">
                        {INTEGRATIONHEADER[0]}
                        {INTEGRATIONSTATUSLABEL[status]}
                    </Typography>
                    <Typography variant="body5" color="text-medium-emphasis" className="gap-1">
                        {status}
                    </Typography>
                    <div className={`integration-header__circle ${status}`}></div>
                </div>
                <div className="d-flex flex-row align-items-center gap-1">
                    <Typography variant="subtitle3" color="text-medium-emphasis">
                        {INTEGRATIONHEADER[1]}
                    </Typography>
                    <Typography variant="body5" color="text-medium-emphasis" className="gap-1">
                        {updatedTime ? moment(updatedTime).fromNow() : NEVER}
                    </Typography>
                    <Typography variant="subtitle3" color="text-medium-emphasis">
                        {syncTimeText}
                    </Typography>
                </div>
            </div>
        </div>
    );
};

const IntegrationHeader = (props: IntegrationHeaderProps) => {
    const { icon, label, updatedTime, syncTime, checked, status, onToggle } = props;

    return (
        <div className="integration-header w-100 d-flex flex-row justify-content-between align-items-center bg-text-black border border-structural-dark-stroke-400">
            <IntegrationHeaderContent
                icon={icon}
                label={label}
                updatedTime={updatedTime}
                status={status}
                syncTime={syncTime}
            />
            <Form.Check type="switch" checked={checked} onChange={onToggle} data-testid='checkbox'/>
        </div>
    );
};

export default IntegrationHeader;
