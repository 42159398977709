import { I18nContentService } from '@ampsec/platform-client';
import { useCallback, useMemo, useState } from 'react';

const i18n = I18nContentService.instance();

export const useI18n = () => {
    const [loading, setLoading] = useState(false);
    const [initialized, setInitialized] = useState(false);
    useMemo(async () => {
        if (!initialized) {
            setLoading(true);
            await i18n.init();
            setLoading(false);
            setInitialized(true);
        }
    }, [initialized]);
    const refresh = useCallback(async () => {
        if (!loading) {
            setLoading(true);
            await i18n.refresh();
            setLoading(false);
        }
    }, [loading]);

    return {
        t: i18n.getLookupFunction(),
        loading,
        refresh,
    };
};
