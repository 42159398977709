import { ShareSecurityPostureType } from 'Core-utils/types/types';

export const SHARE_SECURITY_POSTURE_DATA: ShareSecurityPostureType[] = [
    {
        id: 1,
        name: 'Jakob Chrishop',
        addedDate: '2022-03-14T06:28:48Z',
        emailId: 'jchrishop0@amplifier.com',
        lastLoggedIn: new Date().toISOString(),
        status: 'ACTIVE',
        access: 'Admin',
    },
    {
        id: 2,
        name: 'Dosi Boodell',
        addedDate: '2022-03-14T06:28:48Z',
        emailId: 'jmarousekf@amplifier.com',
        lastLoggedIn: new Date().toISOString(),
        status: 'SUSPENDED',
        access: 'Admin',
    },
    {
        id: 3,
        name: 'Marlee Izzard',
        addedDate: '2022-03-14T06:28:48Z',
        emailId: 'dboodell1@amplifier.com',
        lastLoggedIn: new Date().toISOString(),
        status: 'SUSPENDED',
        access: 'Admin',
    },
    {
        id: 4,
        name: 'Jarred Ivatts',
        addedDate: '2022-03-14T06:28:48Z',
        emailId: 'mizzard2@amplifier.com',
        lastLoggedIn: new Date().toISOString(),
        status: 'SUSPENDED',
        access: 'Admin',
    },
    {
        id: 5,
        name: 'Dulsea Blackwood',
        addedDate: '2022-03-14T06:28:48Z',
        emailId: 'uroomeg@amplifier.com',
        lastLoggedIn: new Date().toISOString(),
        status: 'SUSPENDED',
        access: 'Read Only',
    },
];
