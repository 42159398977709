import { Button } from 'react-bootstrap';
import { REMOVE, WARNING } from 'Core-utils/constants/constants';
import { Typography } from 'Components/atoms';
import './styles.scss';

interface RemoveIntegrationProps {
    handleClick: () => void;
    content?: string;
}

const RemoveIntegration = (props: RemoveIntegrationProps) => {
    return (
        <div className="d-flex flex-column remove-button gap-3">
            <Typography variant="body3" color="text-high-emphasis">
                {props.content ? props.content : WARNING}
            </Typography>
            <Button
                className="bg-secondary-accent-red-4 text-uppercase caption5 remove-button__button"
                onClick={props.handleClick}
            >
                {REMOVE}
            </Button>
        </div>
    );
};

export default RemoveIntegration;
