import { useEffect, useMemo, useState } from 'react';
import { useApiData } from 'Hooks/useApiData';
import { getMetricsHealthScoreData } from 'Apis/library';
import { useSelector } from 'react-redux';
import { RootState } from 'Rdx/store';
import { REPORTS, ReportResultUpsertDto } from '@ampsec/platform-client';
import _ from 'lodash';
import DepartmentContent from './DepartmentContent';
import { ALL } from 'Core-utils/constants/constants';
import './styles.scss';
import useTimeSpent from 'Hooks/useTimeSpent';

const COMPONENT_IDENTIFIER = 'Department Tab';

const DepartmentTab = () => {
    useTimeSpent(COMPONENT_IDENTIFIER);
    const org = useSelector((state: RootState) => state.orgModel.org);
    const [selectedDept, setSelectedDept] = useState<string>('');
    const [isSelected, setIsSelected] = useState<boolean>(true);
    const handleDepartmentClick = (department: string) => {
        setSelectedDept(department);
        setIsSelected(true);
    };

    const {
        data: healthScoreData,
        error: healthScoreDataError,
        isLoading: healthScoreDataIsLoading,
        refresh: healthScoreDataRefresh,
    } = useApiData<ReportResultUpsertDto, ReportResultUpsertDto>(
        REPORTS.HEALTH_SCORE,
        getMetricsHealthScoreData,
        (a) => a,
        {
            organization: org,
            uid: null,
            limit: 100,
        },
    );
    const [uniqueDepartments, setUniqueDepartments] = useState<ReportResultUpsertDto[]>([]);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [selectedEventKey, setSelectedEventKey] = useState<string | null>('');

    useEffect(() => {
        if (healthScoreData) {
            setSelectedDept(
                (healthScoreData as ReportResultUpsertDto[]).find((department) => department.department === ALL)
                    ?.department ?? '',
            );
            setIsSelected(true);
        }
    }, [healthScoreData]);

    const filteredDepartments = useMemo(() => {
        if (!searchQuery) {
            return uniqueDepartments;
        }
        const searchString = searchQuery.trim().toLocaleLowerCase();
        const tempData = uniqueDepartments.filter(
            (ele) =>
                (ele?.department && ele?.department.toLocaleLowerCase() === 'all') ||
                (ele?.department && ele?.department.toLocaleLowerCase().includes(searchString)),
        );
        if (!tempData.some((ele) => ele.department === selectedDept)) {
            setSelectedDept('ALL');
        }
        return tempData;
    }, [searchQuery, uniqueDepartments]);

    useEffect(() => {
        if (healthScoreData) {
            const departments = _.uniqBy(healthScoreData as ReportResultUpsertDto[], 'department')
                .filter((healthScoreData) => !!healthScoreData.department)
                .sort((a, b) => {
                    if (a.department === 'UNKNOWN' && b.department === 'UNKNOWN') {
                        return 0;
                    } else if (a.department === 'UNKNOWN') {
                        return 1;
                    } else if (b.department === 'UNKNOWN') {
                        return -1;
                    } else {
                        return a.department!.localeCompare(b.department!);
                    }
                });
            setUniqueDepartments(departments);
        }
    }, [healthScoreData]);

    const handleDropdownChange = (eventKey: string | null) => {
        setSelectedEventKey(eventKey);
        if (eventKey === 'Name') {
            const sortedDepartments = [...uniqueDepartments].sort((a, b) => a.department!.localeCompare(b.department!));
            setUniqueDepartments(sortedDepartments);
        } else if (eventKey === 'Score') {
            const sortedDepartments = [...uniqueDepartments].sort(
                (a, b) =>
                    Number(b.results?.['health_score_label']?.value) - Number(a.results?.['health_score_label']?.value),
            );
            setUniqueDepartments(sortedDepartments.reverse());
        }
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
    };

    return (
        <div className="h-100 d-flex justify-content-center">
            <DepartmentContent
                uniqueDepartments={filteredDepartments}
                selectedDept={selectedDept}
                onDepartmentClick={handleDepartmentClick}
                isSelected={isSelected}
                onRefresh={healthScoreDataRefresh}
                isLoading={healthScoreDataIsLoading}
                error={healthScoreDataError}
                isEmpty={_.isEmpty(healthScoreData)}
                onDropdownChange={handleDropdownChange}
                onSearchChange={handleSearchChange}
                selectedEventKey={selectedEventKey}
            />
        </div>
    );
};

export default DepartmentTab;
