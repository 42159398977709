import React from 'react';
import { Color } from 'Core-utils/types/color';
import { TypographyVariant } from 'Core-utils/types/Variant';
import classNames from 'classnames';

export type TypographyProps = {
    variant: TypographyVariant;
    children: React.ReactNode;
    color?: Color;
    style?: React.CSSProperties;
    className?: string;
};

const Typography: React.FC<TypographyProps> = ({ variant, children, color, style, className }) => {
    const typographyClassName = classNames(variant, className, {
        [`text-${color}`]: color,
    });
    return (
        <p className={typographyClassName} data-testid="typography" style={style}>
            {children}
        </p>
    );
};

export default Typography;
