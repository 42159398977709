import { Typography } from 'Components/atoms';
import { COMPLIANCE_LABEL, ENGAGEMENT_DASHBOARD_MAP } from 'Core-utils/constants/constants';
import './styles.scss';

interface EngagementDashboardCardObject {
    label: string;
    value: string | null;
}

interface EngagementDashboardCardProps {
    title: string;
    data: EngagementDashboardCardObject[];
}

interface EngagementDashboardCardData {
    object: EngagementDashboardCardObject;
}

const EngagementColumnMetric = ({ object }: EngagementDashboardCardData) => {
    // TODO: [EZ-253] logic for color range needs to be modified
    return (
        <div className={'engagement-card__column d-flex flex-column text-center text-uppercase'}>
            <Typography variant="body1" color="text-medium-emphasis">
                {object.label}
            </Typography>
            <Typography variant="h3" color="text-high-emphasis">
                {object.label === ENGAGEMENT_DASHBOARD_MAP[COMPLIANCE_LABEL] ? (
                    <span
                        className={
                            object.value !== null && parseInt(object.value) <= 80
                                ? 'text-vizualization-orange-100'
                                : 'text-success-green'
                        }
                    >{`${object.value}%`}</span>
                ) : (
                    object.value
                )}
            </Typography>
        </div>
    );
};

const EngagementDashboardCard = ({ data, title }: EngagementDashboardCardProps) => {
    const [complianceMetric, totalHealedMetric, activeEngagementsMetric] = Object.keys(ENGAGEMENT_DASHBOARD_MAP).map(
        (label) => {
            const labelName: { label: string; value: string } = { label: ENGAGEMENT_DASHBOARD_MAP[label], value: '-' };
            data.forEach((dataItem) => {
                if (dataItem.label === label) {
                    labelName.value = dataItem.value ?? '-';
                }
            });
            return labelName;
        },
    );

    return (
        <div className="engagement-card bg-text-black h-100 w-100 d-flex justify-content-center rounded">
            <div className="engagement-card__styled-container h-100 w-100 d-flex flex-column align-items-center">
                <Typography variant="subtitle2" color="text-high-emphasis" style={{ marginBottom: '0%' }}>
                    {title.toUpperCase()}
                </Typography>
                <div className="engagement-card__row d-flex justify-content-center align-items-center h-100 w-100">
                    <EngagementColumnMetric object={complianceMetric} />
                    <EngagementColumnMetric object={totalHealedMetric} />
                    <EngagementColumnMetric object={activeEngagementsMetric} />
                </div>
            </div>
        </div>
    );
};

export default EngagementDashboardCard;
