import { FilterCriteria } from '@ampsec/platform-client';
import { store } from 'Rdx/store';
import { useEffect, useMemo, useState } from 'react';
import useSWR from 'swr';

export type ApiDataResponse<RenderT> = {
    data: RenderT[] | RenderT | null | undefined;
    error: string;
    isLoading: boolean;
    refresh: () => void;
};

export const useApiData = <DataT, RenderT>(
    cacheKey: string,
    fetcher: any,
    // eslint-disable-next-line no-unused-vars
    transformer: (dataItem: DataT) => RenderT,
    filter?: FilterCriteria,
): ApiDataResponse<RenderT> => {
    const updatedFilter: FilterCriteria = { organization: store.getState().orgModel.org, ...filter };
    const { data: apiData, error, isLoading, mutate } = useSWR<DataT[] | DataT>([cacheKey, updatedFilter], () =>
        fetcher(cacheKey, updatedFilter),
    );
    const [data, setData] = useState<RenderT | RenderT[] | null>();
    const computedData = useMemo(() => {
        if (!apiData) {
            return [];
        }

        if (!Array.isArray(apiData)) {
            return transformer(apiData);
        }

        const updatedData = apiData.map(transformer);

        return updatedData;
    }, [apiData]);

    useEffect(() => {
        setData(computedData);
    }, [apiData]);
    const refresh = () => {
        mutate();
    };
    return {
        data,
        error,
        isLoading,
        refresh,
    };
};
