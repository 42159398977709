export const FIELDS_DATA = [
    {
        label: 'Base URL',
        placeholder: 'Base URL',
        value: 'http://www.YourDomain.com',
    },
    {
        label: 'Client ID',
        placeholder: 'Client ID',
        value: 'http://www.YourDomain.com',
    },
    {
        label: 'Client Secret',
        placeholder: 'Client Secret',
        value: 'http://www.YourDomain.com',
    },
];
