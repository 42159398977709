import _ from 'lodash';
import { IconWithTypography } from 'Components/molecules';
import { Speedometer } from 'Components/molecules';
import { CustomCard } from 'Components/molecules';
import ErrorHandlerAndLoaderWrapper from 'Wrappers/ErrorHandlerandLoaderWrapper';
import { SECURITY_HEALTH, SECURITY_HEALTH_LABEL, SECURITY_HEALTH_SUBTITLE } from 'Core-utils/constants/constants';
import { getMetricsReportsData } from 'Apis/library';
const { REPORTS } = require('@ampsec/platform-client');
import { useApiObjectData } from 'Hooks/useApiObjectData';
import { TrendingMetricResults } from 'Core-utils/types/types';
import { roundOffValue } from 'Core-utils/helpers/helpers';
import healthScoreIcon from 'Assets/icons/healthScore.svg';
import './styles.scss';

const COMPONENT_IDENTIFIER = 'Organization Security Health';

const SecurityHealth = () => {
    const { data, error, isLoading, refresh } = useApiObjectData<TrendingMetricResults, TrendingMetricResults>(
        REPORTS.HEALTH_SCORE,
        getMetricsReportsData,
        (a) => a,
        { department: 'ALL' },
    );
    const { data: selfHealingData } = useApiObjectData<TrendingMetricResults, TrendingMetricResults>(
        REPORTS.SELF_HEALED,
        getMetricsReportsData,
        (a) => a,
        { department: 'ALL' },
    );
    const value = selfHealingData.total_self_healed?.value;
    return (
        <div className="security-health h-100" data-testid="security-health">
            <CustomCard
                cardType="oneThird"
                title={<IconWithTypography isRow={true} label={SECURITY_HEALTH} labelColor="primary" />}
                subTitle={value === undefined ? null : `${value} ${SECURITY_HEALTH_SUBTITLE}`}
                content={
                    <div className="d-flex align-items-center justify-content-center">
                        <ErrorHandlerAndLoaderWrapper
                            error={error}
                            isLoading={isLoading}
                            render={() => (
                                <Speedometer
                                    value={roundOffValue(data?.[SECURITY_HEALTH_LABEL]?.value)}
                                    variant="organization"
                                />
                            )}
                            handleRefresh={refresh}
                            isEmpty={_.isEmpty(data) && _.isEmpty(selfHealingData)}
                            ImageSrc={healthScoreIcon}
                            component={COMPONENT_IDENTIFIER}
                        />
                    </div>
                }
            />
        </div>
    );
};

export default SecurityHealth;
