import CategoryCard from './CategoryCard';
import IntegratedVendorCard from './IntegratedVendorCard';
import { ConnectorStatus } from '@ampsec/platform-client';
import './styles.scss';

export type IntegratedCardProps = {
    imageUrl: string;
    title: string;
    vendor?: string;
    handleCard: () => void;
} & (
    | {
          variant: ConnectorStatus;
      }
    | {
          variant: 'custom';
      }
);

const IntegratedCard = ({
    imageUrl,
    title,
    vendor,
    handleCard,
    variant = ConnectorStatus.ACTIVE,
    ...otherProps
}: IntegratedCardProps) => {
    return (
        <div data-testid="integrated-card">
            {vendor ? (
                <IntegratedVendorCard
                    imageUrl={imageUrl}
                    title={title}
                    handleCard={handleCard}
                    tool={vendor}
                    variant={variant}
                    {...otherProps}
                />
            ) : (
                <CategoryCard imageUrl={imageUrl} title={title} onCardClick={handleCard} />
            )}
        </div>
    );
};

export default IntegratedCard;
