import { RiArrowDownSLine } from '@react-icons/all-files/ri/RiArrowDownSLine';
import { Typography } from 'Components/atoms';
import { Dropdown } from 'react-bootstrap';
import { MenuItem } from 'Core-utils/types';
interface DropdownComponentProps {
    value: string;
    dropDownItems: MenuItem[];
    onChange?: (eventKey: string | null, e: any) => void;
}
const RoleDropdown = ({ value, dropDownItems, onChange }: DropdownComponentProps) => {
    return (
        <Dropdown onSelect={onChange}>
            <Dropdown.Toggle className="border-0 text-text-low-emphasis d-flex gap-2 justify-content-center align-items-center bg-transparent">
                <Typography variant={'body5'} style={{ marginBottom: '0%' }}>
                    {value}
                </Typography>
                <RiArrowDownSLine />
            </Dropdown.Toggle>
            <Dropdown.Menu className="p-0 w-100 gap-2 bg-body-color border-text-medium-emphasis custom-scrollbar dropdown__custom-menu">
                {dropDownItems.map((item) => {
                    return (
                        <Dropdown.Item
                            key={item.id}
                            eventKey={item.value}
                            className="dropdown__menu-item d-flex align-items-center gap-2"
                        >
                            <Typography variant={'body5'} style={{ textTransform: 'uppercase', marginBottom: '0rem' }}>
                                {item.value}
                            </Typography>
                        </Dropdown.Item>
                    );
                })}
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default RoleDropdown;
