import { UserDetailContent } from 'Components/organisms';
import { BackButton } from 'Components/molecules';
import './styles.scss';

const UserDetailPage = () => {
    return (
        <div className="user-specific-page">
            <div className="user-specific-page__content d-flex flex-column g-3 h-100">
                <BackButton skipHistory={true} />
                <UserDetailContent />
            </div>
        </div>
    );
};

export default UserDetailPage;
