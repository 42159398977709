import { createModel } from '@rematch/core';
import type { RootModel } from './models';

const ssoModel = createModel<RootModel>()({
    state: {
        ssoOption: 'Google',
        error: null,
    },
    reducers: {
        setSsoOption(state, payload) {
            return { ...state, ssoOption: payload };
        },
        setError(state, payload) {
            return { ...state, error: payload };
        },
    },
});

export default ssoModel;
