import React, { useRef } from 'react';
import { DonutGraphDataSet } from 'Core-utils/types/types';
import { useDonutChartGraphData } from 'Core-utils/constants/charts';
import EngageIcon from 'Assets/icons/engagement.svg';
import { EMPTY_DATA_MSG } from 'Core-utils/constants/constants';
import { EmptyState } from '../EmptyState';
import './styles.scss';

interface DonutGraphProps {
    datasets: DonutGraphDataSet[];
}
const DonutGraph: React.FC<DonutGraphProps> = ({ datasets }) => {
    const chartRef = useRef<HTMLCanvasElement>(null);
    const allZero = datasets.every((dataset) => dataset.data.every((value) => value === 0));
    useDonutChartGraphData(chartRef, datasets);
    if (allZero) {
        return <EmptyState ImageSrc={EngageIcon} description={EMPTY_DATA_MSG} />;
    }
    return (
        <div data-testid="donut-chart" className="w-100 donut-graph">
            <canvas ref={chartRef} />
        </div>
    );
};

export default DonutGraph;
