import { Modal } from 'react-bootstrap';
import { Typography } from 'Components/atoms';
import { AiOutlineClose } from '@react-icons/all-files/ai/AiOutlineClose';
import { EngagementDashboardCard } from 'Components/molecules';
import {
    TRAINING_DEFICIENCIES_HEALED,
    VULNERABILITIES_HEALED,
    TOOLING_GAPS_HEALED,
    PRODUCT_VALUE_DASHBOARD,
} from 'Core-utils/constants/constants';
import {
    VULNERABILITIES_HEALED_CARD_DATA,
    TRAINING_DEFICIENCIES_HEALED_CARD_DATA,
    TOOLING_GAPS_HEALED_CARD_DATA,
} from './EngagementData';
import { ToolingCoverageComplianceByEndPoints } from 'Components/organisms';
import { OutStandingVulnerabilities } from 'Components/organisms';
import { TrainingCompliance } from 'Components/organisms';
import { ToolingMTTRAnalysis } from 'Components/organisms';
import { VulnerabilityMTTRAnalysis } from 'Components/organisms';
import { TrainingMTTRAnalysis } from 'Components/organisms';
import './styles.scss';

interface EngagementDashboardModalProps {
    onClose: () => void;
    show: boolean;
}

const EngagementDashboardModal = ({ show, onClose }: EngagementDashboardModalProps) => {
    const categories = ['5/1', '12/1', '19/1', '26/1', '2/2', '9/2', '16/2'];

    return (
        <Modal show={show} className="engagement-dashboard" onHide={onClose}>
            <Modal.Header>
                <Typography variant="subtitle1" color="text-high-emphasis">
                    {PRODUCT_VALUE_DASHBOARD}
                </Typography>
                <AiOutlineClose className="text-text-medium-emphasis engagement-dashboard__icon" onClick={onClose} />
            </Modal.Header>
            <Modal.Body className="custom-scrollbar overflow-auto">
                <div className="d-flex flex-column gap-2 h-100 w-100">
                    <div className="d-flex gap-3 w-100">
                        <EngagementDashboardCard title={TOOLING_GAPS_HEALED} data={TOOLING_GAPS_HEALED_CARD_DATA} />
                        <EngagementDashboardCard
                            title={VULNERABILITIES_HEALED}
                            data={VULNERABILITIES_HEALED_CARD_DATA}
                        />
                        <EngagementDashboardCard
                            title={TRAINING_DEFICIENCIES_HEALED}
                            data={TRAINING_DEFICIENCIES_HEALED_CARD_DATA}
                        />
                    </div>
                    <div className="d-flex gap-3 h-100 w-100">
                        <ToolingCoverageComplianceByEndPoints
                            series={[
                                {
                                    name: 'Strong MFA',
                                    data: [25, 21, 28, 30, 40, 55, 71],
                                },
                                {
                                    name: 'Endpoint Management',
                                    data: [40, 25, 33, 41, 64, 70, 56],
                                },
                                {
                                    name: 'Endpoint Security',
                                    data: [42, 55, 63, 52, 66, 70, 62],
                                },
                                {
                                    name: 'Vulnerability Management',
                                    data: [5, 12, 23, 32, 28, 23, 21],
                                },
                            ]}
                            categories={categories}
                        />
                        <OutStandingVulnerabilities
                            series={[
                                {
                                    name: 'Critical',
                                    data: [100, 25, 28, 57, 250, 120, 100],
                                },
                                {
                                    name: 'High',
                                    data: [110, 30, 45, 132, 140, 152, 141],
                                },
                                {
                                    name: 'Medium',
                                    data: [200, 105, 245, 132, 134, 252, 120],
                                },
                                {
                                    name: 'Low',
                                    data: [11, 200, 145, 32, 34, 52, 41],
                                },
                            ]}
                            categories={categories}
                        />
                        <TrainingCompliance
                            series={[45, 50, 72, 68, 77, 75, 80]}
                            overDueSeries={[220, 200, 170, 175, 150, 120, 112]}
                            categories={categories}
                        />
                    </div>
                    <div className="d-flex gap-3 h-100 w-100">
                        <ToolingMTTRAnalysis
                            series={[
                                {
                                    name: 'Strong MFA',
                                    data: [15, 12, 16, 10, 3, 7, 1],
                                },
                                {
                                    name: 'Endpoint Management',
                                    data: [4, 4, 7, 10, 8, 11, 13],
                                },
                                {
                                    name: 'Endpoint Security',
                                    data: [3, 5, 3, 7, 9, 12, 10],
                                },
                                {
                                    name: 'Vulnerability Management',
                                    data: [5, 12, 9, 7, 4, 8, 11],
                                },
                            ]}
                            categories={categories}
                        />
                        <VulnerabilityMTTRAnalysis
                            series={[
                                {
                                    name: 'Critical',
                                    data: [15, 12, 16, 10, 3, 7, 1],
                                },
                                {
                                    name: 'High',
                                    data: [4, 4, 7, 10, 8, 11, 13],
                                },
                                {
                                    name: 'Medium',
                                    data: [3, 5, 3, 7, 9, 12, 10],
                                },
                                {
                                    name: 'Low',
                                    data: [5, 12, 9, 7, 4, 8, 11],
                                },
                            ]}
                            categories={categories}
                        />
                        <TrainingMTTRAnalysis series={[35, 22, 18, 13, 15, 17, 13]} categories={categories} />
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default EngagementDashboardModal;
