import { createModel } from '@rematch/core';
import type { RootModel } from './models';
import { ConnectorDto, ProviderDto } from '@ampsec/platform-client';

const installedConnectorMap = createModel<RootModel>()({
    state: {
        ...({} as Record<string, ConnectorDto & { provider: ProviderDto }>),
    },
    reducers: {
        setInstalledConnectorMap(_state, payload) {
            return { ...payload };
        },
    },
});

export default installedConnectorMap;
