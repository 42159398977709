import React from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { Placement } from 'react-bootstrap/esm/types';
import ToolingCoverageTooltipContent from './components/ToolingCoverageTooltipContent';
import './styles.scss';

export interface ToolingCoverageTooltipProps {
    children?: React.ReactNode;
    id?: string;
    overlayContainerClassName?: string;
    overlayPlacement?: Placement;
    tooltipClassName?: string;
    icon?: React.ReactNode;
    headerLabel?: string;
    headerIcon?: string | React.ReactNode;
    tooltipContent?: { key: string; value: string }[];
    customContent?: { icon: React.ReactNode; value: string }[];
}
const ToolingCoverageTooltip = ({
    id = 'tooltip',
    overlayContainerClassName,
    overlayPlacement,
    children,
    tooltipClassName,
    icon,
    headerIcon,
    headerLabel,
    tooltipContent,
    customContent,
}: ToolingCoverageTooltipProps) => {
    const tooltip = (
        <Tooltip
            id={id}
            className={
                tooltipClassName ?? 'tooling-tooltip text-text-black d-flex justify-content-center align-items-center'
            }
        >
            <ToolingCoverageTooltipContent
                icon={icon}
                headerIcon={headerIcon}
                headerLabel={headerLabel}
                content={tooltipContent}
                customContent={customContent}
            />
        </Tooltip>
    );
    return (
        <OverlayTrigger placement={overlayPlacement ?? 'top'} overlay={tooltip}>
            <div
                className={
                    overlayContainerClassName ??
                    'tooling-tooltip__content d-flex justify-content-center align-items-center'
                }
            >
                {children}
            </div>
        </OverlayTrigger>
    );
};

export default ToolingCoverageTooltip;
