import { RichI18nContentService } from '@ampsec/platform-client';
import { useCallback, useMemo, useState } from 'react';

const richI18n = RichI18nContentService.instance();

export const useRichI18n = () => {
    const [loading, setLoading] = useState(false);
    const [initialized, setInitialized] = useState(false);
    useMemo(async () => {
        if (!initialized) {
            setLoading(true);
            await richI18n.init();
            setLoading(false);
            setInitialized(true);
        }
    }, [initialized]);
    const refresh = useCallback(async () => {
        if (!loading) {
            setLoading(true);
            await richI18n.refresh();
            setLoading(false);
        }
    }, [loading]);
    return {
        t: richI18n.getLookupFunction(),
        loading,
        refresh,
    };
};
