import { Button, FormGroup, FormLabel } from 'react-bootstrap';
import DynamicField from './DynamicField';
import { Typography } from 'Components/atoms';
import { FormSettings, TypographyVariant } from 'Core-utils/types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import type { Document as ContentfulDocument } from '@contentful/rich-text-types';
import { BLOCKS } from '@contentful/rich-text-types';
import { ChangeEventHandler } from 'react';
import { DynamicTab } from 'Components/organisms/Integrations/Integrations';
import classNames from 'classnames';

type DynamicTabContentProps = {
    selectedOptions: FormSettings;
    tab: DynamicTab;
    vendor: any;
    onOptionSelected: (formId: string, name: string, variant?: string) => ChangeEventHandler<HTMLInputElement>;
    onOptionChecked: (formId: string, name: string) => ChangeEventHandler<HTMLInputElement>;
    onButtonClick: (
        formId: string,
        strategy?: string,
        type?: string,
    ) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

export const DynamicTabContent = ({
    selectedOptions,
    tab,
    vendor,
    onOptionSelected,
    onOptionChecked,
    onButtonClick,
}: DynamicTabContentProps) => {
    return (
        <>
            {tab.type === 'text' && tab?.contentfulKey && (vendor as any)?.[tab.contentfulKey] && (
                <Typography variant={(tab.variant as TypographyVariant) || 'body10'}>
                    {(vendor as any)?.[tab.contentfulKey]}
                </Typography>
            )}
            {tab.type === 'richText' && tab?.contentfulKey && (vendor as any)?.[tab.contentfulKey] && (
                <div style={{ color: 'var(--bs-primary-100)' }}>
                    {documentToReactComponents(
                        ((vendor as { [k: string]: any })[tab.contentfulKey] as ContentfulDocument) || null,
                        {
                            renderNode: {
                                [BLOCKS.EMBEDDED_ASSET]: (node, _children) => {
                                    return (
                                        <img
                                            src={`https://${node.data.target.fields.file.url}`}
                                            height={node.data.target.fields.file.details.image.height}
                                            width={node.data.target.fields.file.details.image.width}
                                            alt={node.data.target.fields.description}
                                        />
                                    );
                                },
                            },
                        },
                    )}
                </div>
            )}
            {tab.type === 'slackBot' && (
                <a
                    href={`https://slack.com/oauth/v2/authorize?client_id=${process.env.SLACK_CLIENT_ID}&scope=${process.env.SLACK_SCOPE}&user_scope=${process.env.SLACK_USER_SCOPE}`}
                >
                    <img
                        alt="Add to Slack"
                        height="40"
                        width="139"
                        src="https://platform.slack-edge.com/img/add_to_slack.png"
                        srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
                    />
                </a>
            )}
            {tab.type === 'buttonOnly' && (
                <>
                    <Button
                        type="submit"
                        onClick={onButtonClick('', tab.strategy, tab.buttonType)}
                        className="bg-primary-500 text-uppercase caption5 removeButton__button mt-4"
                    >
                        {tab.buttonText}
                    </Button>
                </>
            )}
            {tab.type === 'form' && tab.formId && (
                <>
                    {tab.formTitle && (
                        <Typography
                            variant={(tab.formTitleVariant as TypographyVariant) || 'h4'}
                            color="text-high-emphasis"
                        >
                            {tab.formTitle}
                        </Typography>
                    )}
                    {tab.fields?.map((field) => {
                        const formClassName = classNames({
                            'd-flex justify-content-between mb-2': field.type === 'switch',
                        });
                        return (
                            <FormGroup key={field.name} className={formClassName}>
                                {field.label && (
                                    <FormLabel className="text-text-high-emphasis">{field.label}</FormLabel>
                                )}
                                <DynamicField
                                    {...field}
                                    formId={tab.formId!}
                                    selectedOptions={selectedOptions}
                                    onOptionChecked={onOptionChecked}
                                    onOptionSelected={onOptionSelected}
                                />
                            </FormGroup>
                        );
                    })}
                    <Button
                        onClick={onButtonClick(tab.formId, tab.strategy, tab.buttonType)}
                        className="bg-primary-500 text-uppercase caption5 removeButton__button mt-4 p-2"
                        type={tab.buttonType}
                        variant="primary"
                    >
                        {tab.buttonText}
                    </Button>
                </>
            )}
        </>
    );
};
