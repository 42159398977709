import './styles.scss';
import { ReactNode } from 'react';
import { Card } from 'react-bootstrap';
import { CgArrowsExpandRight } from '@react-icons/all-files/cg/CgArrowsExpandRight';
import { Typography } from 'Components/atoms';

type StatisticsCardProps = {
    header: string;
    subHeader?: string;
    headerBody?: ReactNode;
    body: ReactNode;
};

const StatisticsCard = ({ header, subHeader, headerBody, body }: StatisticsCardProps) => {
    return (
        <Card className="statistics-card bg-structural-CardBG text-text-high-emphasis position-absolute">
            <CgArrowsExpandRight className="position-absolute end-0 me-3 mt-3 text-text-low-emphasis" size={20} />
            <div className="statistics-card__header d-flex flex-column align-items-center">
                <div className="statistics-card__header__title d-flex flex-column align-items-center pt-3">
                    <Typography variant="body8" className="text-uppercase mb-1">
                        {header}
                    </Typography>
                    {!!subHeader && (
                        <Typography variant="subtitle3" className="mb-1 text-text-medium-emphasis">
                            {subHeader}
                        </Typography>
                    )}
                </div>
                {!!headerBody && headerBody}
            </div>
            <div className="mt-0 mb-0 m-3">{body}</div>
        </Card>
    );
};

export default StatisticsCard;
