import { ReactSVG } from 'react-svg';
import PageNotFoundImage from 'Assets/icons/PageNotFound.svg';
import { Typography } from 'Components/atoms';
import { NOTFOUNDPAGE } from 'Core-utils/constants/constants';
import './styles.scss';

const NotFoundPage = () => {
    return (
        <div className="page-not-found" data-testid="page-not-found">
            <div className="page-not-found__card d-flex flex-column align-items-center justify-content-center bg-structural-CardBG">
                <ReactSVG src={PageNotFoundImage} />
                <div className="divider" />

                <div className="page-not-found__title d-flex flex-row">
                    <Typography variant="h5" color="text-high-emphasis">
                        {NOTFOUNDPAGE[1]}
                    </Typography>
                    <Typography variant="h5" color="text-medium-emphasis">
                        {NOTFOUNDPAGE[2]}
                    </Typography>
                </div>

                <Typography variant="body5" color="text-low-emphasis">
                    {NOTFOUNDPAGE[3]}
                </Typography>
            </div>
        </div>
    );
};

export default NotFoundPage;
