import { Container, Row, Col } from 'react-bootstrap';
import { SideNav } from 'Components/organisms';
import { ToastMessage } from 'Components/molecules';
import './styles.scss';

interface MainTemplateProps {
    children: React.ReactNode;
    hasBanner: boolean;
}

const MainTemplate = (props: MainTemplateProps) => {
    return (
        <Container fluid className="main-template bg-structural-BG">
            <Row className="main-template__row">
                <Col lg="auto" sm="auto" xs="auto" md="auto" className="main-template__Col">
                    <SideNav />
                </Col>
                {props.hasBanner ? (
                    <Col className="main-template__table-col custom-scrollbar w-100 h-100 overflow-auto">
                        {<ToastMessage variant="error" />}
                        {props.children}
                    </Col>
                ) : (
                    <Col className="main-template__table-col custom-scrollbar w-100 h-100 overflow-auto pb-0">
                        {props.children}
                    </Col>
                )}
            </Row>
        </Container>
    );
};

export default MainTemplate;
