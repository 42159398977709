import { IoClose } from '@react-icons/all-files/io5/IoClose';
import { Modal, ModalBody, ModalHeader } from 'react-bootstrap';
import _ from 'lodash';
import { UsersInsightsModalBody } from './components';
import { FINDINGS_GRID_MODAL_HEIGHT } from 'Core-utils/constants/constants';
import './styles.scss';

type UsersInsightsModalProps = {
    show: boolean;
    title?: string;
    onClose?: () => void;
    defaultFilter?: {
        [x: string]: string[];
    };
};

const UsersInsightsModal = ({ show, title, onClose, defaultFilter }: UsersInsightsModalProps) => {
    if (!show) {
        return null;
    }

    const handleClose = () => {
        onClose && onClose();
    };

    return (
        <Modal show={show} className="users-insights-modal w-100" centered onHide={handleClose}>
            <ModalHeader className="text-text-high-emphasis bg-table-row-hover border-0">
                <Modal.Title className="h6 bg-text">{title}</Modal.Title>
                <IoClose
                    className="findingsgrid-modal__icon"
                    size={20}
                    onClick={handleClose}
                    data-testid="close-icon"
                />
            </ModalHeader>
            <ModalBody className="p-0 w-0">
                <UsersInsightsModalBody defaultFilter={defaultFilter} tableBodyHeight={FINDINGS_GRID_MODAL_HEIGHT} />
            </ModalBody>
        </Modal>
    );
};

export default UsersInsightsModal;
