import { CustomTab } from 'Components/molecules';
import { ReactNode, useEffect } from 'react';
import { DropdownComponent } from 'Components/molecules';
import { BaseMetric, MenuItem, TabProps } from 'Core-utils/types/types';
import { getEnum } from 'Apis/library';
import { useApiObjectData } from 'Hooks/useApiObjectData';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from 'Rdx/store';
import PageErrorHandlerAndLoaderWrapper from 'Wrappers/PageErrorHandlerAndLoaderWrapper';
import { ALL, ALL_ORGS } from 'Core-utils/constants/constants';
import { useSWRConfig } from 'swr';
import { clearSWRCache } from 'Core-utils/helpers/helpers';
import './styles.scss';

interface DashboardProps {
    tabs: TabProps[];
    children: ReactNode;
}

interface DashboardContent {
    tabs: TabProps[];
    selectedOrg: string;
    organizations: MenuItem[];
    onOrgChange: (event: string | null) => void;
    children: React.ReactNode;
}

const DashboardContent = ({ tabs, selectedOrg, organizations, onOrgChange, children }: DashboardContent) => {
    return (
        <div className="d-flex flex-column gap-3 p-3 ps-0 h-100 overflow-auto">
            <div className="d-flex justify-content-between align-items-center w-100">
                <CustomTab tabs={tabs} />
                <div className="dashboard__dropdown-container">
                    <DropdownComponent
                        value={selectedOrg}
                        dropDownItems={organizations}
                        variant="default"
                        onChange={onOrgChange}
                    />
                </div>
            </div>
            <div className="h-100 w-100 overflow-auto">{children}</div>
        </div>
    );
};

const DashboardTemplate = ({ tabs, children }: DashboardProps) => {
    const org = useSelector((state: RootState) => state.orgModel.org);
    const { cache } = useSWRConfig();
    const dispatch = useDispatch<Dispatch>();
    const { data: fetchedOrgs, error, isLoading } = useApiObjectData<BaseMetric, MenuItem[]>(
        'organizations',
        getEnum,
        (dataItem: any) => {
            return (
                dataItem.data &&
                Object.keys(dataItem.data[0])?.map((key: string) => ({
                    id: key,
                    value: key,
                }))
            );
        },
    );

    const organizations: MenuItem[] = _.isEmpty(fetchedOrgs) ? [ALL_ORGS] : [ALL_ORGS, ...fetchedOrgs];

    const handleOrgChange = (event: any) => {
        dispatch.orgModel.setOrg(event);
        clearSWRCache(cache);
    };

    useEffect(() => {
        if (org !== ALL) {
            dispatch.orgModel.setOrg(ALL);
        }
    }, []);

    return (
        <PageErrorHandlerAndLoaderWrapper
            isLoading={isLoading}
            error={error}
            render={() => (
                <DashboardContent
                    tabs={tabs}
                    selectedOrg={org}
                    organizations={organizations}
                    onOrgChange={handleOrgChange}
                >
                    {children}
                </DashboardContent>
            )}
        />
    );
};

export default DashboardTemplate;
