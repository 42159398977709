import { useCallback, useMemo, useState, useEffect } from 'react';
import _, { isEmpty } from 'lodash';
import DataTable, { TableColumn, Alignment, SortOrder } from 'react-data-table-component';
import InfiniteScroll from 'react-infinite-scroll-component';
import { IoChevronDownCircle } from '@react-icons/all-files/io5/IoChevronDownCircle';
import PageErrorHandlerAndLoaderWrapper from 'Wrappers/PageErrorHandlerAndLoaderWrapper';
import SubHeaderComponent from './SubHeaderComponent';
import { DataTableProps, GridType } from 'Core-utils/types/types';
import CustomPagination from './CustomPagination';
import TableSubStates from './TableSubStates';
import { useApiObjectData } from 'Hooks/useApiObjectData';
import { getGridData, getApiData } from 'Apis/library';
import { useSearchParamsState } from 'Hooks/useSearchParamsState';
import { FilterCriteria } from '@ampsec/platform-client';
import './styles.scss';

const columnFilterMap: Record<string, string> = {
    provider: 'provider.displayValue',
};

const AMP_ENUMS: { [key: string]: string } = {
    ORGANIZATION: 'organization',
    DEPARTMENT: 'department',
    TITLE: 'position',
    SEVERITY: 'severity',
    [columnFilterMap.provider]: 'provider',
    CATEGORY: 'issue type',
};

export const getFilterCriteria = (selectedOptions: { [key: string]: string[] }) => {
    return Object.keys(selectedOptions).reduce((prevSelection, currSelection) => {
        const cb = (value: string) => value === currSelection;
        const key = _.findKey(AMP_ENUMS, cb);
        if (key) {
            const formattedKey = key === 'provider.displayValue' ? key : key.toLowerCase();
            return { ...prevSelection, [formattedKey]: selectedOptions[currSelection] };
        }
        return prevSelection;
    }, {});
};

const customStyle = {
    subHeader: {
        style: {
            backgroundColor: '$structural-CardBG',
        },
    },
    table: {
        style: {
            minHeight: '100%',
            backgroundColor: '$structural-CardBG',
        },
    },
};

function Table<T>({
    data,
    expandableRows = false,
    expandableRowsComponent,
    cacheKey,
    rowsPerPageOptions,
    tableBodyHeight,
    customStyles,
    columns,
    showSubHeader = true,
    defaultSortId,
    titleComponent,
    transformer,
    subHeaderVariant = 'filter-with-actions',
    subHeaderContent,
    filter,
    isMock = false,
    pagination = true,
    selectableRows,
    selectedRows,
    onSelectedRowsChange,
    clearSelectedRows,
    dropdownOptions = {},
    refreshTableData,
    dataIsLoading = false,
    onChangePage,
    onRowChange,
    currentPage,
    currentLimit,
    paginationTotalRows,
    isInfiniteScroll,
    onRowDoubleClicked,
    placeHolder,
    searchKey = 'q',
    operation,
    defaultSortOrder,
    componentIdentifier,
    noContextMenu = true,
    estimated,
}: DataTableProps<T>) {
    if (tableBodyHeight) {
        customStyle.table.style.minHeight = tableBodyHeight;
    }

    const { filter: urlFilter, setSearchParamsState } = useSearchParamsState();

    const [initialCallMade, setInitialCallMade] = useState(false);
    const sortColumn = urlFilter?.sortColumn ?? defaultSortId;
    const sortOrder = urlFilter?.sortOrder ?? defaultSortOrder ?? 'ASC';
    const page = urlFilter?.page && !isNaN(parseInt(urlFilter?.page)) ? parseInt(urlFilter?.page) : currentPage ?? 1;
    const limit =
        urlFilter?.limit && !isNaN(parseInt(urlFilter?.limit))
            ? parseInt(urlFilter?.limit)
            : currentLimit ?? rowsPerPageOptions[0];
    const combinedFilter = urlFilter ? { ...urlFilter, ...filter } : { ...filter };
    const [searchValue, setSearchValue] = useState<string>('');

    const [selectedOptions, setSelectedOptions] = useState<{
        [key: string]: string[];
    }>({});
    useEffect(() => {
        setSelectedOptions(
            _.omit(
                {
                    ...getFilterCriteria(urlFilter as { [key: string]: any }),
                    sortColumn: [sortColumn ?? ''],
                    sortOrder: [sortOrder],
                },
                'page',
                'limit',
                'sortColumn',
                'sortOrder',
            ),
        );
        setSearchValue(urlFilter?.[searchKey] ?? '');
    }, [urlFilter]);

    useEffect(() => {
        const debounceTimer = setTimeout(() => {
            if (!isEmpty(urlFilter) || initialCallMade) {
                if (searchValue !== undefined && searchValue !== null) {
                    setSearchParamsState({
                        [searchKey]: searchValue,
                        ..._.omit(urlFilter, searchKey),
                    });
                }
            }
        }, 500);

        return () => {
            clearTimeout(debounceTimer);
        };
    }, [searchValue]);

    const filters = useMemo(() => {
        const offset = (page - 1) * limit;
        const sort = sortOrder && sortColumn ? { [sortColumn]: sortOrder as 'ASC' | 'DESC' } : null;
        const search =
            urlFilter?.[searchKey] === '' || urlFilter?.[searchKey] === undefined
                ? { [searchKey]: undefined }
                : {
                      [searchKey]: searchKey === 'q' ? urlFilter?.[searchKey] : { $like: `${urlFilter?.[searchKey]}*` },
                  };

        const updatedSelectedOptions = getFilterCriteria(selectedOptions);
        const updatedFilters = { offset, limit, ...updatedSelectedOptions };
        if (combinedFilter) {
            return { ...combinedFilter, ...updatedFilters, ...(sort ? { sort } : {}), ...search };
        }
        return sort ? { ...updatedFilters, sort, ...search } : { ...updatedFilters, ...search };
    }, [selectedOptions, urlFilter, page, limit, sortOrder, sortColumn]);
    const onSortHandler = useCallback(
        (sortedColumn: TableColumn<T>, sortDirection: SortOrder) => {
            if (!isEmpty(urlFilter) || initialCallMade) {
                setSearchParamsState({
                    sortOrder: sortDirection === 'asc' ? 'ASC' : 'DESC',
                    sortColumn: sortedColumn.id,
                    ..._.omit(urlFilter, 'sortColumn', 'sortOrder'),
                });
            }
        },
        [columns, urlFilter],
    );

    // Updating the styles of currently sorted column and header
    const updatedColumns = useMemo(
        () =>
            columns.map((column) => {
                const tempColumn = { ...column };
                if (column.id === sortColumn) {
                    tempColumn.name = <div className="sorted-column w-100">{column.name}</div>;
                    tempColumn.conditionalCellStyles = [
                        {
                            when: (_row: T) => true,
                            classNames: ['sorted-column-cell'],
                        },
                    ];
                }
                return tempColumn;
            }),
        [sortColumn, columns],
    );

    const changePageHandler = (currentPage: number) => {
        if (!isEmpty(urlFilter) || initialCallMade) {
            setSearchParamsState({ page: currentPage, ..._.omit(urlFilter, 'page') });
        }
    };
    const changeRowsPerPageHandler = (rowsOption: number) => {
        if (!isEmpty(urlFilter) || initialCallMade) {
            setSearchParamsState({ limit: rowsOption, ..._.omit(urlFilter, 'limit') });
        }
    };

    const getGridDataWithOperations = (inputUrl: GridType, filter?: FilterCriteria) => {
        if (!isEmpty(filters) || !initialCallMade) {
            setInitialCallMade(true);
            return operation ? getGridData(inputUrl, filter, operation) : getGridData(inputUrl, filter);
        }
    };

    const { data: APIdata, isLoading, error, refresh } = data
        ? { data: { data }, isLoading: dataIsLoading, error: false, refresh: () => {} }
        : useApiObjectData(cacheKey, isMock ? getApiData : getGridDataWithOperations, transformer, filters);

    useEffect(() => {
        refresh();
    }, [refreshTableData]);

    const [asyncData, setAsyncData] = useState<T[]>(APIdata?.data ?? []);
    const handleInfiniteScroll = () => {
        setSearchParamsState({ page: page + 1 });
    };
    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;
        setSearchValue(inputValue);
    };

    const PaginationComponent = (props: any) => (
        <CustomPagination
            {...props}
            rowsPerPageOptions={rowsPerPageOptions}
            estimated={estimated}
            rowsPerPage={limit}
        />
    );

    useEffect(() => {
        if (APIdata?.data) {
            setAsyncData((prevAsyncData) => [...prevAsyncData, ...APIdata?.data]);
        }
    }, [APIdata?.data]);

    const TableComponent = () => (
        <div className="d-flex flex-column h-100 w-100">
            <div id="table-skeleton" className="d-flex flex-column flex-grow p-0 table-skeleton">
                {!isInfiniteScroll ? (
                    <DataTable
                        data={APIdata?.data}
                        expandableRows={expandableRows}
                        expandableRowsComponent={expandableRowsComponent}
                        columns={updatedColumns}
                        subHeader={showSubHeader}
                        subHeaderComponent={
                            <SubHeaderComponent
                                columns={columns}
                                subHeaderVariant={subHeaderVariant}
                                selectedRows={selectedRows}
                                dropdownOptions={dropdownOptions}
                                setSelectedOptions={setSelectedOptions}
                                subHeaderContent={subHeaderContent}
                                selectedOptions={selectedOptions}
                                searchInputValue={searchValue}
                                onSearchChange={handleSearchChange}
                                filterColumns={Object.keys(dropdownOptions)}
                                placeHolder={placeHolder}
                            />
                        }
                        subHeaderAlign={Alignment.LEFT}
                        className="custom-scrollbar data-table flex-grow-1 bg-structural-CardBG"
                        pagination={pagination}
                        paginationServer
                        paginationComponent={PaginationComponent}
                        paginationPerPage={limit}
                        paginationRowsPerPageOptions={rowsPerPageOptions}
                        paginationDefaultPage={page}
                        onChangePage={onChangePage ?? changePageHandler}
                        onChangeRowsPerPage={onRowChange ?? changeRowsPerPageHandler}
                        sortIcon={<IoChevronDownCircle />}
                        defaultSortFieldId={defaultSortId}
                        fixedHeader
                        persistTableHead={true}
                        title={titleComponent}
                        fixedHeaderScrollHeight={tableBodyHeight}
                        customStyles={customStyles ?? customStyle}
                        progressPending={isLoading}
                        progressComponent={<TableSubStates variant="loader" />}
                        paginationTotalRows={paginationTotalRows ?? APIdata?.hints?.count}
                        noDataComponent={<TableSubStates variant="empty" error={error} />}
                        onSort={onSortHandler}
                        selectableRows={selectableRows ?? false}
                        onSelectedRowsChange={onSelectedRowsChange}
                        clearSelectedRows={clearSelectedRows}
                        onRowDoubleClicked={onRowDoubleClicked}
                        noContextMenu={noContextMenu}
                    />
                ) : (
                    <InfiniteScroll
                        dataLength={APIdata?.data.length ?? 0}
                        next={handleInfiniteScroll}
                        hasMore={APIdata?.hints?.hasMore ?? false}
                        loader={<TableSubStates variant="loader" />}
                        className="custom-scrollbar bg-structural-CardBG"
                    >
                        <DataTable
                            data={asyncData}
                            columns={updatedColumns}
                            subHeader={showSubHeader}
                            subHeaderComponent={
                                <SubHeaderComponent
                                    columns={columns}
                                    subHeaderVariant={subHeaderVariant}
                                    selectedRows={selectedRows}
                                    dropdownOptions={dropdownOptions}
                                    setSelectedOptions={setSelectedOptions}
                                    subHeaderContent={subHeaderContent}
                                    selectedOptions={selectedOptions}
                                    searchInputValue={searchValue}
                                    onSearchChange={handleSearchChange}
                                    filterColumns={Object.keys(dropdownOptions)}
                                />
                            }
                            subHeaderAlign={Alignment.LEFT}
                            className="custom-scrollbar custom-table"
                            pagination={pagination}
                            paginationServer
                            paginationComponent={(props) => (
                                <CustomPagination {...props} rowsPerPageOptions={rowsPerPageOptions} />
                            )}
                            paginationPerPage={limit}
                            paginationDefaultPage={page}
                            paginationRowsPerPageOptions={rowsPerPageOptions}
                            onChangePage={changePageHandler}
                            onChangeRowsPerPage={changeRowsPerPageHandler}
                            sortIcon={<IoChevronDownCircle />}
                            defaultSortFieldId={defaultSortId}
                            fixedHeader
                            persistTableHead={true}
                            title={titleComponent}
                            fixedHeaderScrollHeight={tableBodyHeight}
                            customStyles={customStyle}
                            progressPending={isLoading}
                            progressComponent={<TableSubStates variant="loader" />}
                            paginationTotalRows={APIdata?.hints?.count}
                            noDataComponent={<TableSubStates variant="empty" />}
                            onSort={onSortHandler}
                            selectableRows={selectableRows ?? false}
                            onSelectedRowsChange={onSelectedRowsChange}
                            noContextMenu={noContextMenu}
                        />
                    </InfiniteScroll>
                )}
            </div>
        </div>
    );
    return <PageErrorHandlerAndLoaderWrapper error={error} render={TableComponent} component={componentIdentifier} />;
}

export default Table;
