import { FindingsGrid, SecurityHealthCardGroup } from 'Components/organisms';
import { UserHeader } from 'Components/organisms';
import { useApiObjectData } from 'Hooks/useApiObjectData';
import { useParams } from 'react-router-dom';
import ErrorHandlerAndLoaderWrapper from 'Wrappers/ErrorHandlerandLoaderWrapper';
import PageErrorHandlerAndLoaderWrapper from 'Wrappers/PageErrorHandlerAndLoaderWrapper';
import { getUserDetails } from '../../../apis/library';
import _ from 'lodash';
import { mapThreshold } from 'Core-utils/helpers/helpers';
import { UserDetailContentType } from 'Core-utils/types/types';
import toolingIcon from 'Assets/icons/toolingCoverage.svg';
import './styles.scss';
import { Typography } from 'Components/atoms';

interface UserDetailContentProps {
    userId: string;
    userData: UserDetailContentType;
    refresh: () => void;
}

const FINDINGS_TABLE_HEADER = 'Findings';
const HeaderComponent = (
    <div className="h-10 mt-3 d-inline-block">
        <Typography variant="subtitle1" color="text-high-emphasis">
            {FINDINGS_TABLE_HEADER}
        </Typography>
    </div>
);

const UserDetailContent = ({ userId, userData, refresh }: UserDetailContentProps) => {
    const UserDetailContentHelper = () => {
        const { label } = mapThreshold(parseInt(userData.score));
        return (
            <div className="custom-scrollbar d-flex flex-column gap-3 h-100 overflow-visible">
                <div className="user-detail-page__error-container d-flex align-center justify-content-center">
                    <UserHeader
                        userId={userData.id}
                        imageUrl={userData.pictureUrl}
                        firstName={userData.firstName}
                        lastName={userData.lastName}
                        department={userData.department}
                        position={userData.title}
                        startDate={userData.startDate}
                        manager={userData.managerEmail}
                        organization={userData.organization}
                        chipLabel={label.toUpperCase()}
                        email={userData.emails?.[0]}
                        designation={userData.title}
                    />
                </div>
                <SecurityHealthCardGroup
                    department={userData.department}
                    dashboardVariant="userDetails"
                    userId={userId}
                    userData={userData}
                />
                <FindingsGrid
                    titleComponent={HeaderComponent}
                    filter={{ ['user_id']: userId }}
                    enableUpdatingURLWithFiltersAndSorting={false}
                    showSubHeader={false}
                    disableFilters={true}
                />
            </div>
        );
    };

    return (
        <div className="user-detail-page custom-scrollbar mb-3 h-100">
            <PageErrorHandlerAndLoaderWrapper
                error={undefined}
                isLoading={false}
                render={UserDetailContentHelper}
                handleRefresh={refresh}
            />
        </div>
    );
};

const UserDetailContentWrapper = () => {
    const { id: userId } = useParams();
    const { data: userData, error, isLoading, refresh } = useApiObjectData<
        UserDetailContentType,
        UserDetailContentType
    >(`${userId}`, getUserDetails);
    const renderUserDetailContent = () => <UserDetailContent userId={userId!} userData={userData} refresh={refresh} />;

    return (
        <ErrorHandlerAndLoaderWrapper
            error={error}
            isLoading={isLoading || userData?.organization === undefined || userData?.organization === null}
            render={renderUserDetailContent}
            handleRefresh={refresh}
            isEmpty={_.isElement(userData)}
            ImageSrc={toolingIcon}
        />
    );
};

export default UserDetailContentWrapper;
