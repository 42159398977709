import { UserDto, Page, EnumDto, DeliveryStrategyKind } from '@ampsec/platform-client';
import { EnumService } from '@ampsec/platform-client/src/services/rest/EnumService';
import { useSelector } from 'react-redux';
import { RootState } from 'Rdx/store';
import { Table } from 'Components/organisms';
import {
    AMP_ENUMS,
    DEFAULT_ERROR_MSG,
    ENGAGE,
    ENGAGE_SUCCESS_MSG,
    ENGAGE_VARIANT,
    ERROR_VARIANT,
    USER_TABLE_ROWS_PER_PAGE_OPTIONS,
} from 'Core-utils/constants/constants';
import { Employee, USERS_COLUMNS } from './usersTable';
import { convertFindingViewToNotificationData, roundOffValue } from 'Core-utils/helpers/helpers';
import { useApiObjectData } from 'Hooks/useApiObjectData';
import { getEnums, postEngageNotifications } from 'Apis/library';
import { ChangeEvent, useContext, useState } from 'react';
import { ActionsBar } from 'Components/molecules';
import { ReactSVG } from 'react-svg';
import EngageIcon from 'Assets/icons/chat.svg';
import { EngageModal } from 'Components/organisms';
import { ToastsContext } from 'Rdx/contexts/ToastsContext';
import { useI18n } from 'Hooks/useI18n';
import useTimeSpent from 'Hooks/useTimeSpent';

const COMPONENT_IDENTIFIER = 'Users Table';

const filtersTransformer = (dataItems: PromiseSettledResult<Page<EnumDto>>[]) => {
    return dataItems.reduce((prevDataItem, currDataItem) => {
        if (currDataItem.status === 'rejected') {
            return prevDataItem;
        }
        return {
            ...prevDataItem,
            [AMP_ENUMS[currDataItem.value.kind]]: currDataItem.value.data
                .reduce((prevEnum: any[], currEnum) => {
                    return [...prevEnum, ...Object.keys(currEnum)];
                }, [])
                .sort(),
        };
    }, {});
};

const usersDataMap = (dataItems: UserDto[] | undefined) => {
    if (!dataItems) {
        return [];
    }
    return dataItems.map((dataItem) => {
        return {
            id: dataItem.id,
            name: `${dataItem.firstName} ${dataItem.lastName} `,
            score: roundOffValue(dataItem.score),
            topRiskContributors: dataItem.riskContributors.length
                ? dataItem.riskContributors.map((riskContributor) => riskContributor.displayValue)
                : [],
            department: dataItem.department ?? '-',
            title: dataItem.title ?? '-',
            tenure: dataItem.startDate ? new Date(dataItem.startDate) : undefined,
            organization: dataItem.organization ?? '-',
            image: dataItem.pictureUrl,
        };
    });
};

const Users = () => {
    useTimeSpent(COMPONENT_IDENTIFIER);
    const { t } = useI18n();
    const org = useSelector((state: RootState) => state.orgModel.org);
    const { addToast } = useContext(ToastsContext);
    const [actionsShow, setActionsShow] = useState<boolean>(true);
    const [selectedRows, setSelectedRows] = useState<Employee[]>([]);
    const [clear, setClear] = useState<boolean>(false);
    const [show, setShow] = useState<boolean>(false);
    const [copySecurityTeam, setCopySecurityTeam] = useState<boolean>(false);
    const transformer = (dataItem: Page<UserDto>) => ({
        ...dataItem,
        data: usersDataMap(dataItem.data),
    });

    const optionalFields: (keyof EnumService)[] = ['departments', 'organizations', 'titles'];

    const { data: enums, isLoading: _isEnumsLoading, error: _enumsError } = useApiObjectData(
        optionalFields.join('__'),
        getEnums,
        filtersTransformer,
        { organization: org },
    );

    const onSelectedRowsChange = (selected: {
        allSelected: boolean;
        selectedCount: number;
        selectedRows: Employee[];
    }) => {
        setSelectedRows(selected.selectedRows);
        if (selected.selectedRows.length > 0) {
            setActionsShow(true);
        }
    };

    const handleActionsClose = () => {
        setSelectedRows([]);
        setClear((clear) => !clear);
        setActionsShow(false);
    };

    const handleEngageClick = () => setShow((show) => !show);

    const handleClose = () => setShow(false);

    const getToastBody = (variant: string): string => {
        const variantMessages: Record<string, string> = {
            [ENGAGE_VARIANT]: ENGAGE_SUCCESS_MSG,
            [ERROR_VARIANT]: DEFAULT_ERROR_MSG,
        };

        return variantMessages?.[variant] || variant || '';
    };

    const handleCopySecurityTeamCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
        setCopySecurityTeam(event.target.checked);
    };

    const handleSend = (text: string, strategy: DeliveryStrategyKind, includeFinding = false) => {
        const NotificationDataArray = selectedRows.map((selectedRow) =>
            convertFindingViewToNotificationData(
                text,
                copySecurityTeam,
                strategy,
                includeFinding,
                undefined,
                selectedRow,
            ),
        );

        postEngageNotifications(NotificationDataArray)
            .then((response) => {
                addToast({
                    severity: response?.error ? 'error' : 'success',
                    body: getToastBody(response?.error || ENGAGE_SUCCESS_MSG),
                });
            })
            .catch((error) => {
                addToast({
                    severity: 'error',
                    body: t(error.response.data.message),
                });
            });
        setShow(false);
        setActionsShow(false);
        setClear((clear) => !clear);
    };

    const actions = [
        {
            label: ENGAGE,
            icon: (
                <ReactSVG
                    src={EngageIcon}
                    className="data-grid__svg-react-icon d-flex justify-content-center align-items-center"
                />
            ),
            onClick: handleEngageClick,
        },
    ];
    return (
        <div className="h-100 w-100 overflow-visible custom-scrollbar">
            <Table
                cacheKey="users"
                columns={USERS_COLUMNS}
                defaultSortId={'score'}
                rowsPerPageOptions={USER_TABLE_ROWS_PER_PAGE_OPTIONS}
                tableBodyHeight={'100%'}
                transformer={transformer}
                dropdownOptions={enums}
                updateUrl={true}
                onSelectedRowsChange={onSelectedRowsChange}
                clearSelectedRows={clear}
                selectableRows={true}
                componentIdentifier={COMPONENT_IDENTIFIER}
            />
            {selectedRows.length > 0 && actionsShow && (
                <ActionsBar
                    selectedFindings={selectedRows.length}
                    actions={actions}
                    show={selectedRows.length > 0 && actionsShow}
                    onActionsClose={handleActionsClose}
                />
            )}
            {show && (
                <EngageModal
                    show={show}
                    onClose={handleClose}
                    onSend={handleSend}
                    copySecurityTeam={copySecurityTeam}
                    onChangeCheckbox={handleCopySecurityTeamCheckboxChange}
                    findingsData={false}
                />
            )}
        </div>
    );
};

export default Users;
