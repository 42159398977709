import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'Rdx/store';
import { useEffect } from 'react';

export const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
    const navigate = useNavigate();
    const auth = useSelector((state: RootState) => state.auth);

    useEffect(() => {
        if (!Boolean(auth.token) || !Boolean(auth.user)) {
            navigate('/signin');
        }
    }, [auth, navigate]);

    return children;
};
