import { initializeSentry } from '../../sentry';

const sentry = initializeSentry();

enum CustomMetrics {
    LOGIN = 'login',
    PAGE_LOAD = 'page_load',
}

export type CustomMetricTags = {
    tenant: string;
    tid: string;
    userEmail: string;
    page: string;
    engagementFlowId: string;
    cid: string;
};

export const incrementLoginMetric = (tenantDisplayValue: string, tid: string, userEmail: string) => {
    sentry.metrics.increment(CustomMetrics.LOGIN, 1, {
        tags: { tenant: tenantDisplayValue, tid, userEmail },
    });
};

export const addPageLoadMetric = (value: number, tags: Partial<CustomMetricTags>) => {
    sentry.metrics.distribution(CustomMetrics.PAGE_LOAD, value, {
        tags,
        unit: 'second',
    });
};
